<template>
  <a-tooltip>
    <template slot="title"><slot name="title" /></template>
    <span class="error"><a-icon type="exclamation-circle" /></span>
  </a-tooltip>
</template>

<script>
export default {
  props: {},
};
</script>
