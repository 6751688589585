const CONNECTION_ERROR = {
  title: "Connection error",
  detail:
    "We attempted to make a request and did not receive a response.  Please try submitting again",
};

function extractErrors(axiosError) {
  if (axiosError.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const data = axiosError.response.data;

    // 500-status responses don't always have predictable
    // error shapes
    if (!(data.error || data.errors)) {
      return [CONNECTION_ERROR];
    }

    return data.error || [...data.errors];
  } else if (axiosError.request) {
    // The request was made but no response was received
    // `axiosError.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return [CONNECTION_ERROR];
  } else {
    return [
      {
        title: "Unknown error",
        detail: axiosError.message,
      },
    ];
  }
}

export default extractErrors;
