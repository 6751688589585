export const COLORS = {
  blue: "#1890FF",
  green: "#52C41A",
  yellow: "#FACC14",
  orange: "#FA8C16",
  red: "#F5222D",
  lightGrey: "#E9E9E9",
  midGrey: "#D9D9D9",
  darkGrey: "#8C8C8C",
  textGrey: "#000000D9",
  greyCardHeader: "#FAFAFA",
  buttonBlue: "#40A9FF",
};
