import {
  saveTableStateToUrl,
  currentTableStateFromUrl,
} from "@/shared/table_helper.js";

import { parseUrlParamsToObject } from "@/shared/url_parser.js";

const moduleName = "urlPersistence";

const initialUrlState = parseUrlParamsToObject(window.location.search);

export default (store) => {
  store.registerModuleOnce(moduleName, {
    namespaced: true,
    state: {
      urlState: initialUrlState,
    },
    getters: {
      tableState: () => (defaultConfigs) => {
        const { namespace } = defaultConfigs;

        return currentTableStateFromUrl(
          store.state[moduleName].urlState[namespace] || {},
          defaultConfigs,
        );
      },
    },
  });

  store.subscribeAction((action) => {
    const { payload } = action;

    if (typeof payload === "object" && "urlPersistenceVersion" in payload) {
      saveTableStateToUrl(payload.queryParams, payload);
    }
  });
};
