<template>
  <a-modal
    v-model="visible"
    okText="Add Alert Sources"
    :okButtonProps="{ props: { loading: isCreatingAlert } }"
    title="Add an Alert Source"
    :maskClosable="false"
    :afterClose="clearForm"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-alert
      v-if="message"
      showIcon
      :message="message"
      type="info"
      style="margin-bottom: 20px"
    />
    <a-form :form="form" layout="vertical">
      <a-form-item>
        <span slot="label">
          Alert Sources&nbsp;
          <a-tooltip
            title="Alert Sources imported into OpsLevel - ex. Datadog monitors, Pagerduty services, ..."
          >
            <a-icon type="question-circle-o" />
          </a-tooltip>
        </span>
        <a-select
          v-decorator="[
            'alertSourceIds',
            {
              rules: [
                { required: true, message: 'Please select an alert source' },
              ],
            },
          ]"
          mode="multiple"
          placeholder="Select an alert source"
          showSearch
          allowClear
          :filterOption="false"
          @search="handleSearch"
        >
          <a-spin
            v-if="isFetchingAlertData"
            slot="notFoundContent"
            style="display: flex; justify-content: center"
          />
          <a-select-opt-group
            v-for="(alertSources, alertSourceType) in alertSourceOptions"
            v-else
            :key="alertSourceType"
          >
            <span slot="label">
              <OpsIcon
                :type="alertSourceType"
                color="grey"
                style="font-size: 15px"
              />
              {{ formattedAlertSource(alertSourceType) }}
            </span>
            <a-select-option
              v-for="alertSource in alertSources"
              :key="alertSource.id"
              :disabled="existingAlert(alertSource)"
            >
              <a-row style="width: 100%">
                <a-col :span="21">
                  {{ formattedAlertSource(alertSourceType) }}/{{
                    alertSource.name
                  }}
                </a-col>
              </a-row>
            </a-select-option>
          </a-select-opt-group>
          <a-select-option v-if="showLimitMsg" value="message-block" disabled>
            Showing first {{ fetch_limit }} alert sources. Type to begin
            searching
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import * as types from "@/modules/alertSources/mutation_types.js";
import { mapState, mapActions } from "vuex";
import { debounce, groupBy, startCase } from "lodash";
import OpsIcon from "@/components/atoms/OpsIcon.vue";
import { debouncedSearch } from "@/shared/search_helper.js";

const ALERT_SOURCE_FETCH_LIMIT = 100;

const INITIAL_STATE = {
  visible: false,
  message: null,
};

export default {
  name: "AlertSourceEditModal",
  components: { OpsIcon },

  props: {
    serviceId: {
      type: String,
      required: true,
    },
    alertSourceDataPollingInterval: {
      type: Number,
      default: 6000, // 6 seconds
    },
  },

  data() {
    return {
      ...INITIAL_STATE,
      form: this.$form.createForm(this, { name: "form" }),
      fetch_limit: ALERT_SOURCE_FETCH_LIMIT,
      search: "",
    };
  },

  computed: {
    ...mapState({
      isFetchingAlertData: (state) => state.alertSources.isFetchingAlertData,
      isCreatingAlert: (state) => state.alertSources.isCreatingAlert,
      alertData: (state) => state.alertSources.alertData,
      errorsFetchingAlertData: (state) =>
        state.alertSources.errorsFetchingAlertData,
      errorsCreatingAlert: (state) => state.alertSources.errorsCreatingAlert,
      allCreatingFailed: (state) => state.alertSources.allCreatingFailed,
    }),
    alertSourceOptions() {
      return groupBy(this.alertData, "type");
    },
    showLimitMsg() {
      return this.alertData.length == this.fetch_limit;
    },
  },

  created() {
    this.$store.subscribe(this.onStoreChanged);
  },

  methods: {
    ...mapActions({
      fetchAlertData: "alertSources/fetchAlertData",
      createAlert: "alertSources/createAlert",
      fetchServiceAlerts: "alertSources/fetchServiceAlerts",
    }),
    existingAlert(alertSource) {
      return alertSource.services.totalCount > 0;
    },
    handleSubmit: debounce(function (e) {
      e.preventDefault();
      const serviceId = this.serviceId;

      this.form.validateFields({ force: true }, (err, values) => {
        if (err) {
          return;
        }

        this.createAlert({
          service: { id: serviceId },
          alertSourceIds: values.alertSourceIds,
        });
        this.fetchAlertData({
          first: ALERT_SOURCE_FETCH_LIMIT,
          service_id: this.serviceId,
        });
      });
    }, 150),
    handleCancel() {
      this.hide();
    },
    hide() {
      this.visible = false;
    },
    clearForm() {
      Object.assign(this, INITIAL_STATE);
      this.form.resetFields();
    },
    show() {
      this.visible = true;
    },
    onStoreChanged(mutation) {
      if (
        mutation.type === `alertSources/${types.RECEIVE_CREATE_ALERT_RESPONSE}`
      ) {
        this.showCreateAlertSourceErrors(this.errorsCreatingAlert);

        if (!this.allCreatingFailed) {
          this.hide();
          this.pollForAlertData();

          if (this.errorsCreatingAlert.length !== 0) {
            this.$message.success("Some alerts attached successfully");
          }
        }
      } else if (
        mutation.type === `alertSources/${types.RECEIVE_CREATE_ALERT_ERROR}`
      ) {
        this.showCreateAlertSourceErrors(this.errorsCreatingAlert);
      }
    },
    showCreateAlertSourceErrors(errors) {
      for (const error of errors) {
        this.$message.error(error.message, 10);
      }
    },
    handleSearch(value) {
      this.search = value;
      debouncedSearch.cancel();
      this.wrapperDebouncedSearch();
    },
    // need to wrap the function to make `this` available
    wrapperDebouncedSearch: debouncedSearch,
    executeSearch() {
      this.fetchAlertData({
        first: ALERT_SOURCE_FETCH_LIMIT,
        search: this.search,
        service_id: this.serviceId,
      });
    },
    // We want to poll for alert data after a few seconds to avoid synchronously waiting
    pollForAlertData() {
      const alertStatusPoll = global.setInterval(() => {
        this.fetchServiceAlerts({ service_id: this.serviceId });
        clearInterval(alertStatusPoll);
      }, this.alertSourceDataPollingInterval);
    },
    formattedAlertSource(name) {
      return startCase(name);
    },
  },
};
</script>
