import { ApolloClient, split, InMemoryCache, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities/index.js";
import csrf from "@/lib/csrf.js";
import fetch from "unfetch";

import { createConsumer } from "@rails/actioncable";
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink.js";

// Setup a link for action cable
const cable = createConsumer("/subscribe");
const actionCableLink = new ActionCableLink({ cable });

const httpLink = new HttpLink({
  uri: "/graphql",
  fetch: fetch,
  headers: {
    "GraphQL-Visibility": "internal",
    [csrf.headerKey]: csrf.token,
  },
});

// Redirect subscriptions to the action cable link, while using the HTTP link for other queries
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  actionCableLink,
  httpLink,
);

const clientOptions = {
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
};

export const gqlClient = new ApolloClient(clientOptions);
