// This is an optimistic lock that can be used to avoid clobbering data with old, stale requests.
// Useful when making search queries in response to user keystrokes.
// The first query can take longer to process than the second one.
// Using an optimistic lock will avoid overwriting the second result with the stale first result.

const counters = {};

export const acquireLock = (namespace) => {
  let counter = counters[namespace] || 1;

  counter++;
  counters[namespace] = counter;

  return counter;
};

export const validLock = (namespace, counter) => {
  return counter == counters[namespace];
};
