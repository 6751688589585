<template>
  <span v-html="safeContent" />
</template>

<script>
import DOMPurify from "dompurify";

export default {
  props: {
    contentHtml: {
      type: String,
      required: true,
    },
  },

  computed: {
    safeContent() {
      return DOMPurify.sanitize(this.contentHtml);
    },
  },
};
</script>
