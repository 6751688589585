import * as types from "./mutation_types.js";
import {
  LEVEL_INDEX_QUERY,
  CREATE_LEVEL_MUTATION,
  UPDATE_LEVEL_MUTATION,
  DELETE_LEVEL_MUTATION,
} from "./queries.js";

import { get } from "lodash";
import { gqlClient } from "@/shared/apolloClient.js";
import extractApolloErrors from "@/lib/extract_apollo_errors.js";

export const updateLevel = ({ commit }, data) => {
  commit(types.REQUEST_UPDATE_LEVEL);

  gqlClient
    .mutate({
      mutation: UPDATE_LEVEL_MUTATION,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.levelUpdate.errors");

      if (errors.length > 0) {
        commit(types.RECEIVE_UPDATE_LEVEL_ERROR, errors);
      } else {
        const level = get(response, "data.levelUpdate.level");

        commit(types.RECEIVE_UPDATE_LEVEL_SUCCESS, level);
      }
    })
    .catch((error) => {
      commit(types.RECEIVE_UPDATE_LEVEL_ERROR, extractApolloErrors(error));
    });
};

export const createLevel = ({ commit }, data) => {
  commit(types.REQUEST_CREATE_LEVEL);

  gqlClient
    .mutate({
      mutation: CREATE_LEVEL_MUTATION,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.levelCreate.errors");

      if (errors.length > 0) {
        commit(types.RECEIVE_UPDATE_LEVEL_ERROR, errors);
      } else {
        commit(
          types.RECEIVE_CREATE_LEVEL_SUCCESS,
          get(response, "data.levelCreate.level"),
        );
      }
    })
    .catch((error) => {
      commit(types.RECEIVE_CREATE_LEVEL_ERROR, extractApolloErrors(error));
    });
};

export const deleteLevel = ({ commit }, data) => {
  commit(types.REQUEST_DELETE_LEVEL);

  gqlClient
    .mutate({
      mutation: DELETE_LEVEL_MUTATION,
      variables: data,
    })
    .then((response) => {
      const errors = get(response, "data.levelDelete.errors");

      if (errors && errors.length) {
        commit(types.RECEIVE_DELETE_LEVEL_ERROR, errors);
      } else {
        const deletedLevelId = get(response, "data.levelDelete.deletedLevelId");

        commit(types.RECEIVE_DELETE_LEVEL_SUCCESS, deletedLevelId);
      }
    })
    .catch((error) => {
      commit(types.RECEIVE_DELETE_LEVEL_ERROR, extractApolloErrors(error));
    });
};

export const fetchLevels = ({ commit }) => {
  commit(types.REQUEST_LEVELS);

  gqlClient
    .query({
      query: LEVEL_INDEX_QUERY,
    })
    .then((response) => {
      const levels = get(response, "data.account.rubric.levels.nodes");

      commit(types.RECEIVE_LEVELS_SUCCESS, levels);
    })
    .catch((error) => {
      commit(types.RECEIVE_LEVELS_ERROR, extractApolloErrors(error));
    });
};
