import moment from "moment";
import { gold, green, magenta, purple } from "@ant-design/colors";
import { parseUrlParamsToObject } from "@/shared/url_parser.js";
import { isString } from "lodash";

function capitalize(value) {
  if (value == null) {
    return "";
  }

  value = value.toString();

  return value.charAt(0).toUpperCase() + value.slice(1);
}

export { capitalize };

export const dateTimeCalendar = (datetime) => {
  return moment(datetime).calendar();
};

export const dateWithoutWeekday = (datetime) => {
  // Converts datetime to "MMMM DD, YYYY" format
  return moment(datetime).format("LL");
};

export const dateWithTime = (datetime) => {
  // Converts datetime to "MMMM DD, YYYY, H:mm"
  return moment(datetime).format("LLL");
};

export const dateTimeWithoutWeekday = (datetime) => {
  // Converts datetime to "MMMM D, YYYY at h:mm [AM|PM]" format
  return moment(datetime).format("MMMM D, YYYY [at] h:mm A");
};

export const timeSince = (datetime) => {
  // Converts datetime to "X seconds/minutes/.../years" format
  return moment(datetime).fromNow(true);
};

export const humanizedDate = (date, lowercase = false) => {
  // Docs: https://momentjs.com/docs/#/displaying/
  const momentDate = moment(date);

  if (!momentDate.isValid()) {
    return "";
  }

  let ret = momentDate.calendar(null, {
    lastDay: "[Yesterday at] h:mm a",
    sameDay: "[Today at] h:mm a",
    nextDay: "[Tomorrow at] h:mm a",
    lastWeek: "MMM D, YYYY [at] h:mm a",
    sameElse: function () {
      return "[" + momentDate.format("MMM Do, YYYY [at] h:mm a") + "]";
    },
  });

  if (lowercase) {
    ret = ret.charAt(0).toLowerCase() + ret.slice(1);
  }

  return ret;
};

export const humanizeString = (str, titleCase = true) => {
  return str
    .toString()
    .replace(/[\s_-]+/g, " ")
    .split(" ")
    .map((word) =>
      titleCase ? word.charAt(0).toUpperCase() + word.slice(1) : word,
    )
    .join(" ")
    .trim();
};

export const maskToken = (preview) => {
  const maskedDigits = 12;

  return "•".repeat(maskedDigits) + preview;
};

export const normalizeAxiosErrors = (error) => {
  if (!error.request) {
    return [
      {
        title: "Unexpected Error",
        detail:
          "An unexpected error occurred. Please refresh the page and try again.",
      },
    ];
  }

  if (!error.response) {
    return [
      {
        title: "Connection Error",
        detail: "No response was received. Please try again.",
      },
    ];
  }

  if (!error.response.data?.errors) {
    return [
      {
        title: "Server Error",
        detail:
          "The server was unable to process the request due to an error. Please try again.",
      },
    ];
  }

  const errorData = {
    status: error.response.status,
    title: error.response.statusText,
  };

  switch (error.response.status) {
    case 504:
      return [
        {
          ...errorData,
          detail: "A network error occurred. Please try again.",
        },
      ];
    default:
      return error.response.data.errors;
  }
};

export const percentage = (value, maximum, decimalCount = 0) => {
  const divisor = Math.pow(10, decimalCount);
  const multiplier = 100 * divisor;

  return Math.floor((value / maximum) * multiplier) / divisor || 0;
};

export const percentageString = (value, maximum, decimalCount = 0) => {
  return `${percentage(value, maximum, decimalCount)}%`;
};

export const isSelfHosted = () =>
  window.location.hostname !== "app.opslevel.com" &&
  window.location.hostname !== "staging.opslevel.dev" &&
  window.location.hostname !== "staging2.opslevel.dev" &&
  window.location.hostname !== "staging3.opslevel.dev" &&
  window.location.hostname !== "app.opslevel-staging.com" &&
  window.location.hostname !== "opslevel.local";

export const getSidebarBackgroundColor = (environment) => {
  const defaultColor = "#001529";

  if (
    parseUrlParamsToObject(window.location.search).simulateProd !== undefined
  ) {
    return defaultColor;
  }

  switch (environment) {
    case "staging1":
      return magenta[7];
    case "staging2":
      return purple[7];
    case "staging3":
      return gold[7];
    case "local":
      return green[7];
    case "production":
    default:
      return defaultColor;
  }
};

export function replaceNonAlphanumericWithUnderscores(input) {
  return input.replace(/[^a-zA-Z0-9]/g, "_");
}

export const sluggify = (str, strict = false) => {
  if (!isString(str)) {
    return "";
  }

  let slug = str.toLowerCase().replace(/[^a-z0-9]+/g, "_");

  if (strict) {
    slug = slug.replace(/(^_|_$)/g, "");
  }

  return slug;
};
