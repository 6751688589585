import { tableState } from "@/shared/table_helper.js";

export default () => ({
  suggestions: [],
  totalSuggestions: [],
  suggestionActions: {},
  isFetchingSuggestions: false,
  totalSuggestionsCount: 0,
  filteredSuggestionsCount: 0,
  errorsFetchingSuggestions: [],
  isFetchingSuggestionsCount: false,
  errorsFetchingSuggestionsCount: [],
  hasDeployIntegration: false,
  hasDeploys: false,
  isActioningSuggestions: false,
  actionedResults: { actioned: [], notActioned: [] },
  actionSuggestionErrors: [],
  ...tableState({ itemsPerPage: 50 }),
  notIgnoredSuggestions: [],
  isUpdatingSuggestion: false,
  errorsSettingIgnoredStatus: [],
  updatedIgnoreValue: false,
  updateIgnoreValueSuccess: false,

  isFetchingSuggestionSourceIntegrationTypes: false,
  suggestionSourceIntegrationTypes: [],
  errorsFetchingSuggestionSourceIntegrationTypes: [],

  isFetchingSuggestionSourceIntegrations: false,
  suggestionSourceIntegrations: [],
  errorsFetchingSuggestionSourceIntegrations: [],

  isDetachingSuggestionAlias: false,
  errorsDetachingSuggestionAlias: [],

  errorsUpdatingSuggestion: [],
});
