<template>
  <a-tooltip @click="tagClick">
    <span v-if="needsTooltip" slot="title" v-text="entireTag" />
    <a-tag
      v-bind="$attrs"
      class="tag"
      :style="wrapText ? 'white-space: normal' : 'white-space: nowrap;'"
      v-on="enableDelete && $listeners"
    >
      <HighlightContent
        v-if="isHighlighted('tags.key')"
        :contentHtml="tag.highlights['tags.key'][0]"
      />
      <span v-else-if="tag.key" class="tagKey" v-text="keyDisplay" />
      <HighlightContent
        v-if="isHighlighted('tags.value')"
        :contentHtml="highlightedValueDisplay"
      />
      <span
        v-else-if="valueDisplay.length > 0"
        class="tagValue"
        v-text="valueDisplay"
      />
      <a-icon v-if="enableDelete" type="close" />
      <a-tooltip v-if="locked && deletable" placement="top">
        <span slot="title">
          This tag is managed by an
          <a :href="configUrl" target="_blank">opslevel.yml</a>
          and cannot be deleted.
        </span>
        <a-icon type="lock" />
      </a-tooltip>
    </a-tag>
  </a-tooltip>
</template>

<script>
import HighlightContent from "@/components/atoms/HighlightContent.vue";
import { OPSLEVEL_YAML_DOC } from "@/shared/links.js";
import { featureFlags } from "@/mixins/featureFlags.js";

const DISPLAY_LENGTH_DEFAULT = 24;

function isValidTag(tag) {
  return (
    typeof tag === "object" &&
    typeof tag.id === "string" &&
    typeof tag.key === "string" &&
    typeof tag.value === "string"
  );
}

export default {
  components: {
    HighlightContent,
  },

  mixins: [featureFlags],

  props: {
    tag: {
      type: Object,
      required: true,
      validator: (tag) => {
        return isValidTag(tag);
      },
    },
    displayLength: {
      type: Number,
      default: DISPLAY_LENGTH_DEFAULT,
    },
    // if true, show lock or `x` icon, depending on locked
    deletable: {
      type: Boolean,
      default: false,
    },
    wrapText: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    entireTag() {
      return this.tag.value
        ? this.append(this.tag.key, this.tag.value)
        : this.tag.key;
    },
    needsTooltip() {
      return this.tag.key.length + this.tag.value.length > this.displayLength;
    },
    keyDisplay() {
      const key = this.tag.key;

      if (key.length <= this.displayLength) {
        return key;
      } else {
        return `${key.substring(0, this.displayLength)}...`;
      }
    },
    valueDisplay() {
      const remainingCharacters = this.displayLength - this.tag.key.length;

      if (remainingCharacters < 0) {
        return "";
      } else if (this.tag.value.length <= remainingCharacters) {
        return this.append("", this.tag.value);
      } else {
        return this.append(
          "",
          `${this.tag.value.substring(0, remainingCharacters)}...`,
        );
      }
    },
    highlightedValueDisplay() {
      if (!this.isHighlighted("tags.value")) {
        return "";
      }

      const remainingCharacters = this.displayLength - this.tag.key.length;

      if (remainingCharacters < 0) {
        return "";
      } else {
        return this.append("", this.tag.highlights["tags.value"][0]);
      }
    },
    enableDelete() {
      return this.deletable && !this.locked;
    },
    locked() {
      return this.tag.locked;
    },
    configUrl() {
      return OPSLEVEL_YAML_DOC;
    },
  },

  methods: {
    append(key, value) {
      return `${key}: ${value}`;
    },
    tagClick() {
      this.$emit("tagClick", this.tag);
    },
    isHighlighted(property) {
      return this.tag.highlights && this.tag.highlights[property];
    },
  },
};
</script>

<style scoped>
.tagKey {
  color: rgba(0, 0, 0, 0.85);
}
.tagValue {
  color: rgba(0, 0, 0, 0.65);
}
.tag {
  cursor: default;
  white-space: normal;
  word-wrap: break-word;
}
</style>
