<template>
  <a-card
    :loading="loading"
    :bodyStyle="{ padding: '16px 24px' }"
    :bordered="false"
    class="header-border"
  >
    <a-row type="flex" align="middle">
      <div :style="`width: ${contentWidth}%; float: left;`">
        <a-breadcrumb v-if="$slots.breadcrumbs">
          <slot name="breadcrumbs" />
        </a-breadcrumb>
        <slot v-if="$slots.breadcrumbsComponent" name="breadcrumbsComponent" />
        <div v-if="avatarSrc" class="header-avatar">
          <Avatar
            :size="60"
            :userName="title"
            :gravatarHref="avatarSrc"
            styleProps="vertical-align: middle; font-size: 250%"
          />
          <a
            class="gravatar-link"
            href="https://www.gravatar.com"
            target="_blank"
          >
            Powered by Gravatar
          </a>
        </div>
        <div class="row">
          <div v-if="$slots.title" class="title">
            <slot name="title" />
          </div>
          <h2 v-else class="title" style="height: 24px; margin-bottom: 0px">
            {{ title }}
          </h2>
        </div>
        <h3 v-if="subtitle" class="subtitle">
          {{ subtitle }}
        </h3>
        <h3 v-if="$slots.subtitle" class="subtitle">
          <slot name="subtitle" />
        </h3>

        <template v-if="$slots.content">
          <div style="margin-top: 16px">
            <slot name="content" />
          </div>
        </template>
      </div>
      <div :style="`float: right; width: ${actionWidth}%;`">
        <div v-if="$slots.actions" class="action">
          <slot name="actions" />
        </div>
      </div>
    </a-row>
  </a-card>
</template>

<script>
import Avatar from "./atoms/Avatar.vue";
export default {
  name: "HeaderCard",
  components: { Avatar },

  props: {
    // eslint-disable-next-line vue/require-default-prop -- This appears to have a default of ''
    title: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop -- This appears to have a default of ''
    subtitle: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop -- This appears to have a default of ''
    avatarSrc: {
      type: String,
    },
    contentWidth: {
      type: Number,
      default: 60,
    },
    actionWidth: {
      type: Number,
      default: 40,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  justify-content: space-between;
  padding: 4px 0px;
}
.title {
  color: black;
  font-size: 1.5em;
  font-weight: 500;
  width: 100%;
}
.action {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: right;
}
h3.subtitle {
  font-weight: normal;
  margin-bottom: 0px;
  padding: 8px 0px 0px 0px;
  color: #8c8c8c;
}

.header-avatar {
  margin-right: 16px;
  width: 80px;
  text-align: center;
  display: block;
  float: left;
}

.gravatar-link {
  text-decoration: none;
  color: #8c8c8c;
  font-size: 8px;
  font-style: italic;
  white-space: nowrap;
}

.header-border {
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
</style>
