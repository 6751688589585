import * as icons from "@/shared/icons.js";

// Repository type to icon name mapping
const REPO_ICONS = {
  Bitbucket: "bitbucket",
  GitHub: "github",
  GitLab: "gitlab",
};

// Contact type to icon type mapping
const CONTACT_ICONS = {
  email: "mail",
  slack: "slack",
  slack_handle: "slack",
  web: "global",
};

export const repositoryIcon = (type) => {
  return REPO_ICONS[type];
};

export const contactIcon = (type) => {
  return CONTACT_ICONS[type];
};

const EXPLICIT_ICON_MAPPING = {
  googlecloudplatform: "GoogleCloud",
  googlecloud: "GoogleCloud",
  google: "GoogleCloud",
  gcp: "GoogleCloud",
  azure: "AzureResources",
  microsoft: "AzureDevops",
  msazure: "AzureDevops",
  azureresources: "AzureResources",
  aws: "AwsAmazonCom",
  amazon: "AwsAmazonCom",
  amazonwebservice: "AwsAmazonCom",
  amazonwebservices: "AwsAmazonCom",
};

export const getIconViaFuzzyMatching = (iconInputName) => {
  if (!iconInputName) {
    return null;
  }

  // lowercase matching
  const squashedIconPrefix = iconInputName.replace(/[\s.\-_]/g, "");
  const casedIconName = `${squashedIconPrefix
    .charAt(0)
    .toUpperCase()}${squashedIconPrefix.slice(1).toLowerCase()}Icon`;
  const squashedIcon = icons[casedIconName];

  if (squashedIcon) {
    return squashedIcon;
  }

  // PascalCase matching
  const pascalIconPrefix = iconInputName
    .replace(/[\s.\-_]+/g, " ")
    .toLowerCase()
    .split(" ")
    .reduce((acc, cur) => {
      return acc + cur.charAt(0).toUpperCase() + cur.slice(1);
    }, "");
  const pascalIcon = icons[pascalIconPrefix + "Icon"];

  if (pascalIcon) {
    return pascalIcon;
  }

  // Explicit allow-list matching
  const lowerCasedPrefix = squashedIconPrefix.toLowerCase();

  if (EXPLICIT_ICON_MAPPING[lowerCasedPrefix]) {
    return icons[EXPLICIT_ICON_MAPPING[lowerCasedPrefix] + "Icon"];
  }

  // Fuzzy matching
  const inputContainsIconMatch = Object.keys(icons).find((iconKey) =>
    squashedIconPrefix
      .toLowerCase()
      .includes(iconKey.toLowerCase().replace("icon", "")),
  );
  const inputContainsIconMatchIcon = icons[inputContainsIconMatch];

  if (inputContainsIconMatchIcon) {
    return inputContainsIconMatchIcon;
  }

  return null;
};
