<template>
  <span>
    <template v-if="isMissingAttribute">
      <i>{{ missingAttributeText }}</i>
    </template>
    <template v-else-if="hasBrokenSlug">
      <BrokenSlug :type="type" :value="attribute.slug" />
    </template>
    <template v-else>
      <slot name="decorator">
        {{ attribute.name }}
      </slot>
    </template>
  </span>
</template>

<script>
import BrokenSlug from "@/components/atoms/BrokenSlug.vue";

export default {
  components: {
    BrokenSlug,
  },

  props: {
    attribute: {
      type: Object,
      required: false,
      default: undefined,
    },
    type: {
      type: String,
      required: false,
      default: "value",
    },
    missingAttributeText: {
      type: String,
      default: "",
      required: false,
    },
  },

  computed: {
    hasBrokenSlug() {
      return !this.attribute.name && !!this.attribute.slug;
    },
    isMissingAttribute() {
      return !this.attribute || (!this.attribute.name && !this.attribute.slug);
    },
  },
};
</script>
