import { get } from "lodash";

const emptyValues = [null, NaN, undefined];

export function localeComparator(path, nullFirst = true) {
  return function (a, b) {
    const nullDirection = nullFirst ? 1 : -1;
    const first = get(a, path);
    const second = get(b, path);

    if (emptyValues.includes(first) && emptyValues.includes(second)) {
      return 0;
    } else if (first === second) {
      return 0;
    } else if (first === null || first === undefined) {
      return 1 * nullDirection;
    } else if (second === null || second === undefined) {
      return -1 * nullDirection;
    }

    return first.localeCompare(second, "en", { sensitivity: "base" });
  };
}

export function numericComparator(path) {
  return function (a, b) {
    const first = get(a, path);
    const second = get(b, path);

    if (emptyValues.includes(first) && emptyValues.includes(second)) {
      return 0;
    } else if (first === second) {
      return 0;
    } else if (first === null || first === undefined || isNaN(first)) {
      return 1;
    } else if (second === null || second === undefined || isNaN(second)) {
      return -1;
    }

    return first - second;
  };
}

export function quotientComparator(numerator, denominator) {
  return function (a, b) {
    const a_quotient = get(a, numerator) / (get(a, denominator) || 1);
    const b_quotient = get(b, numerator) / (get(b, denominator) || 1);

    return numericComparator("q")({ q: a_quotient }, { q: b_quotient });
  };
}
