export default () => ({
  levels: [],
  selectedLevels: [],
  isFetchingLevels: false,
  errorsFetchingLevels: [],

  isUpdatingLevel: false,
  errorsUpdatingLevel: [],

  isCreatingLevel: false,
  errorsCreatingLevel: [],

  isDeletingLevel: false,
  errorsDeletingLevel: [],
});
