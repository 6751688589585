import { Icon } from "ant-design-vue";
import OpslevelSvg from "./svgs/opslevel.svg";

// Generic
// ##################################################
import ArraySvg from "./svgs/array.svg";
import AISparkleSvg from "./svgs/ai_sparkle.svg";
import DragDropSvg from "./svgs/drag_drop.svg";

// Integrations
// ##################################################

// generator_hook:svg
import LaceworkSvg from "./svgs/lacework.svg";
import CodacySvg from "./svgs/codacy.svg";
import StackhawkSvg from "./svgs/stackhawk.svg";
import PrismacloudSvg from "./svgs/prisma_cloud.svg";
import SumologicSvg from "./svgs/sumo_logic.svg";
import VeracodeSvg from "./svgs/veracode.svg";
import PrometheusSvg from "./svgs/prometheus.svg";
import DynatraceSvg from "./svgs/dt.svg"; // if we call it `dynatrace.svg` it gets blocked by adblockers
import DeploySvg from "./svgs/deploy.svg";
import CheckSvg from "./svgs/check.svg";
import PayloadSvg from "./svgs/payload.svg";
import ServiceSvg from "./svgs/service.svg";
import PackagesSvg from "./svgs/packages.svg";
import UICustomizationSvg from "./svgs/ui_customization.svg";
import GenericSvg from "./svgs/generic.svg";
import SnykSvg from "./svgs/snyk.svg";
import AquasecuritySvg from "./svgs/aqua_security.svg";
import AwsEcrSvg from "./svgs/aws_ecr.svg";
import SonarQubeSvg from "./svgs/sonarqube.svg";
import JiraSoftwareSvg from "./svgs/jira_software.svg";
import ApidocSvg from "./svgs/api_doc.svg";
import ScimSvg from "./svgs/scim.svg";
import GrypeSvg from "./svgs/grype.svg";
import CoverallsSvg from "./svgs/coveralls.svg";
import JfrogxraySvg from "./svgs/jfrog_xray.svg";

// Infrastructure
import AwsAmazonComSvg from "./svgs/aws.svg";
import AzureResourcesSvg from "./svgs/azureresources.svg";
import GoogleCloudSvg from "./svgs/google_cloud.svg";
import GoogleComSvg from "./svgs/google.svg";

// Repositories
import BitbucketSvg from "./svgs/bitbucket.svg";
import SlackSvg from "./svgs/slack.svg";
import GithubSvg from "./svgs/github.svg";
import GitlabSvg from "./svgs/gitlab.svg";
import JiraSvg from "./svgs/jira.svg";
import AtlassianSvg from "./svgs/atlassian.svg";
import AzureDevopsSvg from "./svgs/azure_devops.svg";
// This is for the repositories table. Ideally we can use a single SVG and add a fill to it instead.
import AzuredevopsSvg from "./svgs/azure_devops_repo.svg";

// Deployment
import GoCdSvg from "./svgs/gocd.svg";
import CodeshipSvg from "./svgs/codeship.svg";
import SpinnakerSvg from "./svgs/spinnaker.svg";
import JenkinsSvg from "./svgs/jenkins.svg";
import FluxSvg from "./svgs/flux.svg";
import CircleCISvg from "./svgs/circleci.svg";
import BuildkiteSvg from "./svgs/buildkite.svg";
import HerokuSvg from "./svgs/heroku.svg";
import DockerSvg from "./svgs/docker.svg";
import DigitalOceanSvg from "./svgs/digitalocean.svg";
import OctopusDeploySvg from "./svgs/octopus.svg";
import GithubActionsSvg from "./svgs/githubactions.svg";

// Errors
import SentrySvg from "./svgs/sentry.svg";
import AirbrakeSvg from "./svgs/airbrake.svg";
import RollbarSvg from "./svgs/rollbar.svg";

// Health Checks
import PingdomSvg from "./svgs/pingdom.svg";

// Incidents
import PagerdutySvg from "./svgs/pagerduty.svg";
import OpsgenieSvg from "./svgs/opsgenie.svg";

// Logs
import SplunkSvg from "./svgs/splunk.svg";
import LogdnaSvg from "./svgs/logdna.svg";
import BugsnagSvg from "./svgs/bugsnag.svg";
import ScalyrSvg from "./svgs/scalyr.svg";

// Metrics
import DatadogSvg from "./svgs/datadog.svg";
import DatadogCheckSvg from "./svgs/datadog_check.svg";
import GrafanaSvg from "./svgs/grafana.svg";
import NewrelicSvg from "./svgs/newrelic.svg";
import HotjarSvg from "./svgs/hotjar.svg";
import MixpanelSvg from "./svgs/mixpanel.svg";

// Runbooks
import ConfluenceSvg from "./svgs/confluence.svg";
import PaperDropboxSvg from "./svgs/paperdropbox.svg";

// Orchestrator
import KubernetesSvg from "./svgs/kubernetes.svg";
import TerraformSvb from "./svgs/terraform.svg";
import NomadSvg from "./svgs/nomad.svg";

// styled AntD
import GradientSettingSvg from "./svgs/gradient_setting.svg";
import GradientSearchSvg from "./svgs/gradient_search.svg";
import GradientCheckSvg from "./svgs/gradient_check.svg";

// Feature Flags
import LaunchdarklySvg from "./svgs/launchdarkly.svg";

// API
import GraphqlSvg from "./svgs/graphql.svg";

// Languages
// ##################################################

import RubySvg from "./svgs/languages/ruby.svg";
import PythonSvg from "./svgs/languages/python.svg";
import JavaSvg from "./svgs/languages/java-duke.svg";
import GolangSvg from "./svgs/languages/golang.svg";
import ScalaSvg from "./svgs/languages/scala.svg";
import JavascriptSvg from "./svgs/languages/javascript.svg";
import CppSvg from "./svgs/languages/cpp.svg";
import HtmlSvg from "./svgs/languages/html.svg";
import CssSvg from "./svgs/languages/css.svg";
import ElixirSvg from "./svgs/languages/elixir.svg";
import SqlSvg from "./svgs/languages/sql.svg";

// Frameworks
// ##################################################

import RailsSvg from "./svgs/frameworks/rails.svg";
import DjangoSvg from "./svgs/frameworks/django.svg";
import ReactSvg from "./svgs/frameworks/react.svg";
import FlaskSvg from "./svgs/frameworks/flask.svg";
import AkkaSvg from "./svgs/frameworks/akka.svg";
import SpringSvg from "./svgs/frameworks/spring.svg";
import EmberSvg from "./svgs/frameworks/ember.svg";

// Generic
// ##################################################
export const ArrayIcon = generateIcon(ArraySvg);
export const AISparkleIcon = generateIcon(AISparkleSvg);
export const DragDropIcon = generateIcon(DragDropSvg);

// Integrations
// ##################################################

// Infrastructure
export const AwsAmazonComIcon = generateIcon(AwsAmazonComSvg);
export const AzureResourcesIcon = generateIcon(AzureResourcesSvg);
export const GoogleCloudIcon = generateIcon(GoogleCloudSvg);
export const GoogleComIcon = generateIcon(GoogleComSvg);

// generator_hook:icon
export const LaceworkIcon = generateIcon(LaceworkSvg);
export const CodacyIcon = generateIcon(CodacySvg);
export const StackhawkIcon = generateIcon(StackhawkSvg);
export const PrismacloudIcon = generateIcon(PrismacloudSvg);
export const SumologicIcon = generateIcon(SumologicSvg);
export const GrafanaIcon = generateIcon(GrafanaSvg);
export const PrometheusIcon = generateIcon(PrometheusSvg);
export const VeracodeIcon = generateIcon(VeracodeSvg);
export const DynatraceIcon = generateIcon(DynatraceSvg);
export const BugsnagIcon = generateIcon(BugsnagSvg);
export const DeployIcon = generateIcon(DeploySvg);
export const CheckIcon = generateIcon(CheckSvg);
export const PayloadIcon = generateIcon(PayloadSvg);
export const ServiceIcon = generateIcon(ServiceSvg);
export const PackagesIcon = generateIcon(PackagesSvg);
export const UICustomizationIcon = generateIcon(UICustomizationSvg);
export const GenericIcon = generateIcon(GenericSvg);
export const SnykIcon = generateIcon(SnykSvg);
export const AquasecurityIcon = generateIcon(AquasecuritySvg);
export const AwsecrIcon = generateIcon(AwsEcrSvg);
export const SonarqubeIcon = generateIcon(SonarQubeSvg);
export const JiraSoftwareIcon = generateIcon(JiraSoftwareSvg);
export const ApidocIcon = generateIcon(ApidocSvg);
export const ScimIcon = generateIcon(ScimSvg);
export const GrypeIcon = generateIcon(GrypeSvg);
export const CoverallsIcon = generateIcon(CoverallsSvg);
export const JfrogxrayIcon = generateIcon(JfrogxraySvg);

// Repositories
export const OpslevelIcon = generateIcon(OpslevelSvg);
export const BitbucketIcon = generateIcon(BitbucketSvg);
export const SlackIcon = generateIcon(SlackSvg);
export const GithubIcon = generateIcon(GithubSvg);
export const GitlabIcon = generateIcon(GitlabSvg);
export const JiraIcon = generateIcon(JiraSvg);
export const AtlassianIcon = generateIcon(AtlassianSvg);
export const AzureDevopsIcon = generateIcon(AzureDevopsSvg);
export const AzuredevopsIcon = generateIcon(AzuredevopsSvg);

// Deployment
export const GocdIcon = generateIcon(GoCdSvg);
export const CodeshipIcon = generateIcon(CodeshipSvg);
export const SpinnakerIcon = generateIcon(SpinnakerSvg);
export const JenkinsIcon = generateIcon(JenkinsSvg);
export const FluxIcon = generateIcon(FluxSvg);
export const CircleciIcon = generateIcon(CircleCISvg);
export const BuildkiteIcon = generateIcon(BuildkiteSvg);
export const HerokuIcon = generateIcon(HerokuSvg);
export const DockerIcon = generateIcon(DockerSvg);
export const DigitaloceanIcon = generateIcon(DigitalOceanSvg);
export const OctopusdeployIcon = generateIcon(OctopusDeploySvg);
export const GithubactionsIcon = generateIcon(GithubActionsSvg);

// Errors
export const SentryIcon = generateIcon(SentrySvg);
export const AirbrakeIcon = generateIcon(AirbrakeSvg);
export const RollbarIcon = generateIcon(RollbarSvg);

// Health Checks
export const PingdomIcon = generateIcon(PingdomSvg);

// Incidents
export const PagerdutyIcon = generateIcon(PagerdutySvg);
export const OpsgenieIcon = generateIcon(OpsgenieSvg);

// Logs
export const SplunkIcon = generateIcon(SplunkSvg);
export const LogdnaIcon = generateIcon(LogdnaSvg);
export const ScalyrIcon = generateIcon(ScalyrSvg);

// Metrics
export const DatadogIcon = generateIcon(DatadogSvg);
export const DatadogcheckIcon = generateIcon(DatadogCheckSvg);
export const NewrelicIcon = generateIcon(NewrelicSvg);
export const NewreliccheckIcon = generateIcon(NewrelicSvg);
export const HotjarIcon = generateIcon(HotjarSvg);
export const MixpanelIcon = generateIcon(MixpanelSvg);

// Runbooks
export const ConfluenceIcon = generateIcon(ConfluenceSvg);
export const PaperDropboxIcon = generateIcon(PaperDropboxSvg);

// Orchestrator
export const KubernetesIcon = generateIcon(KubernetesSvg);
export const TerraformIcon = generateIcon(TerraformSvb);
export const NomadIcon = generateIcon(NomadSvg);

// styled AntD
export const GradientSearchIcon = generateIcon(GradientSearchSvg);
export const GradientSettingIcon = generateIcon(GradientSettingSvg);
export const GradientCheckIcon = generateIcon(GradientCheckSvg);

// Feature Flags
export const LaunchdarklyIcon = generateIcon(LaunchdarklySvg);
export const DesktopIcon = generateTypeIcon("desktop");
export const FileIcon = generateTypeIcon("file");
export const GitIcon = generateTypeIcon("branches");

// API
export const GraphqlIcon = generateIcon(GraphqlSvg);

// Languages
// ##################################################

export const RubyIcon = generateIcon(RubySvg);
export const PythonIcon = generateIcon(PythonSvg);
export const JavaIcon = generateIcon(JavaSvg);
export const GolangIcon = generateIcon(GolangSvg);
export const ScalaIcon = generateIcon(ScalaSvg);
export const JavascriptIcon = generateIcon(JavascriptSvg);
export const CppIcon = generateIcon(CppSvg);
export const HtmlIcon = generateIcon(HtmlSvg);
export const CssIcon = generateIcon(CssSvg);
export const ElixirIcon = generateIcon(ElixirSvg);
export const SqlIcon = generateIcon(SqlSvg);

export const LanguageMap = {
  ruby: "Ruby",
  python: "Python",
  java: "Java",
  go: "Golang",
  golang: "Golang",
  scala: "Scala",
  node: "Javascript",
  "node.js": "Javascript",
  nodejs: "Javascript",
  javascript: "Javascript",
  "c++": "Cpp",
  cpp: "Cpp",
  html: "Html",
  css: "Css",
  elixir: "Elixir",
  sql: "Sql",
  mysql: "Sql",
  tsql: "Sql",
};

// Frameworks
// ##################################################

export const RailsIcon = generateIcon(RailsSvg);
export const DjangoIcon = generateIcon(DjangoSvg);
export const ReactIcon = generateIcon(ReactSvg);
export const FlaskIcon = generateIcon(FlaskSvg);
export const AkkaIcon = generateIcon(AkkaSvg);
export const SpringIcon = generateIcon(SpringSvg);
export const EmberIcon = generateIcon(EmberSvg);

export const FrameworkMap = {
  rails: "Rails",
  django: "Django",
  react: "React",
  flask: "Flask",
  akka: "Akka",
  spring: "Spring",
  ember: "Ember",
  "ember.js": "EmberIcon",
};

// Used to generate ant-design icon from an svg
function generateIcon(icon) {
  return {
    render: (h) => h(Icon, { props: { component: icon } }),
  };
}

function generateTypeIcon(icon) {
  return {
    render: (h) => h(Icon, { props: { type: icon } }),
  };
}

export { generateIcon };

export const IconOverrides = {
  onenr: "NewrelicIcon",
  octopus: "OctopusdeployIcon",
};

// Service Creation Source to icon type mapping
export const ServiceCreationMap = {
  repository_analysis: "OpsLevel",
};
