import { isArray } from "lodash";

export const featureFlags = {
  inject: ["featureFlags"],
  methods: {
    hasFeatureFlag(flag) {
      if (!isArray(this.featureFlags)) {
        return false;
      } else {
        return this.featureFlags.includes(flag);
      }
    },
  },
};
