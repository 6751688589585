import * as types from "./mutation_types.js";
import extractApolloErrors from "@/lib/extract_apollo_errors.js";
import { gqlClient } from "@/shared/apolloClient.js";
import { get } from "lodash";
import {
  TEAM_MEMBERS_QUERY,
  CREATE_MEMBERS_MUTATION,
  DELETE_MEMBERS_MUTATION,
} from "./queries.js";
import { formatQueryParams, formatTableData } from "@/shared/table_helper.js";

export const setInitialData = ({ commit }, data) => {
  commit(types.SET_INITIAL_DATA, data);
};

export const requestMembers = ({ commit }) => {
  commit(types.REQUEST_MEMBERS);
};

export const receiveMembersSuccess = (
  { commit },
  { members, totalCount, permissions },
) => {
  commit(types.RECEIVE_MEMBERS_SUCCESS, { members, totalCount, permissions });
};

export const receiveMembersError = ({ commit }, errors) =>
  commit(types.RECEIVE_MEMBERS_ERROR, { errors });

export const setTeamId = ({ commit }, { teamId }) => {
  commit(types.SET_TEAM_ID, { teamId });
};

export const fetchTeamMembers = (
  { commit, dispatch, state },
  { queryParams },
) => {
  dispatch("requestMembers");

  commit(types.UPDATE_TABLE_DATA, formatTableData(state, queryParams));

  gqlClient
    .query({
      query: TEAM_MEMBERS_QUERY,
      variables: { id: state.teamId, ...formatQueryParams(state) },
    })
    .then((response) => {
      const members = get(response, "data.account.team.members.nodes");
      const totalCount = get(response, "data.account.team.members.totalCount");
      const permissions = get(response, "data.account.team.permissions");

      dispatch("receiveMembersSuccess", { members, totalCount, permissions });
    })
    .catch((error) => {
      dispatch("receiveMembersError", extractApolloErrors(error));
    });
};

export const createTeamMemberships = ({ commit }, { params }) => {
  const teamId = params.teamId;

  commit(types.REQUEST_CREATE_MEMBERS);
  gqlClient
    .mutate({
      mutation: CREATE_MEMBERS_MUTATION,
      variables: { teamId: teamId, members: params.members },
    })
    .then((response) => {
      const members = get(response, "data.teamMembershipCreate.members");
      const errors = get(response, "data.teamMembershipCreate.errors");

      if (members && members.length) {
        commit(types.RECEIVE_CREATE_MEMBERS_SUCCESS, {
          teamId,
          members: members,
          errors: errors,
        });
      } else {
        commit(types.RECEIVE_CREATE_MEMBERS_ERROR, { teamId, errors: errors });
      }
    })
    .catch((rawError) => {
      const errors = extractApolloErrors(rawError);

      commit(types.RECEIVE_CREATE_MEMBERS_ERROR, { teamId, errors });
    });
};

export const deleteTeamMemberships = ({ commit }, { params }) => {
  const teamId = params.teamId;

  commit(types.REQUEST_DELETE_MEMBERS);
  gqlClient
    .mutate({
      mutation: DELETE_MEMBERS_MUTATION,
      variables: { teamId: teamId, members: params.members },
    })
    .then((response) => {
      const errors = get(response, "data.teamMembershipDelete.errors");

      if (errors.length) {
        commit(types.RECEIVE_DELETE_MEMBERS_ERROR, { teamId, errors });
      } else {
        const members = get(
          response,
          "data.teamMembershipDelete.deletedMembers",
        );

        commit(types.RECEIVE_DELETE_MEMBERS_SUCCESS, { teamId, members });
      }
    })
    .catch((rawError) => {
      const errors = extractApolloErrors(rawError);

      commit(types.RECEIVE_DELETE_MEMBERS_ERROR, { teamId, errors });
    });
};
