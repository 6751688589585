import { tableState } from "@/shared/table_helper.js";

export default (itemsPerPage = 20) => ({
  isFetchingSearchResults: false,
  errorsFetchingSearchResults: [],
  searchResults: [],
  filteredCount: 0,
  totalLevelCount: 0,
  currentSearchTab: null,
  ...tableState({ itemsPerPage }),
});
