<template>
  <CollapsibleList :items="tags" :numPeeking="numPeeking">
    <template v-slot:item="{ slotProps }">
      <Tag :tag="slotProps.item" :displayLength="25" :wrapText="true" />
    </template>
  </CollapsibleList>
</template>

<script>
import CollapsibleList from "@/components/CollapsibleList.vue";
import Tag from "@/components/Tag.vue";

export default {
  components: {
    CollapsibleList,
    Tag,
  },

  props: {
    tags: {
      type: Array,
      required: true,
    },
    numPeeking: {
      type: Number,
      required: false,
      default: 1,
    },
  },
};
</script>
