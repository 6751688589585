<template>
  <img
    src="/OpsLevelLogoMark-Primary.svg"
    style="height: 30px; width: 30px; opacity: 0.75"
  />
</template>

<script>
export default { name: "SpinningLogo" };
</script>

<style scoped>
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  25% {
    opacity: 0.7;
  }
  50% {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
  75% {
    transform: scale(1.3);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
img {
  animation-name: loading-animation;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
</style>
