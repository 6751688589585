import * as types from "./mutation_types.js";
import { gqlClient } from "@/shared/apolloClient.js";
import { REQUEST_APPLICATION_CONFIGS } from "./queries.js";
import extractApolloErrors from "@/lib/extract_apollo_errors.js";

export const setIsSidebarCollapsed = ({ commit }, { collapsed }) => {
  commit(types.SET_IS_SIDEBAR_COLLAPSED, { collapsed });
};

export const fetchApplicationConfigs = ({ commit }) => {
  commit(types.REQUEST_APPLICATION_CONFIGS);

  gqlClient
    .query({ query: REQUEST_APPLICATION_CONFIGS, variables: {} })
    .then((response) => {
      commit(types.RECEIVE_APPLICATION_CONFIGS_SUCCESS, {
        elasticsearchEnabled: response?.data?.elasticsearchEnabled,
        environment: response?.data?.environment,
      });
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_APPLICATION_CONFIGS_ERROR,
        extractApolloErrors(errors),
      );
    });
};
