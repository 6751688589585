<template>
  <a-card>
    <div v-if="isFetchingSearchResults" class="loading">
      <SpinningLogo />
    </div>
    <h3 v-else-if="isEmptyDomainSearchResult" class="hint">
      <p>
        We couldn't find any results matching "<b>{{ searchTerm }}</b
        >".<br />
        Please try using a different search term.
      </p>
    </h3>
    <template v-else>
      <h3>
        {{ headerText }}
      </h3>
      <DomainsSearchTable :searchTerm="searchTerm" />
    </template>
  </a-card>
</template>

<script>
import pluralize from "pluralize";
import { mapState } from "vuex";
import DomainsSearchTable from "./DomainsSearchTable.vue";
import SpinningLogo from "@/components/SpinningLogo.vue";

export default {
  components: {
    DomainsSearchTable,
    SpinningLogo,
  },

  props: {
    searchTerm: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      isFetchingSearchResults: (state) =>
        state.domainsSearch.isFetchingSearchResults,
      filteredSearchCount: (state) => state.domainsSearch.filteredCount,
    }),
    isEmptyDomainSearchResult() {
      return this.filteredSearchCount === 0 && !this.isFetchingSearchResults;
    },
    headerText() {
      const count = this.filteredSearchCount;

      return `${count} ${pluralize("Domain", count)}`;
    },
  },
};
</script>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
}
</style>
