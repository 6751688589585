import actorPermissions from "@/modules/actorPermissions/index.js";
import { mapState, mapActions } from "vuex";
import { upperFirst, get, isNil } from "lodash";
import { captureMessage } from "@sentry/vue";

function parseActorPermission(resource, action, prefix, defaultReturnValue) {
  if (this.actorPermissions === null) {
    return defaultReturnValue;
  }

  const propName = `${prefix}${upperFirst(action)}`;
  const propValue = get(this.actorPermissions, `${resource}.${propName}`);

  if (isNil(propValue)) {
    captureMessage(
      `Invalid permission queried: '${resource}.${propName}'`,
      "error",
    );

    return defaultReturnValue;
  }

  return propValue;
}

export const actorPermissionsMixin = {
  created() {
    this.$store.registerModuleOnce("actorPermissions", actorPermissions());

    // FIXME: Improve this to fetch only when needed and/or avoid redundant calls
    this.fetchActorPermissions();
  },
  computed: {
    ...mapState({
      actorPermissions: (state) => state.actorPermissions.resource,
    }),
  },
  methods: {
    ...mapActions({
      fetchActorPermissions: "actorPermissions/fetchActorPermissions",
    }),
    hasActorPermission(resource, action) {
      return parseActorPermission.call(this, resource, action, "can", false);
    },
    permittedAttributesFor(resource, action) {
      return parseActorPermission.call(
        this,
        resource,
        action,
        "permittedAttributesFor",
        [],
      );
    },
  },
};
