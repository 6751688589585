import { escape } from "lodash";

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        sanitize(text) {
          return escape(text);
        },
      },
    });
  },
};
