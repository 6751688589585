import * as types from "./mutation_types.js";
import extractApolloErrors from "@/lib/extract_apollo_errors.js";
import { gqlClient } from "@/shared/apolloClient.js";
import { get } from "lodash";
import { JOBS_QUERY, JOBS_SUBSCRIPTION } from "./queries.js";

export const setInitialData = ({ commit }, data) => {
  commit(types.SET_INITIAL_DATA, data);
};

export const requestJobs = ({ commit }) => {
  commit(types.REQUEST_JOBS);
};

export const receiveJobsSuccess = ({ commit }, response) => {
  commit(types.RECEIVE_JOBS_SUCCESS, response);
};

export const receiveJobsError = ({ commit }, errors) => {
  commit(types.RECEIVE_JOBS_ERROR, errors);
};

export const fetchJobs = ({ dispatch }, params) => {
  dispatch("requestJobs");

  gqlClient
    .query({
      variables: params,
      query: JOBS_QUERY,
    })
    .then((response) => {
      const jobs = get(response, "data.account.jobRuns.nodes");

      dispatch("receiveJobsSuccess", jobs);
    })
    .catch((errors) => {
      dispatch("receiveJobsError", extractApolloErrors(errors));
    });
};

export const subscribeToJobUpdates = ({ dispatch }, { templateName }) => {
  gqlClient
    .subscribe({
      query: JOBS_SUBSCRIPTION,
      variables: {
        templateName,
      },
    })
    .subscribe((event) => {
      // TODO: handle errors from our server
      // https://github.com/zenparsing/zen-observable#observablesubscribeobserver
      const jobs = get(event, "data.runnerJobStatusUpdated.nodes");

      dispatch("receiveJobsSuccess", jobs);
    });
};
