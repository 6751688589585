export const SET_INITIAL_DATA = "SET_INITIAL_DATA";

export const REQUEST_MEMBERS = "REQUEST_MEMBERS";
export const RECEIVE_MEMBERS_SUCCESS = "RECEIVE_MEMBERS_SUCCESS";
export const RECEIVE_MEMBERS_ERROR = "RECEIVE_MEMBERS_ERROR";

export const REQUEST_CREATE_MEMBERS = "REQUEST_CREATE_MEMBERS";
export const RECEIVE_CREATE_MEMBERS_SUCCESS = "RECEIVE_CREATE_MEMBERS_SUCCESS";
export const RECEIVE_CREATE_MEMBERS_ERROR = "RECEIVE_CREATE_MEMBERS_ERROR";

export const REQUEST_DELETE_MEMBERS = "REQUEST_DELETE_MEMBERS";
export const RECEIVE_DELETE_MEMBERS_SUCCESS = "RECEIVE_DELETE_MEMBERS_SUCCESS";
export const RECEIVE_DELETE_MEMBERS_ERROR = "RECEIVE_DELETE_MEMBERS_ERROR";

export const UPDATE_TABLE_DATA = "UPDATE_TABLE_DATA";

export const SET_TEAM_ID = "SET_TEAM_ID";
