import axios from "axios";
import csrf from "./csrf.js";

axios.defaults.headers.common[csrf.headerKey] = csrf.token;
// Used by Rails to check if it is a valid XHR request
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const application_json = "application/json;charset=utf-8";

axios.defaults.headers.common["Accept"] = application_json;
axios.defaults.headers.common["Content-Type"] = application_json;

export default axios;
