import * as types from "./mutation_types.js";

export default {
  [types.REQUEST_LEVELS](state) {
    state.isFetchingLevels = true;
    state.errorsFetchingLevels = [];
  },
  [types.RECEIVE_LEVELS_SUCCESS](state, levels) {
    state.levels = levels;
    state.selectedLevels = levels.filter((level) => {
      return level.index != 0;
    });
    state.isFetchingLevels = false;
  },
  [types.RECEIVE_LEVELS_ERROR](state, errors) {
    state.isFetchingLevels = false;
    state.errorsFetchingLevels = errors;
  },
  [types.REQUEST_UPDATE_LEVEL](state) {
    state.isUpdatingLevel = true;
    state.errorsUpdatingLevel = [];
  },
  [types.RECEIVE_UPDATE_LEVEL_SUCCESS](state, level) {
    state.levels = state.levels.map((currentLevel) =>
      currentLevel.id === level.id ? level : currentLevel,
    );
    state.isUpdatingLevel = false;
  },
  [types.RECEIVE_UPDATE_LEVEL_ERROR](state, errors) {
    state.errorsUpdatingLevel = errors;
    state.isUpdatingLevel = false;
  },
  [types.REQUEST_CREATE_LEVEL](state) {
    state.isCreatingLevel = true;
    state.errorsCreatingLevel = [];
  },
  [types.RECEIVE_CREATE_LEVEL_SUCCESS](state, level) {
    state.levels = [...state.levels, level];
    state.isCreatingLevel = false;
  },
  [types.RECEIVE_CREATE_LEVEL_ERROR](state, errors) {
    state.errorsCreatingLevel = errors;
    state.isCreatingLevel = false;
  },
  [types.REQUEST_DELETE_LEVEL](state) {
    state.isDeletingLevel = true;
    state.errorsDeletingLevel = [];
  },
  [types.RECEIVE_DELETE_LEVEL_SUCCESS](state, deletedLevelId) {
    state.levels = state.levels.filter(
      (currentLevel) => currentLevel.id !== deletedLevelId,
    );
    state.isDeletingLevel = false;
  },
  [types.RECEIVE_DELETE_LEVEL_ERROR](state, errors) {
    state.errorsDeletingLevel = errors;
    state.isDeletingLevel = false;
  },
};
