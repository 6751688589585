import * as types from "./mutation_types.js";
import extractApolloErrors from "@/lib/extract_apollo_errors.js";
import { gqlClient } from "@/shared/apolloClient.js";
import * as queries from "./queries.js";
import { formatQueryParams, formatTableData } from "@/shared/table_helper.js";
import { acquireLock, validLock } from "@/shared/lock_helper.js";

import { get } from "lodash";

export const setInitialData = ({ commit }, data) => {
  commit(types.UPDATE_TABLE_DATA, data);
};

export const fetchTeam = ({ commit }, params) => {
  commit(types.REQUEST_TEAM);
  gqlClient
    .query({
      query: queries.FETCH_TEAM_QUERY_FULL,
      variables: params,
    })
    .then((response) => {
      const team = get(response, "data.account.team");

      commit(types.RECEIVE_TEAM_SUCCESS, team);
    })
    .catch((error) => {
      commit(types.RECEIVE_TEAM_ERROR, extractApolloErrors(error));
    });
};

export const fetchTeamWithMembers = ({ commit }, params) => {
  commit(types.REQUEST_TEAM);
  gqlClient
    .query({
      query: queries.FETCH_TEAM_QUERY_FULL_MEMBERS,
      variables: params,
    })
    .then((response) => {
      const team = get(response, "data.account.team");

      commit(types.RECEIVE_TEAM_SUCCESS, team);
    })
    .catch((error) => {
      commit(types.RECEIVE_TEAM_ERROR, extractApolloErrors(error));
    });
};

export const fetchTeamCampaignPerformance = ({ commit }, params) => {
  commit(types.REQUEST_TEAM_CAMPAIGN_PERFORMANCE);
  gqlClient
    .query({
      query: queries.TEAM_CAMPAIGN_PERFORMANCE,
      variables: params,
    })
    .then((response) => {
      const records = get(
        response,
        "data.account.team.teamCampaignPerformance",
      );

      commit(types.RECEIVE_TEAM_CAMPAIGN_PERFORMANCE_SUCCESS, records);
    })
    .catch((error) => {
      commit(
        types.RECEIVE_TEAM_CAMPAIGN_PERFORMANCE_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const fetchTeams = ({ commit }, queryParams = {}, full = false) => {
  commit(types.REQUEST_TEAMS);

  const namespace = "fetchTeams";
  const lock = acquireLock(namespace);

  gqlClient
    .query({
      query: full ? queries.TEAMS_QUERY_FULL : queries.TEAMS_QUERY_SIMPLE,
      variables: { sortBy: "name_ASC", ...queryParams },
    })
    .then((response) => {
      if (validLock(namespace, lock)) {
        const teams = get(response, "data.account.teams.nodes");
        const totalCount = get(response, "data.account.teams.totalCount");

        commit(types.RECEIVE_TEAMS_SUCCESS, {
          teams,
          totalCount,
        });
      }
    })
    .catch((errors) => {
      if (validLock(namespace, lock)) {
        commit(types.RECEIVE_TEAMS_ERROR, extractApolloErrors(errors));
      }
    });
};

export const fetchTeamsFull = ({ commit, state }, queryParams = {}) => {
  return fetchTeams({ commit, state }, queryParams, true);
};

export const fetchTeamsForUser = ({ commit }, userId) => {
  commit(types.REQUEST_TEAMS);

  gqlClient
    .query({
      query: queries.TEAMS_FOR_USER,
      variables: { userId },
    })
    .then((response) => {
      const teams = response.data.account.user.teams.nodes;
      const totalCount = response.data.account.user.teams.totalCount;

      commit(types.RECEIVE_TEAMS_SUCCESS, {
        teams,
        totalCount,
      });
    })
    .catch((errors) => {
      commit(types.RECEIVE_TEAMS_ERROR, extractApolloErrors(errors));
    });
};

export const updateTeamsTableData = (
  { commit, state, dispatch },
  { queryParams },
) => {
  if (queryParams != undefined) {
    commit(types.UPDATE_TABLE_DATA, formatTableData(state, queryParams));
  }

  dispatch("fetchTeamsFull", formatQueryParams(state));
};

export const updateUserTeamsTableData = (
  { commit, state, dispatch },
  userId,
  queryParams,
) => {
  if (queryParams != undefined) {
    commit(types.UPDATE_TABLE_DATA, formatTableData(state, queryParams));
  }

  dispatch("fetchTeamsForUser", userId);
};

export const updateTeam = ({ commit }, { params }) => {
  commit(types.REQUEST_UPDATE_TEAM);

  gqlClient
    .mutate({
      mutation: queries.UPDATE_TEAM_MUTATION,
      variables: params,
    })
    .then((response) => {
      const errors = response.data.teamUpdate.errors;

      if (errors.length) {
        commit(types.RECEIVE_UPDATE_TEAM_ERROR, errors);
      }

      const team = response.data.teamUpdate.team;

      commit(types.RECEIVE_UPDATE_TEAM_SUCCESS, team);
    })
    .catch((errors) => {
      commit(types.RECEIVE_UPDATE_TEAM_ERROR, extractApolloErrors(errors));
    });
};

export const createTeam = ({ commit }, { params }) => {
  commit(types.REQUEST_CREATE_TEAM);

  gqlClient
    .mutate({
      mutation: queries.CREATE_TEAM_MUTATION,
      variables: params,
    })
    .then((response) => {
      const errors = response.data.teamCreate.errors;

      if (errors.length) {
        commit(types.RECEIVE_CREATE_TEAM_ERROR, errors);
      }

      const team = response.data.teamCreate.team;

      commit(types.RECEIVE_CREATE_TEAM_SUCCESS, team);
    })
    .catch((errors) => {
      commit(types.RECEIVE_CREATE_TEAM_ERROR, extractApolloErrors(errors));
    });
};

export const deleteTeam = ({ commit }, params) => {
  commit(types.REQUEST_DELETE_TEAM);
  gqlClient
    .mutate({
      mutation: queries.DELETE_TEAM_MUTATION,
      variables: params,
    })
    .then((response) => {
      const errors = response.data.teamDelete.errors;

      if (errors.length) {
        commit(types.RECEIVE_DELETE_TEAM_ERROR, errors);
      } else {
        const teamId = response.data.teamDelete.deletedTeamId;

        commit(types.RECEIVE_DELETE_TEAM_SUCCESS, teamId);
      }
    })
    .catch((errors) => {
      commit(types.RECEIVE_DELETE_TEAM_ERROR, extractApolloErrors(errors));
    });
};

export const addTeamContact = ({ commit }, { params, dataIndex }) => {
  commit(types.REQUEST_ADD_TEAM_CONTACT);

  const mutation = params["id"]
    ? queries.UPDATE_TEAM_CONTACT_MUTATION
    : queries.ADD_TEAM_CONTACT_MUTATION;

  gqlClient
    .mutate({
      mutation: mutation,
      variables: params,
    })
    .then((response) => {
      const errors =
        get(response.data, "contactCreate.errors") ||
        get(response.data, "contactUpdate.errors");

      if (errors.length) {
        commit(types.RECEIVE_ADD_TEAM_CONTACT_ERROR, {
          errors: errors,
          dataIndex: dataIndex,
        });
      } else {
        const contact =
          get(response.data, "contactCreate.contact") ||
          get(response.data, "contactUpdate.contact");

        commit(types.RECEIVE_ADD_TEAM_CONTACT_SUCCESS, { contact, dataIndex });
      }
    })
    .catch((errors) => {
      commit(types.RECEIVE_ADD_TEAM_CONTACT_ERROR, {
        errors: extractApolloErrors(errors),
        dataIndex: dataIndex,
      });
    });
};

export const deleteTeamContact = ({ commit }, params) => {
  commit(types.REQUEST_DELETE_TEAM_CONTACT);

  return gqlClient
    .mutate({
      mutation: queries.DELETE_TEAM_CONTACT_MUTATION,
      variables: params,
    })
    .then((response) => {
      const errors = response.data.contactDelete.errors;

      if (errors.length) {
        commit(
          types.RECEIVE_DELETE_TEAM_CONTACT_ERROR,
          errors.map((err) => err.message),
        );
      } else {
        commit(
          types.RECEIVE_DELETE_TEAM_CONTACT_SUCCESS,
          response.data.contactDelete.deletedContactId,
        );
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_DELETE_TEAM_CONTACT_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const setTeamContactAsDefault = ({ commit }, params) => {
  commit(types.REQUEST_SET_DEFAULT_TEAM_CONTACT);

  return gqlClient
    .mutate({
      mutation: queries.SET_DEFAULT_TEAM_CONTACT_MUTATION,
      variables: params,
    })
    .then((response) => {
      const errors = response.data.contactUpdate.errors;

      if (errors.length) {
        commit(
          types.RECEIVE_SET_DEFAULT_TEAM_CONTACT_ERROR,
          errors.map((err) => err.message),
        );
      } else {
        commit(
          types.RECEIVE_SET_DEFAULT_TEAM_CONTACT_SUCCESS,
          response.data.contactUpdate,
        );
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_SET_DEFAULT_TEAM_CONTACT_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const deleteAndUpdateTeamContact = async ({ commit }, params) => {
  await deleteTeamContact({ commit }, params);
  setTeamContactAsDefault({ commit }, params);
};

export const fetchTreeTeams = ({ commit }, query) => {
  commit(types.REQUEST_TEAMS_TREE);

  gqlClient
    .query({
      query: queries.TREE_TEAMS_WITH_PARENT,
      variables: query,
    })
    .then((response) => {
      const teams = get(response, "data.account.teams");

      commit(types.RECEIVE_TEAMS_TREE_SUCCESS, {
        teams,
      });
    })
    .catch((errors) => {
      commit(types.RECEIVE_TEAMS_TREE_ERROR, extractApolloErrors(errors));
    });
};
