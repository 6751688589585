import { gql } from "@apollo/client";

export const TRACK_USER_EVENT = gql`
  mutation userInteractionEvent($name: String!) {
    userInteractionEvent(input: { name: $name }) {
      userEvent {
        name
      }
    }
  }
`;
