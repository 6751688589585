<template>
  <a-layout-footer class="global-footer">
    Copyright © {{ year }} J/K Labs Inc. All Rights Reserved.
  </a-layout-footer>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.global-footer {
  text-align: center;
  margin: 10px;
  padding: 0px;
  opacity: 0.45;
}
</style>
