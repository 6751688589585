<template>
  <span>
    {{ value }}
    <FailingBadge>
      <template slot="title">
        <template v-if="configUrl">
          <p>{{ errorMessage }}</p>
          <p>
            Please update this {{ configType }}'s
            <a :href="configUrl" target="_blank"> opslevel.yml </a>
            with a valid identifier.
          </p>
        </template>
        <template v-else>
          <span>{{ errorMessage }}</span>
        </template>
      </template>
    </FailingBadge>
  </span>
</template>

<script>
import FailingBadge from "@/components/atoms/FailingBadge.vue";

export default {
  components: { FailingBadge },

  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    configUrl: {
      type: String,
      default: null,
    },
    configType: {
      type: String,
      required: false,
      default: "service",
    },
  },

  computed: {
    errorMessage() {
      return `No ${this.type} found for this identifier.`;
    },
  },
};
</script>
