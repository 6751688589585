import {
  DEFAULT_SORTED_INFO,
  prepareTablePersistence,
} from "@/shared/table_helper.js";

import {
  debouncedSearch,
  validSearchQuery,
  cleanSearchQuery,
} from "@/shared/search_helper.js";

import { mapGetters } from "vuex";

import { debounce, isNil, isEmpty } from "lodash";

const DEBOUNCE_TIME = 500;

export const table = {
  data: function () {
    return {
      lastExecutedSearchQuery: "",
      searchQuery: "",
      handleSearchValueChange: debounce(this.executeSearch, DEBOUNCE_TIME),
    };
  },
  created: function () {
    window.addEventListener("popstate", this.handlePageChange);

    // mixins are created before regular vue components, because of this
    // we need to fetch the data on $nextTick to ensure that the module
    // has been registered
    this.$nextTick(() => this.handlePageChange());
  },
  computed: {
    ...mapGetters({
      tableState: "urlPersistence/tableState",
    }),
    pagination() {
      const total = this.totalTableCount;

      return {
        ...(!isNil(total) && { total }),
        current: this.paginationState.currentPage,
        pageSize: this.pageSize,
        hideOnSinglePage: true,
      };
    },
    pageSize() {
      return this.paginationState.itemsPerPage;
    },
    initialSort() {
      return DEFAULT_SORTED_INFO;
    },
    namespace() {
      return undefined;
    },
    // list of query param keys that should not be updated in the URL when the table state changes
    urlParamsExcludeList() {
      return [];
    },
    urlParamsIncludeList() {
      return [];
    },
    computedColumns() {
      const { sortState } = this;
      let columns = this.defaultColumns();

      // add columns in the same order of the given `visibleColumns`
      if (this.visibleColumns) {
        columns = this.visibleColumns.reduce(
          (acc, col) => ({ ...acc, [col]: columns[col] }),
          {},
        );
      }

      if (sortState && sortState.key != undefined) {
        for (const column in columns) {
          if (columns[column].key === sortState.key) {
            columns[column].sortOrder = sortState.direction;
          }
        }
      }

      return Object.values(columns);
    },
  },
  methods: {
    handleUpdateData(queryParams) {
      queryParams = {
        ...this.customQueryParams(),
        ...queryParams,
      };

      this.updateTableData(
        prepareTablePersistence(
          {
            queryParams,
            defaultSort: this.initialSort,
            namespace: this.namespace,
            urlParamsExcludeList: this.urlParamsExcludeList,
            urlParamsIncludeList: this.urlParamsIncludeList,
          },
          this.urlPersistenceVersion,
        ),
      );
    },
    handleTableChange(pagination, filters, sorter) {
      // if table sorter changes, return to page 1
      if (
        (!isEmpty(sorter) || this.sortState.direction) &&
        (this.sortState.direction != sorter.order ||
          this.sortState.key != sorter.columnKey)
      ) {
        pagination = { ...pagination, current: 1 };
      }

      const queryParams = {
        pagination,
        searchTerm: this.searchQuery,
        filters: this.filterState,
        sorter,
      };

      this.handleUpdateData(queryParams);
    },
    handlePageChange() {
      const queryParams = this.tableState({
        defaultSort: this.initialSort,
        namespace: this.namespace,
        urlParamsIncludeList: this.urlParamsIncludeList,
      });

      this.handleUpdateData(queryParams);

      this.searchQuery = this.searchTermState;
      this.lastExecutedSearchQuery = this.searchTermState;
    },
    handleClearFilters() {
      this.searchQuery = "";
      const queryParams = {
        pagination: { current: 1 },
        searchTerm: "",
        sorter: this.initialSort,
        filters: {},
      };

      this.handleUpdateData(queryParams);
    },
    handleImmediateSearch() {
      // prevent any already-delayed searches from being executed
      debouncedSearch.cancel();
      this.executeSearch();
    },
    executeSearch() {
      const newQuery = cleanSearchQuery(this.searchQuery);

      if (
        !validSearchQuery(newQuery) ||
        newQuery == this.lastExecutedSearchQuery
      ) {
        return;
      }

      this.lastExecutedSearchQuery = newQuery;
      const queryParams = {
        pagination: { current: 1 },
        searchTerm: cleanSearchQuery(this.searchQuery),
        sorter: this.searchQuery?.length ? {} : this.initialSort,
        filters: this.filterState,
      };

      this.handleUpdateData(queryParams);
    },
    refreshData({ pageNumber = null } = {}) {
      const queryParams = {
        pagination: {
          current: pageNumber || this.paginationState.currentPage || 1,
        },
        searchTerm: this.searchQuery,
        sorter: {
          columnKey: this.sortState.key,
          order: this.sortState.direction,
        },
        filters: this.filterState,
      };

      this.handleUpdateData(queryParams);
    },
    noDataTemplate(tableType, visible) {
      const searchText = cleanSearchQuery(this.lastExecutedSearchQuery).length
        ? ` and query "${this.lastExecutedSearchQuery}"`
        : "";
      const missingText =
        `We couldn't find any ${tableType} matching the selected filters` +
        searchText +
        ".";

      if (!visible) {
        return "No Data";
      } else {
        // TODO: after upgrading ant-design to >= 1.6.0, we should use an
        //      `a-config-provider` + `#renderEmpty` slot to avoid this mess.
        return (
          <span>
            <call-to-action text={missingText} type="primary">
              <img
                src="/status_images/no_data.svg"
                style="width: 175px; paddingBottom: 10px;"
              />
            </call-to-action>
            <div>
              <a-button type="primary" onClick={this.handleClearFilters}>
                {" "}
                Clear All Filters{" "}
              </a-button>
            </div>
          </span>
        );
      }
    },
    // Filters allow reducing the table set based on  database columns. For
    // the case where this doesn't provide sufficient
    // control, the including class can define a "queryParams" method which
    // will be included in table lifecycle events.
    //
    // Properties must be allow-listed by including them in the  urlParamsIncludeList
    customQueryParams() {
      return {};
    },
  },
};
