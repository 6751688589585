import * as types from "./mutation_types.js";
import extractApolloErrors from "@/lib/extract_apollo_errors.js";
import { gqlClient } from "@/shared/apolloClient.js";
import { get } from "lodash";
import {
  ALERT_SOURCES_QUERY,
  SERVICE_ALERTS_QUERY,
  ACCOUNT_HAS_MONITORING_INTEGRATIONS,
  DELETE_ALERT_MUTATION,
  createMultipleAlertSourceServicesMutation,
} from "./queries.js";
import { acquireLock, validLock } from "@/shared/lock_helper.js";

import { formatQueryParams, formatTableData } from "@/shared/table_helper.js";

export const updateAlertsTableData = (
  { commit, state, dispatch },
  { queryParams, service_id },
) => {
  if (queryParams != undefined) {
    commit(types.UPDATE_TABLE_DATA, formatTableData(state, queryParams));
  }

  dispatch("fetchServiceAlerts", { ...formatQueryParams(state), service_id });
};

export const updateIntegrationAlertsTableData = (
  { commit, state, dispatch },
  { queryParams, filter },
) => {
  if (queryParams != undefined) {
    commit(types.UPDATE_TABLE_DATA, formatTableData(state, queryParams));
  }

  dispatch("fetchAlertData", { ...formatQueryParams(state), filter });
};

//
// Fetch Alerts
//
export const fetchServiceAlerts = ({ commit }, params) => {
  commit(types.REQUEST_SERVICE_ALERTS);

  gqlClient
    .query({
      query: SERVICE_ALERTS_QUERY,
      variables: params,
    })
    .then((response) => {
      const account = get(response, "data.account");

      commit(types.RECEIVE_SERVICE_ALERTS_SUCCESS, { account });
    })
    .catch((error) => {
      commit(types.RECEIVE_SERVICE_ALERTS_ERROR, extractApolloErrors(error));
    });
};

//
// Fetch All Alert Sources
//
export const fetchAlertData = ({ commit }, params) => {
  commit(types.REQUEST_ALERT_DATA);

  // The backend deprecated search, which is what the table helpers use. It now
  // uses searchTerm, so this is a hack to make that work.
  if (params.search) {
    params.searchTerm = params.search;
    delete params.search;
  }

  const namespace = "fetchAlertData";
  const lock = acquireLock(namespace);

  gqlClient
    .query({
      query: ALERT_SOURCES_QUERY,
      variables: params,
    })
    .then((response) => {
      if (validLock(namespace, lock)) {
        const alertSources = get(response, "data.account.alertSources.nodes");
        const filteredCount = get(
          response,
          "data.account.alertSources.filteredCount",
        );
        const totalCount = get(
          response,
          "data.account.alertSources.totalCount",
        );

        commit(types.RECEIVE_ALERT_DATA_SUCCESS, {
          alertSources,
          totalCount,
          filteredCount,
        });
      }
    })
    .catch((errors) => {
      if (validLock(namespace, lock)) {
        commit(types.RECEIVE_ALERT_DATA_ERROR, extractApolloErrors(errors));
      }
    });
};

//
//Fetch Account Alert Sources
//
export const fetchHasMonitoringIntegration = ({ commit }) => {
  commit(types.REQUEST_ACCOUNT_ALERTS);

  gqlClient
    .query({
      query: ACCOUNT_HAS_MONITORING_INTEGRATIONS,
    })
    .then((response) => {
      const account = get(response, "data.account");

      commit(types.RECEIVE_ACCOUNT_ALERTS_SUCCESS, account);
    })
    .catch((error) => {
      commit(types.RECEIVE_ACCOUNT_ALERTS_ERROR, extractApolloErrors(error));
    });
};

//
// Create Alerts
//
export const createAlert = ({ commit }, { service, alertSourceIds }) => {
  commit(types.REQUEST_CREATE_ALERT);

  const mutation = createMultipleAlertSourceServicesMutation(alertSourceIds);

  gqlClient
    .mutate({
      variables: { service: service },
      mutation,
    })
    .then((response) => {
      const mutationResults = Object.values(get(response, "data", {}));

      commit(types.RECEIVE_CREATE_ALERT_RESPONSE, mutationResults);
    })
    .catch((error) => {
      commit(types.RECEIVE_CREATE_ALERT_ERROR, extractApolloErrors(error));
    });
};

//
// Delete Alerts
//
export const deleteAlert = ({ commit }, id) => {
  commit(types.REQUEST_DELETE_ALERT);

  gqlClient
    .mutate({
      mutation: DELETE_ALERT_MUTATION,
      variables: { id: id },
    })
    .then((response) => {
      const errors =
        get(response, "data.alertSourceServiceDelete.errors") || [];

      if (errors.length) {
        commit(types.RECEIVE_DELETE_ALERT_ERROR, errors);
      } else {
        const deletedAlertSourceId = get(
          response,
          "data.alertSourceServiceDelete.deletedAlertSourceServiceId",
        );

        commit(types.RECEIVE_DELETE_ALERT_SUCCESS, deletedAlertSourceId);
      }
    })
    .catch((error) => {
      commit(types.RECEIVE_DELETE_ALERT_ERROR, extractApolloErrors(error));
    });
};
