import * as actions from "./actions.js";
import mutations from "./mutations.js";
import state from "./state.js";

export default ({ itemsPerPage = 20 } = {}) => ({
  namespaced: true,
  state: state(itemsPerPage),
  actions,
  mutations,
});
