export const REQUEST_SEARCH_RESULTS = "REQUEST_SEARCH_RESULTS";
export const RECEIVE_SEARCH_RESULTS_SUCCESS = "RECEIVE_SEARCH_RESULTS_SUCCESS";
export const RECEIVE_SEARCH_RESULTS_ERROR = "RECEIVE_SEARCH_RESULTS_ERROR";
export const RESET_SEARCH_RESULTS = "RESET_SEARCH_RESULTS";

export const REQUEST_TECH_DOC_ATTACHED_RESOURCES =
  "REQUEST_TECH_DOC_ATTACHED_RESOURCES";
export const RECEIVE_TECH_DOC_ATTACHED_RESOURCES_SUCCESS =
  "RECEIVE_TECH_DOC_ATTACHED_RESOURCES_SUCCESS";
export const RECEIVE_TECH_DOC_ATTACHED_RESOURCES_ERROR =
  "RECEIVE_TECH_DOC_ATTACHED_RESOURCES_ERROR";

export const UPDATE_TABLE_DATA = "UPDATE_TABLE_DATA";

export const SET_CURRENT_SEARCH_TAB = "SET_CURRENT_SEARCH_TAB";
