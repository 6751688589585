<template>
  <a-card size="small" :bordered="false">
    <a-card-meta>
      <a-icon
        v-if="isLoading"
        slot="avatar"
        type="loading"
        :style="{ paddingTop: '5px', fontSize: '18px' }"
      />
      <a-icon
        v-else-if="isFailure"
        slot="avatar"
        type="exclamation-circle"
        :style="{
          paddingTop: '5px',
          color: 'red',
          fontSize: '18px',
        }"
      />
      <a-icon
        v-else
        slot="avatar"
        type="check-circle"
        :style="{
          paddingTop: '5px',
          color: '#52C41A',
          fontSize: '18px',
        }"
      />

      <span v-if="shouldLinkToResource" slot="title" class="task-title">
        <a target="_blank" :href="link">{{ taskTitle }}</a>
      </span>
      <span v-else slot="title" class="task-title">{{ taskTitle }}</span>

      <span v-if="isLoading" slot="description">
        {{ humanizeString(task.status) }}
      </span>
      <span v-else slot="description">
        {{ humanizeString(task.outcome) }}:
        {{ humanizedDate(task.completedAt) }}
      </span>
    </a-card-meta>
  </a-card>
</template>

<script>
import { humanizeString, humanizedDate } from "@/shared/helpers.js";
export default {
  name: "LongRunningTask",

  props: {
    task: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isLoading() {
      return this.task?.status === "complete" ? false : true;
    },
    isFailure() {
      if (
        [
          "canceled",
          "failed",
          "blackholed",
          "queue_timeout",
          "execution_timeout",
          "pod_timeout",
        ].includes(this.task?.outcome)
      ) {
        return true;
      }

      return false;
    },
    shouldLinkToResource() {
      return !this.isLoading && !this.isFailure && this.link;
    },
    repoId() {
      const repoIdVariable = (this.task?.variables ?? []).find(
        (v) => v.key === "repository_id",
      );

      return repoIdVariable?.value;
    },
    link() {
      if (this.repoId) {
        return `/repositories/${this.repoId}`;
      }

      return undefined;
    },
    taskTitle() {
      return "Run Repository Analysis";
    },
  },

  methods: {
    humanizeString,
    humanizedDate,
  },
};
</script>

<style scoped>
.task-title {
  font-size: 15px;
}
</style>
