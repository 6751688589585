export const REQUEST_CREATE_RESOURCE = "REQUEST_CREATE_RESOURCE";
export const RECEIVE_CREATE_RESOURCE_SUCCESS =
  "RECEIVE_CREATE_RESOURCE_SUCCESS";
export const RECEIVE_CREATE_RESOURCE_ERROR = "RECEIVE_CREATE_RESOURCE_ERROR";
export const CLEAR_CREATE_ERRORS = "CLEAR_CREATE_ERRORS";

export const REQUEST_UPDATE_RESOURCE = "REQUEST_UPDATE_RESOURCE";
export const RECEIVE_UPDATE_RESOURCE_SUCCESS =
  "RECEIVE_UPDATE_RESOURCE_SUCCESS";
export const RECEIVE_UPDATE_RESOURCE_ERROR = "RECEIVE_UPDATE_RESOURCE_ERROR";
export const CLEAR_UPDATE_ERRORS = "CLEAR_UPDATE_ERRORS";

export const REQUEST_DELETE_RESOURCE = "REQUEST_DELETE_RESOURCE";
export const RECEIVE_DELETE_RESOURCE_SUCCESS =
  "RECEIVE_DELETE_RESOURCE_SUCCESS";
export const RECEIVE_DELETE_RESOURCE_ERROR = "RECEIVE_DELETE_RESOURCE_ERROR";
export const CLEAR_DELETE_ERRORS = "CLEAR_DELETE_ERRORS";

export const REQUEST_SHOW_RESOURCE = "REQUEST_SHOW_RESOURCE";
export const RECEIVE_SHOW_RESOURCE_SUCCESS = "RECEIVE_SHOW_RESOURCE_SUCCESS";
export const RECEIVE_SHOW_RESOURCE_ERROR = "RECEIVE_SHOW_RESOURCE_ERROR";
export const CLEAR_SHOW_ERRORS = "CLEAR_SHOW_ERRORS";

export const REQUEST_FETCH_RESOURCES = "REQUEST_FETCH_RESOURCES";
export const RECEIVE_FETCH_RESOURCES_SUCCESS =
  "RECEIVE_FETCH_RESOURCES_SUCCESS";
export const RECEIVE_FETCH_RESOURCES_ERROR = "RECEIVE_FETCH_RESOURCES_ERROR";
export const CLEAR_FETCH_ERRORS = "CLEAR_FETCH_ERRORS";

export const UPDATE_RESOURCE_LIST = "UPDATE_RESOURCE_LIST";
