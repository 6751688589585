import { captureMessage } from "@sentry/vue";

export const permissions = {
  inject: ["currentUser"],
  methods: {
    hasPermission(permission) {
      const userIsAdmin = this.currentUser.user.role === "admin";

      switch (permission) {
        case "service_templates.any_change":
        case "git_integrations.delete":
        case "service_discovery.edit":
        case "actions.manage":
          return userIsAdmin;
        default:
          captureMessage(`Unrecognized permission: '${permission}'`, "error");

          return false;
      }
    },
  },
};
