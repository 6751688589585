import { gql } from "@apollo/client";
import { SERVICE_LEVEL_FRAGMENT } from "@/modules/rubricReports/queries.js";

export const SEARCH_SERVICES_QUERY = gql`
  query search(
    $after: String
    $first: Int
    $sortBy: ServiceSortEnum
    $search: String
  ) {
    account {
      servicesV2(
        after: $after
        first: $first
        sortBy: $sortBy
        searchTerm: $search
      ) {
        filteredCount
        edges {
          highlights
          node {
            id
            alias
            name
            linkable
            href
            locked
            description
            owner {
              name
              href
            }
            type {
              name
            }
            tier {
              name
              index
            }
            product
            language
            framework
            aliases
            note
            tags {
              nodes {
                plainId
                id
                key
                value
              }
            }
            service_stat: checkStats {
              num_checks: totalChecks
              num_passing_checks: totalPassingChecks
            }
            lastDeploy {
              deployedAt
              commitSha
              author
            }
            hasServiceConfigError
            service_level: serviceStats {
              ...ServiceLevel
            }
            level_index: levelIndex
            tools(bestProdEnv: true) {
              nodes {
                displayCategory
                displayName
                environment
                url
              }
            }
            alertStatus {
              index
              type
            }
            creationSource
          }
        }
      }
      rubric {
        levels {
          totalCount
        }
      }
    }
  }
  ${SERVICE_LEVEL_FRAGMENT}
`;

export const SEARCH_DOCUMENTS_QUERY = gql`
  query searchDocuments($after: String, $first: Int, $search: String!) {
    account {
      documents(after: $after, first: $first, searchTerm: $search) {
        filteredCount
        edges {
          highlights
          node {
            id
            title
            metadata
            pathInRepository
            htmlUrl
            content
            globallyPinned
            source {
              ... on Repository {
                displayName
                type
              }
            }
            attachedResources(first: 10, hidden: false) {
              totalCount
              nodes {
                ... on Service {
                  name
                  htmlUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_TECH_DOC_ATTACHED_RESOURCES_QUERY = gql`
  query documentWithResources($documentId: ID!, $first: Int, $after: String) {
    account {
      document(id: $documentId) {
        id
        attachedResources(first: $first, after: $after, hidden: false) {
          edges {
            cursor
            node {
              ... on Service {
                name
                htmlUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_SYSTEMS_QUERY = gql`
  query searchSystems(
    $after: String
    $first: Int
    $filter: [SystemFilterInput!]
    $sortBy: SystemSortEnum
    $search: String
  ) {
    account {
      systems(
        after: $after
        first: $first
        filter: $filter
        sortBy: $sortBy
        searchTerm: $search
      ) {
        filteredCount
        edges {
          node {
            id
            name
            description
            note
            href
            aliases
            owner {
              ... on Team {
                name
                href
              }
              ... on Group {
                name
                href
              }
            }
            parent {
              name
              href
            }
            tags {
              nodes {
                plainId
                id
                key
                value
              }
            }
            childServices {
              filteredCount
            }
          }
          highlights
        }
      }
    }
  }
`;
export const SEARCH_DOMAINS_QUERY = gql`
  query searchDomains(
    $after: String
    $first: Int
    $filter: [DomainFilterInput!]
    $sortBy: DomainSortEnum
    $search: String
  ) {
    account {
      domains(
        after: $after
        first: $first
        filter: $filter
        sortBy: $sortBy
        searchTerm: $search
      ) {
        filteredCount
        edges {
          node {
            id
            name
            description
            note
            href
            aliases
            owner {
              ... on Team {
                name
                href
              }
              ... on Group {
                name
                href
              }
            }
            tags {
              nodes {
                plainId
                id
                key
                value
              }
            }
            childSystems {
              totalCount
              filteredCount
            }
          }
          highlights
        }
      }
    }
  }
`;

export const SEARCH_APIS_QUERY = gql`
  query searchApis($after: String, $first: Int, $search: String!) {
    account {
      apiEndpoints(searchTerm: $search, after: $after, first: $first) {
        filteredCount: totalCount

        edges {
          node {
            description
            title
            path
            operationId
            title
            labels
            type
            method
            document {
              title
              id
              attachedResources(first: 1) {
                nodes {
                  ... on Service {
                    name
                    href
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
