<template>
  <span v-if="resourceName == null" />
  <a v-else-if="resourceHref" :href="resourceHref">
    {{ resourceName }}
  </a>
  <span v-else>{{ resourceName }}</span>
</template>

<script>
export default {
  props: {
    resourceName: {
      type: String,
      required: false,
      default: null,
    },
    resourceHref: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
