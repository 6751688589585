<template>
  <div>
    <a-row :gutter="12">
      <a-col
        v-for="(levelCategory, colIndex) in allLevelsByCategory"
        :key="colIndex"
        :span="colSpan"
      >
        <a-tooltip>
          <template slot="title">
            <span>
              {{ levelCategory.node.name }}:
              <b>{{ levelTooltip(levelCategory) }}</b>
            </span>
          </template>
          <a-row
            v-for="(levelColor, rowIndex) in levelColors()"
            :key="rowIndex"
            :gutter="8"
          >
            <div
              v-if="showColorfulBox(levelCategory, rowIndex)"
              class="box-level"
              :style="boxLevelStyle(levelColor)"
            />
            <div
              v-else-if="!levelCategory.level"
              class="default-box-level disabled-box"
            />
            <div v-else class="default-box-level" />
          </a-row>
        </a-tooltip>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { levelColorPalette } from "@/shared/level_color_helper.js";

export default {
  props: {
    levelsByCategory: {
      type: Array,
      required: true,
    },
    totalLevelCount: {
      required: false,
      type: Number,
      default: 1,
    },
    uncategorizedLevel: {
      required: false,
      type: Object,
      default: null,
    },
    levels: {
      required: true,
      type: Array,
    },
    // in a table (even if some of them don't have an Uncategorized level).
    showUncategorized: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    allLevelsByCategory() {
      if (this.showUncategorized) {
        return this.levelsByCategory.concat({
          node: { name: "Uncategorized" },
          level: this.uncategorizedLevel,
        });
      } else {
        return this.levelsByCategory;
      }
    },
    colSpan() {
      return 2;
    },
  },

  methods: {
    showColorfulBox(levelCategory, rowIndex) {
      // The rows show levels in decreasing order, ex. the first row is for the highest level
      if (levelCategory.level) {
        const indexToUse = this.levels.findIndex(
          (level) => level.index === levelCategory.level.index,
        );
        const levelIndex = this.totalLevelCount - rowIndex - 1;

        return indexToUse == levelIndex;
      } else {
        return false;
      }
    },
    levelColors() {
      // Reverse the list of colors since we show levels in decreasing order.
      return levelColorPalette(this.totalLevelCount).reverse();
    },
    boxLevelStyle(levelColor) {
      return {
        backgroundColor: levelColor.secondary,
        border: `solid 1px ${levelColor.secondary}`,
      };
    },
    levelTooltip(levelCategory) {
      return levelCategory.level ? levelCategory.level.name : "N/A";
    },
  },
};
</script>

<style scoped>
.box-level,
.default-box-level {
  border-radius: 2px;
  height: 9px;
}
.default-box-level {
  border: solid 1px #e9e9e9;
  background-color: #e5e5e5;
}
.disabled-box {
  border: solid 1px #f5f5f5;
  background-color: #ffffff;
}
:deep(.ant-row:not(:last-child)) {
  padding-bottom: 2px;
}
</style>
