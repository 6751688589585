<template>
  <div
    v-if="userName"
    style="display: flex; align-items: center; justify-content: center"
  >
    <a-avatar
      :size="size"
      :shape="shape"
      :style="`${initialsStyles}; ${styleProps}`"
    >
      {{ avatarInitials }}
    </a-avatar>
    <a-avatar
      :src="gravatarHref"
      :size="size"
      :shape="shape"
      :style="`background-color: transparent; ${styleProps}`"
    />
  </div>
  <a-icon v-else class="no-user" type="user" />
</template>

<script>
export default {
  name: "Avatar",

  props: {
    userName: {
      type: String,
      required: false,
      default: null,
    },
    gravatarHref: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      required: false,
      default: "small",
    },
    shape: {
      type: String,
      required: false,
      default: "circle",
    },
    styleProps: {
      required: false,
      default: "",
    },
  },

  computed: {
    avatarInitials() {
      return this.userName
        .split(" ")
        .map((str) => str.charAt(0).toUpperCase())
        .slice(0, 3)
        .join("");
    },
    backgroundColor() {
      if (!this.gravatarHref) {
        return "#1890ff";
      }

      const colorHex = this.gravatarHref.match(/avatar\/(\w{7})/)[1];
      const hue = (parseInt(colorHex.substring(0, 3), 16) % 210) + 150; // Min:150 Max:360
      const saturation = 65;
      const lightness = 55;

      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },
    initialsStyles() {
      return `position: absolute; background-color: ${this.backgroundColor}`;
    },
  },
};
</script>

<style scoped>
.no-user {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  background: #bfbfbf;
  font-size: 16px;
  line-height: 32px;
  color: white;
}
</style>
