const DURATION = 10;

export const errors = {
  data: function () {
    return {};
  },
  methods: {
    showErrorMessages(prefix, errorMessages) {
      errorMessages.forEach((error) => {
        this.$message.error(`${prefix} ${error.message}`, DURATION);
      });
    },
  },
};
