export const SET_INITIAL_DATA = "SET_INITIAL_DATA";
export const RESET_CREDENTIAL_STATE = "RESET_CREDENTIAL_STATE";

export const REQUEST_CREATE_DEPLOY = "REQUEST_CREATE_DEPLOY";
export const RECEIVE_CREATE_DEPLOY_SUCCESS = "RECEIVE_CREATE_DEPLOY_SUCCESS";
export const RECEIVE_CREATE_DEPLOY_ERROR = "RECEIVE_CREATE_DEPLOY_ERROR";

export const REQUEST_CREATE_CHECK = "REQUEST_CREATE_CHECK";
export const RECEIVE_CREATE_CHECK_SUCCESS = "RECEIVE_CREATE_CHECK_SUCCESS";
export const RECEIVE_CREATE_CHECK_ERROR = "RECEIVE_CREATE_CHECK_ERROR";

export const REQUEST_RENAME_INTEGRATION = "REQUEST_RENAME_INTEGRATION";
export const RECEIVE_RENAME_INTEGRATION_SUCCESS =
  "RECEIVE_RENAME_INTEGRATION_SUCCESS";
export const RECEIVE_RENAME_INTEGRATION_ERROR =
  "RECEIVE_RENAME_INTEGRATION_ERROR";

export const REQUEST_SYNC_REPOS_INTEGRATION = "REQUEST_SYNC_REPOS_INTEGRATION";
export const RECEIVE_SYNC_REPOS_INTEGRATION_SUCCESS =
  "RECEIVE_SYNC_REPOS_INTEGRATION_SUCCESS";
export const RECEIVE_SYNC_REPOS_INTEGRATION_ERROR =
  "RECEIVE_SYNC_REPOS_INTEGRATION_ERROR";

export const REQUEST_SYNC_TEAMS_INTEGRATION = "REQUEST_SYNC_TEAMS_INTEGRATION";
export const RECEIVE_SYNC_TEAMS_INTEGRATION_SUCCESS =
  "RECEIVE_SYNC_TEAMS_INTEGRATION_SUCCESS";
export const RECEIVE_SYNC_TEAMS_INTEGRATION_ERROR =
  "RECEIVE_SYNC_TEAMS_INTEGRATION_ERROR";

export const REQUEST_DEAUTH_INTEGRATION = "REQUEST_DEAUTH_INTEGRATION";
export const RECEIVE_DEAUTH_INTEGRATION_SUCCESS =
  "RECEIVE_DEAUTH_INTEGRATION_SUCCESS";
export const RECEIVE_DEAUTH_INTEGRATION_ERROR =
  "RECEIVE_DEAUTH_INTEGRATION_ERROR";

export const REQUEST_SYNC_ALERT_SOURCES_INTEGRATION =
  "REQUEST_SYNC_ALERT_SOURCES_INTEGRATION";
export const RECEIVE_SYNC_ALERT_SOURCES_INTEGRATION_SUCCESS =
  "RECEIVE_SYNC_ALERT_SOURCES_INTEGRATION_SUCCESS";
export const RECEIVE_SYNC_ALERT_SOURCES_INTEGRATION_ERROR =
  "RECEIVE_SYNC_ALERT_SOURCES_INTEGRATION_ERROR";

export const REQUEST_SET_GROUP = "REQUEST_SET_GROUP";
export const RECEIVE_SET_GROUP_SUCCESS = "RECEIVE_SET_GROUP_SUCCESS";
export const RECEIVE_SET_GROUP_ERROR = "RECEIVE_SET_GROUP_ERROR";

export const REQUEST_QUERY_INTEGRATIONS = "REQUEST_QUERY_INTEGRATIONS";
export const RECEIVE_QUERY_INTEGRATIONS_RESPONSE =
  "RECEIVE_QUERY_INTEGRATIONS_RESPONSE";
export const RECEIVE_QUERY_INTEGRATIONS_ERROR =
  "RECEIVE_QUERY_INTEGRATIONS_ERROR";

export const REQUEST_CREATE_INTEGRATION = "REQUEST_CREATE_INTEGRATION";
export const RECEIVE_CREATE_INTEGRATION_SUCCESS =
  "RECEIVE_CREATE_INTEGRATION_SUCCESS";
export const RECEIVE_CREATE_INTEGRATION_ERROR =
  "RECEIVE_CREATE_INTEGRATION_ERROR";

export const REQUEST_UPDATE_INTEGRATION_CREDENTIALS =
  "REQUEST_UPDATE_INTEGRATION_CREDENTIALS";
export const RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_SUCCESS =
  "RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_SUCCESS";
export const RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_ERROR =
  "RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_ERROR";

export const REQUEST_UPDATE_DD_NOTIFICATION_CHANNEL =
  "REQUEST_UPDATE_DD_NOTIFICATION_CHANNEL";
export const RECEIVE_UPDATE_DD_NOTIFICATION_CHANNEL_SUCCESS =
  "RECEIVE_UPDATE_DD_NOTIFICATION_CHANNEL_SUCCESS";
export const RECEIVE_UPDATE_DD_NOTIFICATION_CHANNEL_ERROR =
  "RECEIVE_UPDATE_DD_NOTIFICATION_CHANNEL_ERROR";

export const REQUEST_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS =
  "REQUEST_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS";
export const RECEIVE_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS_SUCCESS =
  "RECEIVE_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS_SUCCESS";
export const RECEIVE_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS_ERROR =
  "RECEIVE_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS_ERROR";

export const REQUEST_UPDATE_INTEGRATION_BASE_URL =
  "REQUEST_UPDATE_INTEGRATION_BASE_URL";
export const RECEIVE_UPDATE_INTEGRATION_BASE_URL_SUCCESS =
  "RECEIVE_UPDATE_INTEGRATION_BASE_URL_SUCCESS";
export const RECEIVE_UPDATE_INTEGRATION_BASE_URL_ERROR =
  "RECEIVE_UPDATE_INTEGRATION_BASE_URL_ERROR";

export const REQUEST_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY =
  "REQUEST_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY";
export const RECEIVE_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY_SUCCESS =
  "RECEIVE_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY_SUCCESS";
export const RECEIVE_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY_ERROR =
  "RECEIVE_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY_ERROR";

export const REQUEST_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED =
  "REQUEST_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED";
export const RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED_SUCCESS =
  "RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED_SUCCESS";
export const RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED_ERROR =
  "RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED_ERROR";

export const REQUEST_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED =
  "REQUEST_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED";

export const RECEIVE_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED_SUCCESS =
  "RECEIVE_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED_SUCCESS";

export const RECEIVE_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED_ERROR =
  "RECEIVE_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED_ERROR";

export const REQUEST_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES =
  "REQUEST_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES";
export const RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES_SUCCESS =
  "RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES_SUCCESS";
export const RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES_ERROR =
  "RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES_ERROR";

export const REQUEST_FETCH_GIT_FORGE_INTEGRATIONS =
  "REQUEST_FETCH_GIT_FORGE_INTEGRATIONS";
export const RECEIVE_FETCH_GIT_FORGE_INTEGRATIONS_SUCCESS =
  "RECEIVE_FETCH_GIT_FORGE_INTEGRATIONS_SUCCESS";
export const RECEIVE_FETCH_GIT_FORGE_INTEGRATIONS_ERRORS =
  "RECEIVE_FETCH_GIT_FORGE_INTEGRATIONS_ERRORS";

export const REQUEST_FETCH_AVAILABLE_INTEGRATIONS =
  "REQUEST_FETCH_AVAILABLE_INTEGRATIONS";
export const RECEIVE_FETCH_AVAILABLE_INTEGRATIONS_SUCCESS =
  "RECEIVE_FETCH_AVAILABLE_INTEGRATIONS_SUCCESS";
export const RECEIVE_FETCH_AVAILABLE_INTEGRATIONS_ERRORS =
  "RECEIVE_FETCH_AVAILABLE_INTEGRATIONS_ERRORS";

export const REQUEST_DELETE_INTEGRATION = "REQUEST_DELETE_INTEGRATION";
export const RECEIVE_DELETE_INTEGRATION_SUCCESS =
  "RECEIVE_DELETE_INTEGRATION_SUCCESS";
export const RECEIVE_DELETE_INTEGRATION_ERRORS =
  "RECEIVE_DELETE_INTEGRATION_ERRORS";

export const REQUEST_VALIDATE_INTEGRATION_CREDENTIALS =
  "REQUEST_VALIDATE_INTEGRATION_CREDENTIALS";
export const RECEIVE_VALIDATE_INTEGRATION_CREDENTIALS_SUCCESS =
  "RECEIVE_VALIDATE_INTEGRATION_CREDENTIALS_SUCCESS";
export const RECEIVE_VALIDATE_INTEGRATION_CREDENTIALS_ERRORS =
  "RECEIVE_VALIDATE_INTEGRATION_CREDENTIALS_ERRORS";

export const REQUEST_GENERIC_INTEGRATIONS = "REQUEST_GENERIC_INTEGRATIONS";
export const RECEIVE_GENERIC_INTEGRATIONS_SUCCESS =
  "RECEIVE_GENERIC_INTEGRATIONS_SUCCESS";
export const RECEIVE_GENERIC_INTEGRATIONS_ERRORS =
  "RECEIVE_GENERIC_INTEGRATIONS_ERRORS";

export const REQUEST_INTEGRATION = "REQUEST_INTEGRATION";
export const RECEIVE_INTEGRATION_SUCCESS = "RECEIVE_INTEGRATION_SUCCESS";
export const RECEIVE_INTEGRATION_ERRORS = "RECEIVE_INTEGRATION_ERRORS";

export const REQUEST_NEW_RELIC_ACCOUNTS = "REQUEST_NEW_RELIC_ACCOUNTS";
export const RECEIVE_NEW_RELIC_ACCOUNTS_ERRORS =
  "RECEIVE_NEW_RELIC_ACCOUNTS_ERRORS";
export const RECEIVE_NEW_RELIC_ACCOUNTS_SUCCESS =
  "RECEIVE_NEW_RELIC_ACCOUNTS_SUCCESS";

export const REQUEST_CREATE_SAMPLE_GIT_INTEGRATION =
  "REQUEST_CREATE_SAMPLE_GIT_INTEGRATION";
export const RECEIVE_CREATE_SAMPLE_GIT_INTEGRATION_ERRORS =
  "RECEIVE_CREATE_SAMPLE_GIT_INTEGRATION_ERRORS";
export const RECEIVE_CREATE_SAMPLE_GIT_INTEGRATION_SUCCESS =
  "RECEIVE_CREATE_SAMPLE_GIT_INTEGRATION_SUCCESS";

export const REQUEST_SYNC_INTEGRATION = "REQUEST_SYNC_INTEGRATION";
export const RECEIVE_SYNC_INTEGRATION_SUCCESS =
  "RECEIVE_SYNC_INTEGRATION_SUCCESS";
export const RECEIVE_SYNC_INTEGRATION_ERROR = "RECEIVE_SYNC_INTEGRATION_ERROR";

export const REQUEST_UPDATE_INTEGRATION_TEAM_SYNC =
  "REQUEST_UPDATE_INTEGRATION_TEAM_SYNC";
export const RECEIVE_UPDATE_INTEGRATION_TEAM_SYNC_ERRORS =
  "RECEIVE_UPDATE_INTEGRATION_TEAM_SYNC_ERRORS";
export const RECEIVE_UPDATE_INTEGRATION_TEAM_SYNC_SUCCESS =
  "RECEIVE_UPDATE_INTEGRATION_TEAM_SYNC_SUCCESS";

export const REQUEST_UPDATE_INTEGRATION_WARNING =
  "REQUEST_UPDATE_INTEGRATION_WARNING";
export const RECEIVE_UPDATE_INTEGRATION_WARNING_ERRORS =
  "RECEIVE_UPDATE_INTEGRATION_WARNING_ERRORS";
export const RECEIVE_UPDATE_INTEGRATION_WARNING_SUCCESS =
  "RECEIVE_UPDATE_INTEGRATION_WARNING_SUCCESS";
