export const formatDirectionEnum = (direction) => {
  return direction == "ascend" ? "asc" : "desc";
};

export const unformatDirectionEnum = (direction) => {
  return direction.toLowerCase() == "asc" ? "ascend" : "descend";
};

export const formatSortField = (sortObject) => {
  if (!sortObject || !sortObject.direction) {
    return undefined;
  }

  return `${sortObject.key}_${formatDirectionEnum(
    sortObject.direction,
  ).toUpperCase()}`;
};
