<template>
  <span>
    <span v-for="(item, index) in contentSplitByHighlights" :key="index">
      <span :class="index % 2 === 1 ? 'highlight' : 'none'">{{ item }}</span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    contentHtml: {
      type: String,
      required: true,
    },
  },

  computed: {
    contentSplitByHighlights() {
      return this.contentHtml.split("OPSLEVEL_MARKDOWN_HIGHLIGHT");
    },
  },
};
</script>
