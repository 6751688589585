import * as types from "./mutation_types.js";

export default {
  [types.SET_IS_SIDEBAR_COLLAPSED](state, { collapsed }) {
    state.isSidebarCollapsed = collapsed;
  },
  [types.REQUEST_APPLICATION_CONFIGS](state) {
    state.isFetchingApplicationConfigs = true;
  },
  [types.RECEIVE_APPLICATION_CONFIGS_SUCCESS](
    state,
    { elasticsearchEnabled, environment },
  ) {
    state.isFetchingApplicationConfigs = false;
    state.elasticsearchEnabled = elasticsearchEnabled;
    state.environment = environment;
  },
  [types.RECEIVE_APPLICATION_CONFIGS_ERROR](state, errors) {
    state.isFetchingApplicationConfigs = false;
    state.errorsFetchingApplicationConfigs = errors;
  },
};
