import ResourceName from "@/components/atoms/ResourceName.vue";
import BooleanType from "@/components/atoms/data_types/BooleanType.vue";
import DateType from "@/components/atoms/data_types/DateType.vue";
import CollapsibleTagsList from "@/components/molecules/CollapsibleTagsList.vue";

export const COLUMN_SLOTS = [
  {
    slotName: "date",
    component: DateType,
    props: (data) => {
      return { date: data };
    },
  },
  {
    slotName: "name",
    component: ResourceName,
    props: (data) => {
      return { resourceName: data?.name, resourceHref: data?.href };
    },
  },
  {
    slotName: "boolean",
    component: BooleanType,
    props: (data) => {
      return { value: data };
    },
  },
  {
    slotName: "tags",
    component: CollapsibleTagsList,
    props: (data) => {
      return { tags: data };
    },
  },
];

export const DATA_TYPE_SLOT_MAPPING = {
  Boolean: "boolean",
  "Types::ISO8601DateTime": "date",
  "Types::TagConnectionType": "tags",
};

export const RESOURCE_TYPES = [
  "ServiceType",
  "TeamType",
  "UserType",
  "RepositoryType",
  "GroupType",
  "CampaignType",
  "IntegrationType",
  "EntityOwnerType",
  "InfrastructureResourceType",
  "FilterType",
];
