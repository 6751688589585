import { gql } from "@apollo/client";

const ALERT_SOURCE_FRAGMENT = gql`
  fragment AlertSources on AlertSource {
    id
    name
    type
    integration {
      name
      displayName
      ... on ManualAlertSourceSync {
        lastManualSyncAlertSources
        allowManualSyncAlertSources
      }
    }
    url
    description
    metadata
  }
`;

export const SERVICE_ALERTS_QUERY = gql`
  query serviceAlertSources(
    $service_id: ID!
    $after: String
    $first: Int
    $sortBy: AlertSourceSortEnum
  ) {
    account {
      monitoringIntegrations: integrations(type: "monitoring") {
        totalCount
      }
      incidentNotifyingIntegrations: integrations(type: "incidentNotifying") {
        totalCount
      }
      slackIntegrations: integrations(type: "slack") {
        totalCount
      }
      service(id: $service_id) {
        alertSources(after: $after, first: $first, sortBy: $sortBy) {
          totalCount
          edges {
            id
            status
            locked
            lockerUrl
            node {
              ...AlertSources
            }
          }
        }
      }
    }
  }
  ${ALERT_SOURCE_FRAGMENT}
`;

export const ACCOUNT_HAS_MONITORING_INTEGRATIONS = gql`
  query hasMonitoringIntegrations {
    account {
      hasMonitoringIntegrations
    }
  }
`;

export const ALERT_SOURCES_QUERY = gql`
  query alertSources(
    $service_ids: [ID!]
    $filter: [AlertSourceFilterInput!]
    $sortBy: AlertSourceSortEnum
    $searchTerm: String
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    account {
      alertSources(
        filter: $filter
        sortBy: $sortBy
        searchTerm: $searchTerm
        first: $first
        last: $last
        before: $before
        after: $after
      ) {
        totalCount
        filteredCount
        nodes {
          id
          name
          type
          integration {
            name
            displayName
          }
          url
          description
          metadata
          services(serviceIds: $service_ids) {
            totalCount
          }

          alertSourceServices {
            nodes {
              service {
                name
                htmlUrl
                href
              }
            }
          }
        }
      }
    }
  }
`;

export const DELETE_ALERT_MUTATION = gql`
  mutation alertSourceDelete($id: ID!) {
    alertSourceServiceDelete(input: { id: $id }) {
      deletedAlertSourceServiceId
      errors {
        message
        path
      }
    }
  }
`;

export const createMultipleAlertSourceServicesMutation = (alertSourceIds) => {
  const createMutations = alertSourceIds.map((alertSourceId, index) => {
    const idsArray = JSON.stringify([alertSourceId]);

    return `create${index}: alertSourceServicesCreate(input: {service: $service, alertSourceIds: ${idsArray}}) {
      ...createAlertSourceServicesFields
    }
    `;
  });

  return gql`
  mutation alertSourceCreation(
    $service: IdentifierInput!,
  ) {
    ${createMutations}
  }

  fragment createAlertSourceServicesFields on AlertSourceServicesCreatePayload {
    alertSourceServices {
      id
      status
      alertSource {
        id
        name
        type
        url
        description
        integration {
          displayName
        }
      }
    }
    errors {
      message
    }
  }
`;
};
