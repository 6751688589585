<template>
  <span :class="{ clickable: isStackClickable }">
    <TagWithIcon
      v-for="(stack, index) in filteredStacks"
      :key="index"
      :input="stack.input"
      :iconMap="stack.iconMap"
      :showIconOnly="showIconOnly"
      :highlightedInput="stack.highlightedInput"
      @tagClicked="stackClick(stack.input)"
    />
  </span>
</template>

<script>
import TagWithIcon from "@/components/TagWithIcon.vue";
import { LanguageMap, FrameworkMap } from "@/shared/icons.js";

export default {
  components: { TagWithIcon },

  props: {
    language: {
      type: String,
      required: false,
      default: null,
    },
    framework: {
      type: String,
      required: false,
      default: null,
    },
    showIconOnly: {
      type: Boolean,
      required: false,
    },
    isStackClickable: {
      type: Boolean,
      required: false,
    },
    formattedHighlight: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  emits: ["stackClick"],

  data() {
    return {
      languageMap: LanguageMap,
      frameworkMap: FrameworkMap,
    };
  },

  computed: {
    stacks() {
      return [
        {
          input: this.language,
          iconMap: this.languageMap,
          highlightedInput: this.formattedHighlight["language"],
        },
        {
          input: this.framework,
          iconMap: this.frameworkMap,
          highlightedInput: this.formattedHighlight["framework"],
        },
      ];
    },
    filteredStacks() {
      return this.stacks.filter((stack) => stack.input);
    },
  },

  methods: {
    stackClick(value) {
      if (this.isStackClickable) {
        const key = value == this.language ? "language" : "framework";

        this.$emit("stackClick", { key, value });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.clickable {
  :deep(.ant-tag) {
    cursor: pointer !important;
    margin-bottom: 2px;
  }
}
</style>
