<template>
  <div style="text-align: center; padding: 10px 0px">
    <div style="margin-bottom: 24px">
      <slot name="image" />
    </div>
    <p v-if="title" class="title">
      {{ title }}
    </p>
    <slot name="text" />
  </div>
</template>

<script>
export default {
  name: "EmptyState",

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  color: #262626;
  margin-bottom: 10px;
}
</style>
