const csrf = {
  init() {
    const csrfTag = document.querySelector('meta[name="csrf-token"]');

    if (csrfTag) {
      this.csrfToken = csrfTag.getAttribute("content");
    } else {
      this.csrfToken = null;
    }
  },

  get token() {
    return this.csrfToken;
  },

  get headerKey() {
    return "X-CSRF-Token";
  },
};

csrf.init();

export default csrf;
