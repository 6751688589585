<template>
  <div>
    <a-layout class="top-layout">
      <slot name="flash" />

      <a-layout-content class="global-content">
        <div class="value-blurb">
          <h1 class="text-gradient">
            Build and ship high-quality software, faster
          </h1>
          <div class="value-props">
            <h2>
              <GradientSearchIcon />
              Get visibility into your entire software ecosystem
            </h2>
            <div class="icon-gradient vertical-divider" />
            <h2>
              <GradientCheckIcon />
              Make standards and compliance easy with built-in guardrails
            </h2>
            <div class="icon-gradient vertical-divider" />
            <h2>
              <GradientSettingIcon />
              Unblock bottlenecks with<br />self-service tools
            </h2>
          </div>
        </div>
        <div class="content-wrapper">
          <div class="content-card">
            <img src="/OpsLevelLogo-Primary.svg" class="logo" alt="OpsLevel" />

            <slot name="content">
              <div></div>
            </slot>
          </div>
        </div>
      </a-layout-content>

      <div class="expando" />
      <CopyrightFooter />
    </a-layout>
  </div>
</template>

<script>
import CopyrightFooter from "./CopyrightFooter.vue";
import {
  GradientSearchIcon,
  GradientSettingIcon,
  GradientCheckIcon,
} from "@/shared/icons.js";

export default {
  components: {
    CopyrightFooter,
    GradientSearchIcon,
    GradientSettingIcon,
    GradientCheckIcon,
  },
};
</script>

<style scoped>
.value-blurb {
  font-style: normal;
  max-width: 720px;
}
.value-blurb h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 59px;
  margin-bottom: 48px;
}

.value-blurb h2 {
  color: #595959;
  font-size: 26px;
  line-height: 37px;
  font-weight: 300;
  margin-bottom: 0;
  max-width: 440px;
  display: flex;
  letter-spacing: 0.5px;
}

.value-props :deep(.anticon) {
  font-size: 44px;
}

.value-props {
  margin-left: 32px;
}

.value-blurb i {
  margin-right: 16px;
}

.vertical-divider {
  height: 42px;
  width: 3px;
  margin: -16px 0 16px 20px; /* align with icons, ignoring text wrapping */
}

.global-content {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 72px;
}
.text-gradient {
  filter: linear-gradient(90deg, #347ed5 -5.35%, #05d1c6 104.49%);
  background: linear-gradient(90deg, #347ed5 -5.35%, #05d1c6 104.49%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}
.text-gradient::selection {
  -webkit-text-fill-color: white;
}
.icon-gradient {
  background: linear-gradient(180deg, #347ed5 0%, #05d1c6 100%);
}

.content-card {
  padding: 40px 60px 60px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d9d9d9;
}

.logo {
  margin: auto;
  width: 150px;
  margin-bottom: 36px;
}

@media (max-width: 1020px) {
  .value-blurb {
    display: none;
  }
}
</style>
