export default function extractApolloErrors({ graphQLErrors, networkError }) {
  const errors = [];

  if (networkError) {
    errors.push({
      message:
        "Connection Error: We attempted to make a request and did not receive a response. Please try again.",
    });
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      errors.push({ message: `Server Error: ${message}` });
    });
  }

  return errors;
}
