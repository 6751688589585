<template>
  <span style="overflow-wrap: break-word">
    <span v-for="(fragment, fragmentIndex) in fragments" :key="fragmentIndex">
      <span
        v-for="(item, highlightIndex) in splitFragmentByHighlights(fragment)"
        :key="highlightIndex"
      >
        <span :class="highlightIndex % 2 === 1 ? 'highlight' : 'none'">{{
          item
        }}</span>
      </span>
      <span v-if="fragments.length > 1"> ...<br /> </span>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    fragments: {
      type: Array,
      required: true,
    },
  },

  methods: {
    splitFragmentByHighlights(searchFragment) {
      return searchFragment.split("OPSLEVEL_MARKDOWN_HIGHLIGHT");
    },
  },
};
</script>
