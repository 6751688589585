import { tableState } from "@/shared/table_helper.js";

export default () => ({
  // Fetch Service Alerts
  hasMonitoringIntegrations: null,
  hasIncidentNotifyingIntegrations: null,
  hasSlackIntegration: null,
  alertSourceEdges: [],
  alertSourceTotalCount: 0,
  isFetchingServiceAlerts: false,
  errorsFetchingServiceAlert: [],
  // Fetch All Alerts
  alertData: [],
  isFetchingAlertData: false,
  errorsFetchingAlertData: [],
  totalAlertSourceCount: 0,
  filteredAlertSourceCount: 0,
  // Create
  isCreatingAlert: false,
  allCreatingFailed: false,
  errorsCreatingAlert: [],
  // Delete
  isDeletingAlert: false,
  errorsDeletingAlert: [],
  //Table
  ...tableState({ itemsPerPage: 20 }),
});
