// The goal is to split the url-persistence plugin into its own package
// in the future. Because of this, we duplicated some of the logic that
// exists in the url_helper.js file. This logic differs slightly.
import { merge, isEmpty, pickBy, countBy, set, identity } from "lodash";

export const parseObjectToUrlParams = (obj) => {
  const objectArray = Object.entries(clean(obj));

  const params = objectArray
    .map(([key, value]) => {
      return toUrl(key, value);
    })
    .filter(Boolean);

  return params.length ? `?${params.join("&")}` : "";
};

const clean = (obj) => {
  return pickBy(obj, identity);
};

export const parseUrlParamsToObject = (url) => {
  const urlParams = new URLSearchParams(url);

  const urlParamsKeys = prepareUrlParamKeys([...urlParams.keys()]);

  const urlParamsValues = [...urlParams.values()];

  const result = urlParamsKeys.reduce((acc, key, index) => {
    const value = urlParamsValues[index];
    const aggregatedValue = set({}, key, convertValueFromString(value));

    return merge(acc, aggregatedValue);
  }, {});

  return result;
};

const toUrl = (key, value) => {
  if (value instanceof Array) {
    return Array.from(value, (v, index) => toUrl(`${key}[${index}]`, v)).join(
      "&",
    );
  } else if (value instanceof Object) {
    const withoutEmptyValues = Object.keys(value).filter(
      (valueKey) => !isEmpty(value[valueKey]),
    );

    return Array.from(withoutEmptyValues, (k) =>
      toUrl(`${key}[${k}]`, value[k]),
    ).join("&");
  } else {
    return `${key}=${convertValueToString(value)}`;
  }
};

const convertValueToString = (value) => {
  return value === null ? "is:null" : encodeURIComponent(value);
};

const prepareUrlParamKeys = (urlParamsKeys) => {
  // count repeated keys
  const countByRepeatedKeys = pickBy(countBy(urlParamsKeys), (k) => k > 1);

  // enumerate duplicated keys, e.g. having two keys named "a" it will return "a[0]" and "a[1]"
  return urlParamsKeys.map((key) => {
    const repeatedKeys = Object.keys(countByRepeatedKeys);

    return repeatedKeys.includes(key)
      ? `${key}[${--countByRepeatedKeys[key]}]`
      : key;
  });
};

const convertValueFromString = (value) => {
  switch (value) {
    case "":
      return null;
    case "is:null":
      return null;
    case "true":
      return true;
    case "false":
      return false;
    default:
      return value;
  }
};
