import { gql } from "@apollo/client";

export const BASIC_USER_FRAGMENT = gql`
  fragment BasicUserFragment on User {
    id
    name
    email
    href
    avatar
    pending
    deactivatedAt
    tags {
      nodes {
        id
        key
        value
      }
    }
  }
`;

export const TEAM_MEMBERS_QUERY = gql`
  query teamMembers($id: ID!, $after: String, $first: Int) {
    account {
      team(id: $id) {
        permissions {
          canUpdate
          permittedAttributesForUpdate
        }
        members: memberships(after: $after, first: $first) {
          totalCount
          nodes {
            role
            user {
              ...BasicUserFragment
            }
          }
        }
      }
    }
  }
  ${BASIC_USER_FRAGMENT}
`;

export const CREATE_MEMBERS_MUTATION = gql`
  mutation createTeamMemberships(
    $teamId: ID!
    $members: [TeamMembershipUserInput!]!
  ) {
    teamMembershipCreate(input: { teamId: $teamId, members: $members }) {
      members {
        id
        email
      }
      errors {
        message
        path
      }
    }
  }
`;

export const DELETE_MEMBERS_MUTATION = gql`
  mutation deleteTeamMemberships(
    $teamId: ID!
    $members: [TeamMembershipUserInput!]!
  ) {
    teamMembershipDelete(input: { teamId: $teamId, members: $members }) {
      deletedMembers {
        id
        name
      }
      errors {
        message
      }
    }
  }
`;
