<template>
  <a-button class="expandAllButton" @click="handleClick">
    <a-icon v-if="expandedRows" type="minus" style="font-size: 13px" />
    <a-icon v-else type="plus" style="font-size: 13px" />
  </a-button>
</template>

<script>
export default {
  props: {
    onClick: {
      type: Function,
      required: false,
      default: () => {},
    },
    expandedRows: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    handleClick() {
      this.onClick();
    },
  },
};
</script>

<style scoped>
/* this is necessary to make the button be inside the table */
.expandAllButton {
  position: absolute;
  z-index: 1;
  height: 23px;
  margin-inline: 16px;
  margin-block: 16px;
  padding-inline: 3px;
}
</style>
