<template>
  <a-modal
    v-model="visible"
    okText="All done"
    width="800px"
    centered
    @ok="() => (visible = false)"
  >
    <JoinTeams :currentUser="currentUser" height="700px" />
    <template slot="footer">
      <div>
        <a-button @click="createTeam"> Create a New Team </a-button>
        <a-button key="submit" type="primary" @click="visible = false">
          Done
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import JoinTeams from "@/components/organisms/JoinTeams.vue";

export default {
  components: { JoinTeams },

  props: {
    currentUser: {
      type: Object,
      required: true,
    },
    newTeamsPath: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      visible: true,
    };
  },

  methods: {
    createTeam() {
      window.location = this.newTeamsPath;
    },
  },
};
</script>
