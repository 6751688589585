import Vue from "vue";
import Vuex from "vuex";
import urlPersistence from "@/store/urlPersistence.js";
import internalNotifications from "@/store/internalNotifications.js";

Vue.use(Vuex);

Vuex.Store.prototype.registerModuleOnce = function (
  path,
  module,
  options = {},
) {
  if (!this.hasModule(path)) {
    this.registerModule(path, module, options);
  }
};

// Note: If making changes, also update app/javascript/src/services/store/index.js
export const createStore = () =>
  new Vuex.Store({
    plugins: [urlPersistence, internalNotifications],
  });

export default createStore();
