<template>
  <a-card class="swagger-ui">
    <div v-if="isFetchingApiSearchResults" class="loading">
      <SpinningLogo />
    </div>
    <h3 v-else-if="isEmptyApiSearchResult" class="hint">
      <p>
        We couldn't find any results matching "<b>{{ searchTerm }}</b
        >".<br />
        To improve your search results, try searching for a specific API path.
      </p>
    </h3>
    <template v-else>
      <h3>
        {{ headerText }}
      </h3>
      <div
        v-for="(apiSearchResult, index) in apiSearchResults"
        :key="index"
        class="opblock"
      >
        <span :class="getOpBlockSummaryClass(apiSearchResult)">
          <a class="endpoint" :href="getLinkPath(apiSearchResult)">
            <span class="opblock-summary-method">{{
              apiSearchResult.method.toUpperCase()
            }}</span>
            <div class="description-wrapper">
              <span class="path">
                <span>{{ apiSearchResult.path }}</span>
              </span>
            </div>
          </a>
          <span class="service-link">
            Appears in
            <a
              :href="apiSearchResult.document.attachedResources.nodes[0].href"
              >{{ apiSearchResult.document.attachedResources.nodes[0].name }}</a
            >
          </span>
        </span>
      </div>
    </template>
  </a-card>
</template>

<script>
import pluralize from "pluralize";
import { mapState } from "vuex";
import SpinningLogo from "@/components/SpinningLogo.vue";
import "@/shared/swagger-overrides.css";
import { replaceNonAlphanumericWithUnderscores } from "@/shared/helpers.js";

export default {
  components: {
    SpinningLogo,
  },

  props: {
    searchTerm: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      apiSearchResults: (state) => state.apisSearch.searchResults,
      isFetchingApiSearchResults: (state) =>
        state.apisSearch.isFetchingSearchResults,
      filteredApisSearchCount: (state) => state.apisSearch.filteredCount,
      errorsFetchingApiSearchResults: (state) =>
        state.apisSearch.errorsFetchingSearchResults,
      apiPaginationState: (state) => state.apisSearch.pagination,
    }),
    isEmptyApiSearchResult() {
      return !this.filteredApisSearchCount && !this.isFetchingApiSearchResults;
    },
    headerText() {
      const count = this.filteredApisSearchCount;

      return `${count} ${pluralize("Result", count)}`;
    },
  },

  methods: {
    getLinkPath(apiSearchResult) {
      if (!apiSearchResult.document?.attachedResources?.nodes?.length) {
        return "#";
      }

      const baseUrl = `${apiSearchResult.document.attachedResources.nodes[0].href}/api-docs`;
      let label = "default";

      if (apiSearchResult.type === "webhooks") {
        label = "webhooks";
      } else if (apiSearchResult.labels?.[0]) {
        label = `${apiSearchResult.labels[0]}`;
      }

      const suffix = apiSearchResult.operationId
        ? apiSearchResult.operationId
        : apiSearchResult.method +
          replaceNonAlphanumericWithUnderscores(apiSearchResult.path);

      return `${baseUrl}#/${label}/${suffix}`;
    },
    getOpBlockSummaryClass(apiSearchResult) {
      return {
        "opblock-summary": true,
        "opblock-summary-get": apiSearchResult.method === "get",
        "opblock-summary-post": apiSearchResult.method === "post",
        "opblock-summary-put": apiSearchResult.method === "put",
        "opblock-summary-patch": apiSearchResult.method === "patch",
        "opblock-summary-delete": apiSearchResult.method === "delete",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.loading {
  display: flex;
  justify-content: center;
}

.opblock-summary {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 4px;
  display: flex;
  font-size: 14px;
  background: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .opblock-summary-method {
    padding: 8px 0;
    font-weight: 700;
    min-width: 80px;
    text-align: center;
  }
  .description-wrapper {
    padding: 0 8px;
    display: flex;
    align-items: center;
    color: #3b4151;
    .path {
      font-size: 16px;
      font-weight: 600;
      font-family: monospace;
    }
  }

  .endpoint {
    display: flex;
    align-items: center;
    width: 50%;
  }
  .service-link {
    color: rgba(0, 0, 0, 0.65);
    padding-right: 24px;
  }
}
</style>
