import { gql } from "@apollo/client";

export const JOBS_QUERY = gql`
  query jobRuns(
    $templateName: JobTemplateEnum!
    $completedAfter: ISO8601DateTime
    $status: RunnerJobStatusEnum
    $repositoryIds: [ID!]
  ) {
    account {
      jobRuns(
        templateName: $templateName
        completedAfter: $completedAfter
        status: $status
        repositoryIds: $repositoryIds
      ) {
        nodes {
          id
          status
          startedAt
          completedAt
          outcome
          variables
        }
      }
    }
  }
`;

export const JOBS_SUBSCRIPTION = gql`
  subscription subscribeToJobRuns($templateName: JobTemplateEnum!) {
    runnerJobStatusUpdated(templateName: $templateName) {
      nodes {
        id
        status
        startedAt
        completedAt
        outcome
        variables
        templateName
      }
    }
  }
`;
