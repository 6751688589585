import { isArray } from "lodash";

export const systemToggles = {
  inject: ["systemToggles"],
  methods: {
    hasSystemToggle(toggle) {
      if (!isArray(this.systemToggles)) {
        return false;
      } else {
        return this.systemToggles.includes(toggle);
      }
    },
  },
};
