import { get } from "lodash";
import { AUTH_TYPES } from "@/shared/authTypes.js";

export const BENTO_SIDEBAR_HAS_BEEN_OPENED_KEY =
  "bento-sidebar-has-been-opened ";

let bentoSettings;

// Auto-complete any step that was completed after a service was updated.
// We do this by updating the corresponding bento attribute and re-initializing
// the bento settings.
export const autocompleteServiceStep = (service, oldService) => {
  if (!window.Bento) {
    return;
  }

  bentoSettings = get(window, "bentoSettings.account", {});

  if (
    ownerAdded(service, oldService) ||
    propertyAdded(service) ||
    serviceRepoAdded(service) ||
    toolAdded(service) ||
    ssoAdded(service)
  ) {
    // Send updated attributes to Bento, see https://www.notion.so/Bento-installation-d860652453b34de89420a475df379a8e.
    window.Bento.initialize();
  }
};

export const resourceAdded = (resourceKey, numAdded = 1) => {
  if (!window.Bento) {
    return;
  }

  bentoSettings = get(window, "bentoSettings.account", {});

  if (bentoSettings[resourceKey] !== undefined) {
    bentoSettings[resourceKey] += numAdded;
    window.Bento.initialize();
  }
};

const ownerAdded = (service, oldService) => {
  if (oldService && !oldService.owner && service.owner) {
    bentoSettings.servicesOwner += 1;

    return true;
  } else {
    return false;
  }
};

const propertyAdded = (service) => {
  let propAdded = !!(
    service.product ||
    service.language ||
    service.framework ||
    service.tier ||
    service.lifecycle
  );

  propAdded = propAdded || !!(service.tags?.length > 0);
  propAdded = propAdded || !!(service.aliases?.length > 0);

  if (bentoSettings.servicesProperties === 0 && propAdded) {
    bentoSettings.servicesProperties = 1;

    return true;
  } else {
    return false;
  }
};

const serviceRepoAdded = (service) => {
  if (bentoSettings.repositoriesCount === 0 && service.serviceRepo) {
    bentoSettings.repositoriesCount = 1;

    return true;
  } else {
    return false;
  }
};

const toolAdded = (service) => {
  if (bentoSettings.tools === 0 && service.tool) {
    bentoSettings.tools = 1;

    return true;
  } else {
    return false;
  }
};

const ssoAdded = (service) => {
  if (service.authType === AUTH_TYPES.SAML && !bentoSettings.hasSSO) {
    bentoSettings.hasSSO = true;

    return true;
  } else {
    return false;
  }
};

export const afterBentoLoad = (callback) => {
  if (window.Bento?.initialized) {
    callback();
  } else {
    document.addEventListener("bento-onGuideLoad", () => {
      callback();
    });
  }
};

export const drawAttentionToBentoSidebar = () => {
  // Many users never realize that the Onboarding guide is available
  // in the sidebar. To help them overcome that hurdle we assign a small
  // animation to the sidebar toggle to help draw their focus. This animation
  // will play until they've opened the sidebar at least once. And we store
  // the users progress in localStorage.
  const sidebarHasBeenOpened = localStorage.getItem(
    BENTO_SIDEBAR_HAS_BEEN_OPENED_KEY,
  );

  if (sidebarHasBeenOpened) {
    return;
  }

  // The bento sidebar is a WebComponent that exists under a Shadow DOM
  const bentoGuide = document.querySelector("bento-sidebar");
  const sidebarToggle = bentoGuide?.shadowRoot?.querySelector(
    "div.bento-sidebar-toggle",
  );

  if (sidebarToggle) {
    // Because of the isolated nature of WebComponents, our VueJS styling wont
    // affect items inside of the Shadow DOM. For that reason we define our
    // CSS animation as a custom CSS property in Bento
    // https://everboarding.trybento.co/styles?anchor=custom_css
    sidebarToggle.classList.add("animation-jump");
    sidebarToggle.addEventListener("click", handleBentoGuideOpened);
  }
};

export const lowerZIndex = () => {
  // The bento sidebar is a WebComponent that exists under a Shadow DOM
  const bentoGuide = document.querySelector("bento-sidebar");
  const sidebarToggle = bentoGuide?.shadowRoot?.querySelector(
    "div.bento-sidebar-toggle",
  );
  const sidebarWrapper = sidebarToggle?.parentElement;

  if (sidebarWrapper) {
    sidebarWrapper.setAttribute("style", "z-index: 100;");
  }
};

export const handleBentoGuideOpened = () => {
  localStorage.setItem(BENTO_SIDEBAR_HAS_BEEN_OPENED_KEY, true);
};
