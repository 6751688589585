import { debounce } from "lodash";

const DEBOUNCE_TIME = 500;

export const debouncedSearch = debounce(function () {
  this.executeSearch();
}, DEBOUNCE_TIME);

// a valid search query is either the empty string ("" or null) or
// a string with at least one non-whitespace character
export const validSearchQuery = (query) => {
  return !query || query.length == 0 || /\S/.test(query);
};

export const cleanSearchQuery = (query) => {
  return query ? query.trim() : "";
};
