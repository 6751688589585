<template>
  <a :href="href">
    <HighlightDocumentContent
      v-if="isContentMarkdown"
      :contentHtml="contentHtml"
    />
    <HighlightContent v-else :contentHtml="contentHtml" />
    <a-icon v-if="iconType" :type="iconType" />
  </a>
</template>

<script>
import HighlightContent from "@/components/atoms/HighlightContent.vue";
import HighlightDocumentContent from "@/components/atoms/HighlightDocumentContent.vue";

export default {
  components: {
    HighlightContent,
    HighlightDocumentContent,
  },

  props: {
    contentHtml: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop -- This appears to be able to be '' by default
    iconType: {
      type: String,
      required: false,
    },
    isContentMarkdown: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
