import { gql } from "@apollo/client";

const ALL_ACTOR_PERMISSION_FIELDS_FRAGMENT = gql`
  fragment allActorPermissionFieldsFragment on ActorPermission {
    canCreate
    canUpdateAll
    permittedAttributesForCreate
    canAbstractUpdate
    canAbstractDelete
  }
`;

export const FETCH_ACTOR_PERMISSIONS_QUERY = gql`
  query getActorPermissions {
    account {
      actorPermissions {
        campaign {
          ...allActorPermissionFieldsFragment
        }
        scorecard {
          ...allActorPermissionFieldsFragment
        }
        category {
          ...allActorPermissionFieldsFragment
        }
        filter {
          ...allActorPermissionFieldsFragment
        }
        level {
          ...allActorPermissionFieldsFragment
        }
        rubric {
          ...allActorPermissionFieldsFragment
        }
        service {
          ...allActorPermissionFieldsFragment
        }
        team {
          ...allActorPermissionFieldsFragment
        }
      }
    }
  }
  ${ALL_ACTOR_PERMISSION_FIELDS_FRAGMENT}
`;
