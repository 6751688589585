import * as types from "./mutation_types.js";
import { get } from "lodash";
import { gqlClient } from "@/shared/apolloClient.js";
import extractApolloErrors from "@/lib/extract_apollo_errors.js";
import {
  SUGGESTIONS_INDEX_QUERY,
  SUGGESTION_GROUPS_INDEX_QUERY,
  SUGGESTION_SET_IGNORED_VALUE_MUTATION,
  ACTION_SUGGESTION_MUTATION,
  SERVICE_DETECTION_INTEGRATION_TYPES,
  SUGGESTIONS_INTEGRATIONS_QUERY,
  DETACH_SUGGESTION_ALIAS_MUTATION,
  SUGGESTION_UPDATE_MUTATION,
} from "./queries.js";
import {
  formatQueryParams,
  formatTableData,
  formatPaginationData,
} from "@/shared/table_helper.js";
import { resourceAdded } from "@/shared/bento_helper.js";

export const fetchSuggestions = (
  { commit, state },
  { queryParams, merge = false, showGroupedSuggestions = false },
) => {
  commit(types.REQUEST_FETCH_SUGGESTIONS);
  commit(types.UPDATE_TABLE_DATA, formatTableData(state, queryParams));

  const variables = formatQueryParams(state);

  if (queryParams["sourceIntegrationTypes"]) {
    variables["sourceIntegrationTypes"] = queryParams["sourceIntegrationTypes"];
  }

  if (queryParams["sourceIntegrationIds"]) {
    variables["sourceIntegrationIds"] = queryParams["sourceIntegrationIds"];
  }

  if (queryParams["teamIds"]) {
    variables["teamIds"] = queryParams["teamIds"].map((id) => ({
      id: id == "null" ? null : id,
    }));
  }

  const index_query = showGroupedSuggestions
    ? SUGGESTION_GROUPS_INDEX_QUERY
    : SUGGESTIONS_INDEX_QUERY;

  gqlClient
    .query({
      query: index_query,
      variables: variables,
    })
    .then((response) => {
      const suggestionType = showGroupedSuggestions
        ? "suggestionGroups"
        : "suggestions";
      const suggestions = get(response, `data.account.${suggestionType}.nodes`);
      const suggestionCount = get(
        response,
        `data.account.${suggestionType}.totalCount`,
      );
      const filteredCount = get(
        response,
        `data.account.${suggestionType}.filteredCount`,
      );
      const hasDeploys = get(response, "data.account.hasDeploys");
      const hasDeployIntegration = get(
        response,
        "data.account.hasDeployIntegrations",
      );

      const mutation = merge
        ? types.MERGE_SUGGESTIONS
        : types.RECEIVE_FETCH_SUGGESTIONS;

      commit(mutation, {
        suggestions,
        suggestionCount,
        filteredCount,
        hasDeploys,
        hasDeployIntegration,
      });
    })
    .catch((error) => {
      commit(types.RECEIVE_FETCH_SUGGESTIONS_ERROR, extractApolloErrors(error));
    });
};

export const updateSuggestionInState = ({ commit, state }, params) => {
  const sug = Object.assign(
    {},
    state.suggestions.find((s) => s.id === params.id),
    { validInput: true },
  );
  const serviceId = sug.action.serviceId;
  const action = get(state.suggestionActions, `${sug.id}.action`);

  if (params.service && !params.owner) {
    sug.action.owner = null;
    params.owner = params.service.owner;
  }

  sug.action = Object.assign({}, sug.action, action, params);

  if (
    sug.action.type === "attach_alias" &&
    sug.action.serviceId === null &&
    serviceId === null
  ) {
    sug.validInput = false;
  }

  if (sug.action.type === "create_service" && !sug.action.serviceName) {
    sug.validInput = false;
  }

  commit(types.UPDATE_SUGGESTION, { sug, params });
};

export const actionSuggestions = ({ commit, dispatch }, { suggestions }) => {
  commit(types.ACTION_SUGGESTIONS);
  suggestions.forEach((suggestion) => {
    suggestion.ownerId = suggestion.owner?.id;
    delete suggestion.owner;
  });

  gqlClient
    .mutate({
      mutation: ACTION_SUGGESTION_MUTATION,
      variables: { input: { suggestions } },
    })
    .then((response) => {
      const actioned = get(response, "data.suggestionAction.results");
      const notActioned = get(response, "data.suggestionAction.notActioned");
      const errors = get(response, "data.suggestionAction.errors");

      commit(types.RECEIVE_ACTION_SUGGESTIONS_RESULTS, {
        actioned,
        notActioned,
        errors,
      });

      resourceAdded("acceptServiceSuggestionsCount", actioned.length);
      dispatch("updateSuggestions", actioned);
      dispatch(
        "suggestionActivity/fetchActivity",
        { queryParams: {} },
        { root: true },
      );
    })
    .catch((error) => {
      commit(
        types.RECEIVE_ACTION_SUGGESTIONS_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const setSuggestionsCount = ({ commit }, count) => {
  commit(types.SET_SUGGESTION_COUNT, count);
};

export const updateSuggestions = ({ commit }, suggestions) => {
  commit(types.UPDATE_SUGGESTIONS, suggestions);
};

export const setSuggestionIgnoredValue = (
  { dispatch, commit, state },
  { suggestionIds, ignored, showGroupedSuggestions = false },
) => {
  commit(types.REQUEST_SET_SUGGESTION_IGNORED_VALUE, ignored);

  gqlClient
    .mutate({
      mutation: SUGGESTION_SET_IGNORED_VALUE_MUTATION,
      variables: { input: { suggestionIds, ignored } },
    })
    .then((response) => {
      const errors = get(response, "data.suggestionsUpdate.errors");

      if (errors.length) {
        commit(types.RECEIVE_UPDATE_SUGGESTION_IGNORED_VALUE_ERROR, errors);
      } else {
        const suggestions = get(response, "data.suggestionsUpdate.results");
        const notIgnored = get(response, "data.suggestionsUpdate.notActioned");

        commit(types.RECEIVE_UPDATE_SUGGESTION_IGNORED_VALUE_SUCCESS, {
          suggestions,
          notIgnored,
          ignored,
        });

        dispatch("updateSuggestions", suggestions);
        dispatch("fetchSuggestions", {
          queryParams: {
            pagination: {
              current: formatPaginationData(state, state.pagination)
                .currentPage,
            },
            filters: state.filters,
          },
          showGroupedSuggestions,
          merge: true,
          namespace: "suggestions",
          urlPersistenceVersion: 2,
        });
        dispatch(
          "suggestionActivity/fetchActivity",
          { queryParams: {} },
          { root: true },
        );
      }
    })
    .catch((errors) => {
      commit(
        types.RECEIVE_UPDATE_SUGGESTION_IGNORED_VALUE_ERROR,
        extractApolloErrors(errors),
      );
    });
};

export const updateSuggestion = ({ commit }, data) => {
  commit(types.REQUEST_UPDATE_SUGGESTION);

  gqlClient
    .mutate({
      mutation: SUGGESTION_UPDATE_MUTATION,
      variables: { input: data },
    })
    .then((response) => {
      const errors = get(response, "data.suggestionUpdate.errors");
      const suggestion = get(response, "data.suggestionUpdate.suggestion");

      if (errors?.length) {
        commit(types.RECEIVE_UPDATE_SUGGESTION_ERROR, errors);
      } else {
        commit(types.RECEIVE_UPDATE_SUGGESTION_SUCCESS, suggestion);
        // Ensure the suggestionAction in state is also updated
        commit(types.UPDATE_SUGGESTION, {
          sug: suggestion,
          params: suggestion,
        });
      }
    });
};

export const detachSuggestionAlias = ({ commit }, { suggestionAlias }) => {
  commit(types.DETACH_SUGGESTION_ALIAS);

  gqlClient
    .mutate({
      mutation: DETACH_SUGGESTION_ALIAS_MUTATION,
      variables: { input: { suggestionAlias } },
    })
    .then((response) => {
      const errors = get(response, "data.detachSuggestionAlias.errors");

      if (errors.length) {
        commit(types.DETACH_SUGGESTION_ALIAS_ERROR, errors);
      } else {
        commit(types.DETACH_SUGGESTION_ALIAS_SUCCESS);
      }
    })
    .catch((error) => {
      commit(types.DETACH_SUGGESTION_ALIAS_ERROR, extractApolloErrors(error));
    });
};

export const fetchSuggestionSourceIntegrationTypes = ({ commit }) => {
  commit(types.REQUEST_FETCH_SUGGESTION_INTEGRATION_TYPES);

  gqlClient
    .query({
      query: SERVICE_DETECTION_INTEGRATION_TYPES,
    })
    .then((response) => {
      const suggestionSourceIntegrationTypes = get(
        response,
        "data.account.serviceDetectionIntegrationTypes",
      );

      commit(types.RECEIVE_FETCH_SUGGESTION_INTEGRATION_TYPES, {
        suggestionSourceIntegrationTypes,
      });
    })
    .catch((error) => {
      commit(
        types.RECEIVE_FETCH_SUGGESTION_INTEGRATION_TYPES_ERROR,
        extractApolloErrors(error),
      );
    });
};

export const fetchSuggestionSourceIntegrations = ({ commit }) => {
  commit(types.REQUEST_FETCH_SUGGESTION_INTEGRATIONS);

  gqlClient
    .query({
      query: SUGGESTIONS_INTEGRATIONS_QUERY,
    })
    .then((response) => {
      const suggestionSourceIntegrations = get(
        response,
        "data.account.suggestionSourceIntegrations.nodes",
      );

      commit(types.RECEIVE_FETCH_SUGGESTION_INTEGRATIONS, {
        suggestionSourceIntegrations,
      });
    })
    .catch((error) => {
      commit(
        types.RECEIVE_FETCH_SUGGESTION_INTEGRATIONS_ERROR,
        extractApolloErrors(error),
      );
    });
};
