import * as actions from "@/modules/generic/actions.js";
import mutations from "@/modules/generic/mutations.js";
import state from "@/modules/generic/state.js";
import { cloneDeep } from "lodash";

export function includeGenericModule(baseIndex) {
  return cloneDeep({
    namespaced: true,
    ...baseIndex,
    state: { ...state, ...baseIndex.state },
    actions: { ...actions, ...baseIndex.actions },
    mutations: { ...mutations, ...baseIndex.mutations },
  });
}
