<template>
  <a-tooltip :title="info" :placement="actualPlacement">
    <template v-if="$slots.info" slot="title">
      <slot name="info" />
    </template>
    <slot name="icon">
      <sup><a-icon type="info-circle-o" style="font-size: 12px" /></sup>
    </slot>
  </a-tooltip>
</template>

<script>
export default {
  name: "InfoTooltip",

  props: {
    // eslint-disable-next-line vue/require-default-prop -- This appears to be able to be required
    info: {
      type: String,
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop -- This appears to be able to have a default of 'right'
    placement: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      actualPlacement: this.placement || "right",
    };
  },
};
</script>
