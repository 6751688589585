<template>
  <a-tooltip :placement="tooltipPlacement">
    <span
      v-if="needsTooltip || !showDisplayText"
      slot="title"
      v-text="tooltipDisplayText"
    />
    <a-tag v-if="input" :style="tagStyles" @click="onTagClick">
      <OpsIcon
        v-if="iconType"
        :type="iconType"
        :highlightIcon="shouldHighlightIcon"
      />
      <HighlightContent
        v-if="showDisplayText && shouldHighlightIcon"
        :contentHtml="displayText"
      />
      <span v-else-if="showDisplayText" @click="onTagClick">{{
        displayText
      }}</span>
    </a-tag>
  </a-tooltip>
</template>

<script>
import OpsIcon from "@/components/atoms/OpsIcon.vue";
import HighlightContent from "@/components/atoms/HighlightContent.vue";

const DISPLAY_LENGTH_DEFAULT = 15;

export default {
  components: {
    OpsIcon,
    HighlightContent,
  },

  props: {
    input: {
      type: String,
      required: true,
    },
    iconMap: {
      type: Object,
      required: true,
    },
    displayLength: {
      type: Number,
      default: DISPLAY_LENGTH_DEFAULT,
    },
    showIconOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    tagStyles: {
      type: String,
      required: false,
      default: "cursor: default;",
    },
    tooltipText: {
      type: String,
      required: false,
      default: null,
    },
    tooltipPlacement: {
      type: String,
      required: false,
      default: "top",
    },
    highlightedInput: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["tagClicked"],

  computed: {
    needsTooltip() {
      return this.input.length > this.displayLength;
    },
    displayText() {
      if (this.highlightedInput) {
        return this.needsTooltip
          ? this.truncateHighlightedInput()
          : this.highlightedInput;
      } else {
        return this.needsTooltip
          ? `${this.input.substring(0, this.displayLength)}...`
          : this.input;
      }
    },
    tooltipDisplayText() {
      return this.tooltipText ? this.tooltipText : this.input;
    },
    iconType() {
      return this.iconMap[this.input.toLowerCase()];
    },
    showDisplayText() {
      return !(this.showIconOnly && this.iconType);
    },
    shouldHighlightIcon() {
      return this.highlightedInput != null;
    },
  },

  methods: {
    truncateHighlightedInput() {
      if (!this.highlightedInput) {
        return null;
      }

      const displayValue = new DOMParser().parseFromString(
        this.highlightedInput,
        "text/html",
      ).documentElement.textContent;
      const truncatedText = `${displayValue.substring(
        0,
        this.displayLength,
      )}...`;

      return this.highlightedInput.replace(displayValue, truncatedText);
    },
    onTagClick() {
      this.$emit("tagClicked");
    },
  },
};
</script>
