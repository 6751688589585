export const SET_INITIAL_DATA = "SET_INITIAL_DATA";

export const REQUEST_SERVICE_ALERTS = "REQUEST_SERVICE_ALERTS";
export const RECEIVE_SERVICE_ALERTS_SUCCESS = "RECEIVE_SERVICE_ALERTS_SUCCESS";
export const RECEIVE_SERVICE_ALERTS_ERROR = "RECEIVE_SERVICE_ALERTS_ERROR";

export const REQUEST_ALERT_DATA = "REQUEST_ALERT_DATA";
export const RECEIVE_ALERT_DATA_SUCCESS = "RECEIVE_ALERT_DATA_SUCCESS";
export const RECEIVE_ALERT_DATA_ERROR = "RECEIVE_ALERT_DATA_ERROR";

export const REQUEST_CREATE_ALERT = "REQUEST_CREATE_ALERT";
export const RECEIVE_CREATE_ALERT_RESPONSE = "RECEIVE_CREATE_ALERT_RESPONSE";
export const RECEIVE_CREATE_ALERT_ERROR = "RECEIVE_CREATE_ALERT_ERROR";

export const REQUEST_DELETE_ALERT = "REQUEST_DELETE_ALERT";
export const RECEIVE_DELETE_ALERT_SUCCESS = "RECEIVE_DELETE_ALERT_SUCCESS";
export const RECEIVE_DELETE_ALERT_ERROR = "RECEIVE_DELETE_ALERT_ERROR";

export const REQUEST_ACCOUNT_ALERTS = "REQUEST_ACCOUNT_ALERTS";
export const RECEIVE_ACCOUNT_ALERTS_SUCCESS = "RECEIVE_ACCOUNT_ALERTS_SUCCESS";
export const RECEIVE_ACCOUNT_ALERTS_ERROR = "RECEIVE_ACCOUNT_ALERTS_ERROR";

export const UPDATE_TABLE_DATA = "UPDATE_TABLE_DATA";
