<template>
  <span v-if="level">
    <div
      :class="gradeClass"
      :style="gradeStyle"
      :data-bento-selector="levelName"
    >
      <a-tooltip :title="level.description" placement="top">
        {{ level.name }}
      </a-tooltip>
    </div>
  </span>
</template>

<script>
import { levelColor } from "@/shared/level_color_helper.js";

export default {
  props: {
    level: {
      required: false,
      type: Object,
      default: null,
    },
    totalLevelCount: {
      required: false,
      type: Number,
      default: 0,
    },
    small: {
      required: false,
      type: Boolean,
      default: false,
    },
    shouldTruncate: {
      required: false,
      type: Boolean,
      default: false,
    },
    levels: {
      required: true,
      type: Array,
    },
  },

  computed: {
    gradeClass() {
      const size = this.small ? "grade small" : "grade large";
      const truncate = this.shouldTruncate && "truncate";

      return [size, truncate];
    },
    gradeStyle() {
      const indexToUse = this.levels.findIndex(
        (level) => level.index === this.level.index,
      );

      const serviceLevelColor = levelColor(
        this.totalLevelCount || this.levels.length,
        indexToUse,
      );
      const colorStyle = serviceLevelColor && {
        color: serviceLevelColor.text,
        borderColor: serviceLevelColor.border,
        backgroundColor: serviceLevelColor.primary,
      };
      const truncate = this.shouldTruncate &&
        this.level.name.length > 15 && { width: "130px" };

      return [colorStyle, truncate];
    },
    levelName() {
      return this.level?.name ?? "";
    },
  },
};
</script>

<style scoped>
.grade {
  width: max-content;
  border-radius: 3px;
  text-align: center;
  border: solid 1px;
}

.small {
  padding: 2px 6px;
  font-size: 14px;
}

.large {
  padding: 8px 20px;
  font-size: 1.5em;
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
</style>
