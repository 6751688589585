import * as types from "./mutation_types.js";

export default {
  [types.SET_INITIAL_DATA](state, data) {
    Object.assign(state, data);
  },
  [types.REQUEST_JOBS](state) {
    state.isFetchingJobs = true;
  },
  [types.RECEIVE_JOBS_SUCCESS](state, jobs) {
    state.isFetchingJobs = false;
    state.jobs = jobs;
    state.errorsFetchingJobs = [];
  },
  [types.RECEIVE_JOBS_ERROR](state, errors) {
    state.isFetchingJobs = false;
    state.errorsFetchingJobs = errors;
    state.jobs = [];
  },
};
