<template>
  <a-dropdown v-if="hasJobsToShow" class="action" :trigger="['click']">
    <div>
      <a-icon v-if="isLoading" type="loading" spin style="font-size: 16px" />

      <span v-if="isLoading" style="padding-left: 6px">
        <b>{{ numberOfOngoingTasks }}</b> Ongoing
        {{ pluralize("Task", numberOfOngoingTasks) }}</span
      >
      <span v-else style="padding-left: 6px">
        <b>{{ numberOfCompletedTasks }}</b> Completed
        {{ pluralize("Task", numberOfCompletedTasks) }}</span
      >
      <a-icon type="down" style="padding-left: 3px; font-size: 14px" />
    </div>

    <a-menu
      slot="overlay"
      :selectable="false"
      :style="{ maxHeight: '400px', overflow: 'auto', padding: '8px 16px' }"
    >
      <template v-if="numberOfOngoingTasks > 0">
        <LongRunningTask
          v-for="task in ongoingTasks"
          :key="task.id"
          :task="task"
        />
      </template>

      <a-divider
        v-if="numberOfOngoingTasks > 0 && numberOfCompletedTasks > 0"
      />

      <template v-if="numberOfCompletedTasks > 0">
        <LongRunningTask
          v-for="task in completedTasks"
          :key="task.id"
          :task="task"
        />
      </template>
    </a-menu>
  </a-dropdown>
</template>

<script>
import moment from "moment";
import pluralize from "pluralize";
import { mapActions, mapState } from "vuex";

import jobs from "@/modules/jobs/index.js";
import LongRunningTask from "@/components/atoms/LongRunningTask.vue";

export default {
  name: "LongRunningTaskDropdown",

  components: {
    LongRunningTask,
  },

  computed: {
    ...mapState({
      tasks: (state) => state.jobs.jobs,
      isFetchingTasks: (state) => state.jobs.isFetchingJobs,
    }),
    isLoading() {
      return this.numberOfOngoingTasks > 0;
    },
    hasJobsToShow() {
      return this.numberOfCompletedTasks + this.numberOfOngoingTasks > 0;
    },
    numberOfOngoingTasks() {
      return this.ongoingTasks.length ?? 0;
    },
    numberOfCompletedTasks() {
      return this.completedTasks.length ?? 0;
    },
    ongoingTasks() {
      return this.tasks.filter((t) => t.status !== "complete");
    },
    completedTasks() {
      return this.tasks.filter((t) => t.status === "complete");
    },
    fifteenMinutesAgo() {
      return new Date(moment().subtract(15, "minutes").format()).toISOString();
    },
  },

  created() {
    this.$store.registerModuleOnce("jobs", jobs);
    // For now we are turning this off because of a large number of timeouts in prod
    // See: https://gitlab.com/jklabsinc/OpsLevel/-/issues/9935
    // this.subscribeToJobUpdates({
    //   templateName: "RepositoryServiceAnalysis",
    // });
  },

  methods: {
    pluralize,
    ...mapActions({
      setInitialData: "jobs/setInitialData",
      subscribeToJobUpdates: "jobs/subscribeToJobUpdates",
    }),
  },
};
</script>
