import { FETCH_ACTOR_PERMISSIONS_QUERY } from "./queries.js";

export const fetchActorPermissions = ({ dispatch }) => {
  const args = {
    query: FETCH_ACTOR_PERMISSIONS_QUERY,
    resourcePath: "account.actorPermissions",
  };

  dispatch("showResource", args);
};
