<template>
  <div>
    <a-alert v-if="notice" type="info" :message="notice" closable show-icon />
    <a-alert v-if="noticeHtml" type="info" closable show-icon>
      <template slot="message">
        <span v-html="noticeHtml" />
      </template>
    </a-alert>
    <a-alert v-if="alert" type="warning" :message="alert" closable show-icon />
    <a-alert v-if="alertHtml" type="warning" closable show-icon>
      <template slot="message">
        <span v-html="alertHtml" />
      </template>
    </a-alert>
    <a-alert v-if="errorHtml" type="error" closable show-icon>
      <template slot="message">
        <span v-if="errorTitle" v-html="errorTitle" />
        <span v-else v-html="errorHtml" />
      </template>
      <template v-if="errorTitle" slot="description">
        <span v-html="errorHtml" />
      </template>
    </a-alert>
  </div>
</template>

<script>
export default {
  name: "Flash",

  props: {
    // eslint-disable-next-line vue/require-default-prop -- These all appear to be strings with default of ''
    notice: {
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop -- These all appear to be strings with default of ''
    noticeHtml: {
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop -- These all appear to be strings with default of ''
    alert: {
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop -- These all appear to be strings with default of ''
    alertHtml: {
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop -- These all appear to be strings with default of ''
    errorHtml: {
      required: false,
    },
    // eslint-disable-next-line vue/require-default-prop -- These all appear to be strings with default of ''
    errorTitle: {
      required: false,
    },
  },
};
</script>
