<template>
  <span>
    <a-tooltip>
      <a-icon v-if="isLocked" type="lock" style="padding-right: 4px" />
      <span slot="title">
        This resource relationship is locked because it is set in your cloud
        provider.
      </span>
    </a-tooltip>
    {{ relationship }}
  </span>
</template>

<script>
export default {
  name: "IntegrationRelationshipLabel",

  props: {
    relationship: {
      type: String,
      required: true,
    },
    isLocked: {
      type: Boolean,
    },
  },
};
</script>
