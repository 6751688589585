import * as types from "./mutation_types.js";

export default {
  [types.UPDATE_TABLE_DATA](state, data) {
    Object.assign(state, data);
  },
  //
  // Index
  //
  [types.REQUEST_TEAMS](state) {
    state.isFetchingTeams = true;
    state.neverFetchedTeams = false;
  },
  [types.RECEIVE_TEAMS_SUCCESS](state, { teams, totalCount }) {
    state.isFetchingTeams = false;
    state.errorsFetchingTeams = [];
    state.teams = teams;

    state.aggregatedTeams = Object.values(
      [...state.aggregatedTeams, ...teams].reduce(
        (accumulator, team) => ({ ...accumulator, [team.id]: team }),
        {},
      ),
    );
    state.totalCount = totalCount;
  },
  [types.RECEIVE_TEAMS_ERROR](state, errors) {
    state.neverFetchedTeams = true; // allow future success to populate teams array
    state.isFetchingTeams = false;
    state.errorsFetchingTeams = errors;
    state.teams = [];
  },
  //
  // SHOW
  //
  [types.REQUEST_TEAM](state) {
    state.isFetchingTeam = true;
  },
  [types.RECEIVE_TEAM_SUCCESS](state, team) {
    state.isFetchingTeam = false;
    state.errorsFetchingTeam = [];
    state.team = team;
  },
  [types.RECEIVE_TEAM_ERROR](state, errors) {
    state.isFetchingTeam = false;
    state.errorsFetchingTeam = errors;
    state.team = null;
  },
  [types.REQUEST_UPDATE_TEAM](state) {
    state.isUpdatingTeam = true;
    state.errorsUpdatingTeam = [];
  },
  [types.RECEIVE_UPDATE_TEAM_SUCCESS](state, team) {
    state.isUpdatingTeam = false;
    state.updatedTeam = team;
  },
  [types.RECEIVE_UPDATE_TEAM_ERROR](state, errors) {
    state.isUpdatingTeam = false;
    state.errorsUpdatingTeam = errors;
  },
  [types.REQUEST_CREATE_TEAM](state) {
    state.isCreatingTeam = true;
    state.errorsCreatingTeam = [];
  },
  [types.RECEIVE_CREATE_TEAM_SUCCESS](state, team) {
    state.isCreatingTeam = false;
    state.createdTeam = team;
  },
  [types.RECEIVE_CREATE_TEAM_ERROR](state, errors) {
    state.isCreatingTeam = false;
    state.errorsCreatingTeam = errors;
  },
  [types.REQUEST_DELETE_TEAM](state) {
    state.isDeletingTeam = true;
    state.errorsDeletingTeam = [];
  },
  [types.RECEIVE_DELETE_TEAM_SUCCESS](state, teamId) {
    state.isDeletingTeam = false;
    const idx = state.teams.findIndex((team) => team.id == teamId);

    if (idx >= 0) {
      state.teams.splice(idx, 1);
    }

    state.deletedTeamId = teamId;
  },
  [types.RECEIVE_DELETE_TEAM_ERROR](state, errors) {
    state.isDeletingTeam = false;
    state.errorsDeletingTeam = errors;
  },
  // Create/Update Team Contacts
  [types.REQUEST_ADD_TEAM_CONTACT](state) {
    state.isAddingTeamContact = true;
  },
  [types.RECEIVE_ADD_TEAM_CONTACT_SUCCESS](state, { contact, dataIndex }) {
    state.isAddingTeamContact = false;
    state.errorsAddingTeamContact = [];
    state.team.contacts.splice(dataIndex, 1, contact);
  },
  [types.RECEIVE_ADD_TEAM_CONTACT_ERROR](state, { errors, dataIndex }) {
    state.isAddingTeamContact = false;
    errors.forEach((error) => {
      error.index = dataIndex;
    });
    state.errorsAddingTeamContact = errors;
  },
  // Delete Team Contacts
  [types.REQUEST_DELETE_TEAM_CONTACT](state) {
    state.isDeletingTeamContact = true;
  },
  [types.RECEIVE_DELETE_TEAM_CONTACT_SUCCESS](state, id) {
    state.isDeletingTeamContact = false;
    state.errorsDeletingTeamContact = [];
    const idx = state.team.contacts.findIndex((contact) => contact.id == id);

    if (idx >= 0) {
      state.team.contacts.splice(idx, 1);
    }
  },
  [types.RECEIVE_DELETE_TEAM_CONTACT_ERROR](state, errors) {
    state.isDeletingTeamContact = false;
    state.errorsDeletingTeamContact = errors;
  },

  // Update Team Contact
  [types.REQUEST_SET_DEFAULT_TEAM_CONTACT](state) {
    state.isUpdatingDefaultTeamContact = true;
  },
  [types.RECEIVE_SET_DEFAULT_TEAM_CONTACT_SUCCESS](state, { contact }) {
    state.isUpdatingDefaultTeamContact = false;
    state.errorsUpdatingDefaultTeamContact = [];
    state.team.contacts.forEach((existingContact, idx) => {
      if (existingContact.type === contact.type) {
        state.team.contacts[idx].isDefault = existingContact.id === contact.id;
      }
    });
  },
  [types.RECEIVE_SET_DEFAULT_TEAM_CONTACT_ERROR](state, errors) {
    state.isUpdatingDefaultTeamContact = false;
    state.errorsUpdatingDefaultTeamContact = errors;
  },

  // Actionable Reporting
  [types.REQUEST_TEAM_CAMPAIGN_PERFORMANCE](state) {
    state.isFetchingTeamCampaignPerformance = true;
  },
  [types.RECEIVE_TEAM_CAMPAIGN_PERFORMANCE_SUCCESS](state, value) {
    state.isFetchingTeamCampaignPerformance = false;
    state.errorsFetchingTeamCampaignPerformance = [];
    state.teamCampaignPerformance = value;
  },
  [types.RECEIVE_TEAM_CAMPAIGN_PERFORMANCE_ERROR](state, errors) {
    state.isFetchingTeamCampaignPerformance = false;
    state.errorsFetchingTeamCampaignPerformance = errors;
    state.teamCampaignPerformance = null;
  },
  [types.REQUEST_TEAMS_TREE](state) {
    state.isFetchingTeamsTree = true;
    state.teamsTree = [];
    state.errorsFetchingTeamTree = [];
  },
  [types.RECEIVE_TEAMS_TREE_SUCCESS](state, { teams }) {
    state.isFetchingTreeTeams = false;
    state.treeTeams = teams;
    state.errorsFetchingTeamTree = [];
  },
  [types.RECEIVE_TEAMS_TREE_ERROR](state, { errors }) {
    state.isFetchingTreeTeams = false;
    state.treeTeams = {};
    state.treeTeamsCount = 0;
    state.errorsFetchingTeamTree = errors;
  },
};
