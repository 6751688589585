import * as types from "./mutation_types.js";

export default {
  [types.SET_INITIAL_DATA](state, data) {
    Object.assign(state, data);
  },
  [types.REQUEST_MEMBERS](state) {
    state.isFetchingMembers = true;
  },
  [types.RECEIVE_MEMBERS_SUCCESS](state, { members, totalCount, permissions }) {
    state.isFetchingMembers = false;
    state.members = members;
    state.membersTotalCount = totalCount;
    state.permissions = permissions;
    state.errorsFetchingMembers = [];
  },
  [types.RECEIVE_MEMBERS_ERROR](state, { errors }) {
    state.isFetchingMembers = false;
    state.errorsFetchingMembers = errors;
    state.members = [];
    state.membersTotalCount = 0;
  },
  [types.UPDATE_TABLE_DATA](state, data) {
    Object.assign(state, data);
  },
  //
  // Team Show
  //
  [types.SET_TEAM_ID](state, { teamId }) {
    state.teamId = teamId;
  },
  [types.REQUEST_CREATE_MEMBERS](state) {
    state.isModifyingMembers = true;
    state.createMemberSuccess = false;
  },
  [types.RECEIVE_CREATE_MEMBERS_SUCCESS](state, { teamId, members, errors }) {
    state.members = members;
    state.joinedTeams.push(teamId);
    state.actionedTeam = teamId;
    state.createMemberSuccess = true;
    state.isModifyingMembers = false;
    state.errorsCreatingMembers = errors;
  },
  [types.RECEIVE_CREATE_MEMBERS_ERROR](state, { teamId, errors }) {
    state.members = [];
    state.actionedTeam = teamId;
    state.createMemberSuccess = false;
    state.isModifyingMembers = false;
    state.errorsCreatingMembers = errors;
  },
  //
  // Team Delete
  //
  [types.REQUEST_DELETE_MEMBERS](state) {
    state.isModifyingMembers = true;
    state.deleteMemberSuccess = false;
  },
  [types.RECEIVE_DELETE_MEMBERS_SUCCESS](state, { teamId, members }) {
    state.deletedMembers = members;
    state.joinedTeams.splice(state.joinedTeams.indexOf(teamId), 1);
    state.actionedTeam = teamId;
    state.deleteMemberSuccess = true;
    state.isModifyingMembers = false;
    state.errorsDeletingMembers = [];
  },
  [types.RECEIVE_DELETE_MEMBERS_ERROR](state, { teamId, errors }) {
    state.deletedMembers = [];
    state.actionedTeam = teamId;
    state.deleteMemberSuccess = false;
    state.isModifyingMembers = false;
    state.errorsDeletingMembers = errors;
  },
};
