<template>
  <div style="text-align: center; padding: 10px 0px">
    <slot />
    <br />
    <slot name="title">
      <h3 v-if="title">
        {{ title }}
      </h3>
    </slot>
    <slot name="text">
      <p v-if="text">
        {{ text }}
      </p>
    </slot>
    <slot v-if="!hideButton" name="button">
      <a v-if="link" :href="link" :target="target">
        <a-button :type="type">{{ buttonText }}</a-button>
      </a>
      <a-button
        v-else-if="buttonText"
        :icon="buttonIcon"
        :type="type"
        @click="() => $emit('click:call-to-action')"
      >
        {{ buttonText }}
      </a-button>
    </slot>
  </div>
</template>

<script>
export default {
  name: "CallToAction",

  props: {
    text: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: undefined,
    },
    link: {
      type: [String, Boolean],
      default: false,
    },
    buttonText: {
      type: String,
      required: false,
      default: null,
    },
    buttonIcon: {
      type: String,
      required: false,
      default: null,
    },
    hideButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      validator: function (value) {
        return (
          ["primary", "ghost", "dashed", "danger", "default"].indexOf(value) !==
          -1
        );
      },
      default: undefined,
    },
    target: {
      type: String,
      validator: function (value) {
        return ["_blank", "_self", "_parent", "_top"].indexOf(value) !== -1;
      },
      default: "_self",
    },
  },
};
</script>
