<template>
  <a-icon
    v-if="value"
    type="check-circle"
    theme="twoTone"
    twoToneColor="#52c41a"
  />
  <a-icon v-else type="close-circle" theme="twoTone" twoToneColor="#F5222D" />
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
