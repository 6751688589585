export const internalNotifications = {
  computed: {
    messages() {
      return this.$store.getters["internalNotifications/messages"];
    },
  },
  watch: {
    messages: function (messages) {
      if (messages.length > 0) {
        const message = messages[messages.length - 1];

        this.showMessage(message);
      }
    },
  },
  methods: {
    clearMessages() {
      this.$store.dispatch("internalNotifications/clearMessages", {
        root: true,
      });
    },
    showMessage({ message, type, duration }) {
      let messagePromise;

      switch (type) {
        case "success":
          messagePromise = this.$message.success(message, duration);
          break;
        case "error":
          messagePromise = this.$message.error(message, duration);
          break;
        case "info":
          messagePromise = this.$message.info(message, duration);
          break;
        case "warning":
          messagePromise = this.$message.warning(message, duration);
          break;
        case "warn":
          messagePromise = this.$message.warn(message, duration);
          break;
        case "loading":
          messagePromise = this.$message.loading(message, duration);
          break;
        default:
          messagePromise = this.$message.error(message, duration);
      }

      messagePromise.then(() => {
        this.clearMessages();
      });
    },
  },
};
