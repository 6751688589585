<template>
  <div style="display: flex; justify-content: space-between">
    <template v-if="numChecks > 0">
      <a-progress
        type="line"
        :showInfo="false"
        :percent="percentPassing"
        style="min-width: 40%; max-width: 40%; padding-right: 6px"
      />

      <span class="primary-table-text passing-percent"
        >{{ percentPassing }}%</span
      >
      <span v-if="showRaw" class="secondary-table-text passing-raw"
        >{{ numPassingChecks }}/{{ numChecks }}</span
      >
      <span v-else class="secondary-table-text passing-raw" />
    </template>
    <i v-else> No {{ statType }} </i>
  </div>
</template>

<script>
import { percentage } from "@/shared/helpers.js";

export default {
  name: "ServiceStat",

  props: {
    numPassingChecks: {
      type: Number,
      required: true,
    },
    numChecks: {
      type: Number,
      required: true,
    },
    showRaw: {
      type: Boolean,
      default: true,
    },
    statType: {
      type: String,
      required: false,
      default: "checks",
    },
  },

  computed: {
    percentPassing() {
      if (this.numChecks === 0) {
        return 100;
      }

      return percentage(this.numPassingChecks, this.numChecks);
    },
  },
};
</script>

<style scoped>
.passing-percent {
  padding-right: 4px;
}

.passing-raw {
  margin-left: auto;
}
</style>
