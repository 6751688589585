export default {
  isCreatingResource: false,
  createdResource: null,
  isUpdatingResource: false,
  updatedResource: null,
  resource: null,
  resources: [],
  resourcesTotalCount: 0,
  resourcesFilteredCount: 0,
  isDeletingResource: false,
  isLoadingResources: false,
  isLoadingResource: false,
  errorsCreatingResource: [],
  errorsDeletingResource: [],
  errorsUpdatingResource: [],
  errorsLoadingResource: [],
  errorsLoadingResources: [],
};
