import * as types from "./mutation_types.js";
import {
  SEARCH_SERVICES_QUERY,
  SEARCH_DOCUMENTS_QUERY,
  SEARCH_DOMAINS_QUERY,
  SEARCH_APIS_QUERY,
  SEARCH_SYSTEMS_QUERY,
  FETCH_TECH_DOC_ATTACHED_RESOURCES_QUERY,
} from "./queries.js";

import { get, orderBy } from "lodash";
import { gqlClient } from "@/shared/apolloClient.js";
import extractApolloErrors from "@/lib/extract_apollo_errors.js";
import { formatQueryParams, formatTableData } from "@/shared/table_helper.js";

export const resetSearchResults = ({ commit }) =>
  commit(types.RESET_SEARCH_RESULTS);

export const setCurrentSearchTab = ({ commit }, searchTab) => {
  commit(types.SET_CURRENT_SEARCH_TAB, searchTab);
};

export const fetchServiceSearchResults = ({ commit }, params) => {
  commit(types.REQUEST_SEARCH_RESULTS);

  gqlClient
    .query({
      query: SEARCH_SERVICES_QUERY,
      variables: params,
    })
    .then((response) => {
      const searchData = get(response, "data.account.servicesV2");
      const filteredCount = searchData["filteredCount"];
      const searchResults = augmentHighlightData(searchData);
      const totalLevelCount = get(
        response,
        "data.account.rubric.levels.totalCount",
      );

      commit(types.RECEIVE_SEARCH_RESULTS_SUCCESS, {
        searchResults,
        filteredCount,
        totalLevelCount,
      });
    })
    .catch((error) => {
      commit(types.RECEIVE_SEARCH_RESULTS_ERROR, extractApolloErrors(error));
    });
};

export const fetchDocumentSearchResults = ({ commit }, params) => {
  commit(types.REQUEST_SEARCH_RESULTS);

  gqlClient
    .query({
      query: SEARCH_DOCUMENTS_QUERY,
      variables: params,
    })
    .then((response) => {
      const searchData = get(response, "data.account.documents");
      const filteredCount = searchData["filteredCount"];
      const searchResults = augmentHighlightData(searchData);

      commit(types.RECEIVE_SEARCH_RESULTS_SUCCESS, {
        searchResults,
        filteredCount,
      });
    })
    .catch((error) => {
      commit(types.RECEIVE_SEARCH_RESULTS_ERROR, extractApolloErrors(error));
    });
};

export const updateServiceTableData = (
  { commit, state, dispatch },
  { queryParams },
) => {
  commit(types.UPDATE_TABLE_DATA, formatTableData(state, queryParams));
  dispatch("fetchServiceSearchResults", { ...formatQueryParams(state) });
};

export const documentUpdateData = (
  { commit, state, dispatch },
  { queryParams },
) => {
  commit(types.UPDATE_TABLE_DATA, formatTableData(state, queryParams));
  dispatch("fetchDocumentSearchResults", { ...formatQueryParams(state) });
};

export const updateSystemData = (
  { commit, state, dispatch },
  { queryParams },
) => {
  commit(types.UPDATE_TABLE_DATA, formatTableData(state, queryParams));
  dispatch("fetchSystemsSearchResults", { ...formatQueryParams(state) });
};

export const updateDomainData = (
  { commit, state, dispatch },
  { queryParams },
) => {
  commit(types.UPDATE_TABLE_DATA, formatTableData(state, queryParams));
  dispatch("fetchDomainsSearchResults", { ...formatQueryParams(state) });
};

export const updateApiData = ({ commit, state, dispatch }, { queryParams }) => {
  commit(types.UPDATE_TABLE_DATA, formatTableData(state, queryParams));
  dispatch("fetchApisSearchResults", { ...formatQueryParams(state) });
};

const augmentHighlightData = (resources) => {
  resources.edges.map((edge) => {
    edge.node.highlights = edge.highlights;
  });
  resources = resources.edges.map((edge) => edge.node);
  resources = sortAndAnnotateTags(resources);

  return resources;
};

const sortAndAnnotateTags = (services) => {
  var servicesCount = services.length;

  for (var i = 0; i < servicesCount; i++) {
    const service = services[i];

    if (!service.highlights) {
      continue;
    }

    const tagsHighlights = service.highlights["tags"];

    if (tagsHighlights && tagsHighlights.length) {
      const tagIdToHighlightsMap = {};

      tagsHighlights.forEach((tagHighlight) => {
        tagIdToHighlightsMap[tagHighlight.id] = tagHighlight.highlights;
      });
      service.tags.nodes.forEach((tag) => {
        tag.highlights = tagIdToHighlightsMap[tag.plainId];
      });
      // order the tags so the highlighted tags are first
      service.tags.nodes = orderBy(service.tags.nodes, ["highlights"]);
      service.tags.num_matching_tags = tagsHighlights.length;
    }
  }

  return services;
};

export const fetchAttachedResourcesForDocument = ({ commit }, params) => {
  commit(types.REQUEST_TECH_DOC_ATTACHED_RESOURCES, {
    documentId: params.documentId,
  });

  gqlClient
    .query({
      query: FETCH_TECH_DOC_ATTACHED_RESOURCES_QUERY,
      variables: params,
    })
    .then((response) => {
      const documentWithResources = get(response, "data.account.document");

      commit(types.RECEIVE_TECH_DOC_ATTACHED_RESOURCES_SUCCESS, {
        documentWithResources,
      });
    })
    .catch((error) => {
      commit(types.RECEIVE_TECH_DOC_ATTACHED_RESOURCES_ERROR, {
        error: extractApolloErrors(error),
        documentId: params.documentId,
      });
    });
};

export const fetchSystemsSearchResults = ({ commit }, params) => {
  commit(types.REQUEST_SEARCH_RESULTS);

  gqlClient
    .query({
      query: SEARCH_SYSTEMS_QUERY,
      variables: params,
    })
    .then((response) => {
      const searchData = get(response, "data.account.systems");
      const filteredCount = searchData["filteredCount"];
      const searchResults = augmentHighlightData(searchData);

      commit(types.RECEIVE_SEARCH_RESULTS_SUCCESS, {
        searchResults,
        filteredCount,
      });
    })
    .catch((error) => {
      commit(types.RECEIVE_SEARCH_RESULTS_ERROR, extractApolloErrors(error));
    });
};

export const fetchDomainsSearchResults = ({ commit }, params) => {
  commit(types.REQUEST_SEARCH_RESULTS);

  gqlClient
    .query({
      query: SEARCH_DOMAINS_QUERY,
      variables: params,
    })
    .then((response) => {
      const searchData = get(response, "data.account.domains");
      const filteredCount = searchData["filteredCount"];
      const searchResults = augmentHighlightData(searchData);

      commit(types.RECEIVE_SEARCH_RESULTS_SUCCESS, {
        searchResults,
        filteredCount,
      });
    })
    .catch((error) => {
      commit(types.RECEIVE_SEARCH_RESULTS_ERROR, extractApolloErrors(error));
    });
};

export const fetchApisSearchResults = ({ commit }, params) => {
  commit(types.REQUEST_SEARCH_RESULTS);

  gqlClient
    .query({
      query: SEARCH_APIS_QUERY,
      variables: params,
    })
    .then((response) => {
      const searchData = get(response, "data.account.apiEndpoints");
      const filteredCount = searchData["filteredCount"];
      const searchResults = augmentHighlightData(searchData);

      commit(types.RECEIVE_SEARCH_RESULTS_SUCCESS, {
        searchResults,
        filteredCount,
      });
    })
    .catch((error) => {
      commit(types.RECEIVE_SEARCH_RESULTS_ERROR, extractApolloErrors(error));
    });
};
