import { tableState } from "@/shared/table_helper.js";

export default () => ({
  isFetchingMembers: false,
  membersTotalCount: 0,
  permissions: null,
  errorsFetchingMembers: [],
  ...tableState({ itemsPerPage: 10 }),
  isModifyingMembers: false,
  createMemberSuccess: false,
  deleteMemberSuccess: false,
  members: [],
  deletedMembers: [],
  errorsCreatingMembers: [],
  errorsDeletingMembers: [],
  actionedTeam: "",
  joinedTeams: [],
  teamId: "",
});
