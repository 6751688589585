export const REQUEST_LEVELS = "REQUEST_LEVELS";
export const RECEIVE_LEVELS_SUCCESS = "RECEIVE_LEVELS_SUCCESS";
export const RECEIVE_LEVELS_ERROR = "RECEIVE_LEVELS_ERROR";

export const REQUEST_UPDATE_LEVEL = "REQUEST_UPDATE_LEVEL";
export const RECEIVE_UPDATE_LEVEL_SUCCESS = "RECEIVE_UPDATE_LEVEL_SUCCESS";
export const RECEIVE_UPDATE_LEVEL_ERROR = "RECEIVE_UPDATE_LEVEL_ERROR";

export const REQUEST_CREATE_LEVEL = "REQUEST_CREATE_LEVEL";
export const RECEIVE_CREATE_LEVEL_SUCCESS = "RECEIVE_CREATE_LEVEL_SUCCESS";
export const RECEIVE_CREATE_LEVEL_ERROR = "RECEIVE_CREATE_LEVEL_ERROR";

export const REQUEST_DELETE_LEVEL = "REQUEST_DELETE_LEVEL";
export const RECEIVE_DELETE_LEVEL_SUCCESS = "RECEIVE_DELETE_LEVEL_SUCCESS";
export const RECEIVE_DELETE_LEVEL_ERROR = "RECEIVE_DELETE_LEVEL_ERROR";
