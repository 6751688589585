<template>
  <div>
    <ExpandButton
      :onClick="toggleTableExpanded"
      :expandedRows="areAllRowsExpanded"
    />
    <Table
      :rowKey="(row) => row.id"
      :columns="columns"
      :data="searchResults"
      :totalTableCount="filteredSearchCount"
      :paginationState="paginationState"
      :sortState="sortState"
      :loading="isFetchingSearchResults"
      :expandIconColumnIndex="-1"
      :expandIconAsCell="true"
      :expandedRowKeys="currentExpandedRows"
      @expandedRowsChange="handleExpandedRowsChange"
      @updateTableData="handleTableChange"
    >
      <template v-slot:name="system">
        <HighlightContentWithHref
          v-if="highlights(system, 'name')"
          :contentHtml="highlights(system, 'name')[0]"
          :href="system.href"
        />
        <a v-else :href="system.href">{{ system.name }}</a>
      </template>
      <template v-slot:domains="system">
        <a v-if="system.parent" :href="system.parent.href">
          {{ system.parent.name }}
        </a>
      </template>
      <template v-slot:children="system">
        {{ childrenTotalCount(system) }}
      </template>
      <template v-slot:owner="system">
        <HighlightContentWithHref
          v-if="highlights(system, 'owner.name')"
          :contentHtml="highlights(system, 'owner.name')[0]"
          :href="system.owner.href"
        />
        <a v-else-if="system.owner" :href="system.owner.href">{{
          system.owner.name
        }}</a>
      </template>
      <template v-slot:expandedRowRender="system">
        <EntitySearchDetails :entity="system" />
      </template>
    </Table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Table from "@/components/molecules/Table.vue";
import ExpandButton from "@/components/ExpandButton.vue";
import HighlightContentWithHref from "@/components/atoms/HighlightContentWithHref.vue";
import EntitySearchDetails from "@/components/molecules/EntitySearchDetails.vue";
import {
  saveTableStateToUrl,
  DEFAULT_SORTED_INFO,
} from "@/shared/table_helper.js";

export default {
  components: {
    Table,
    HighlightContentWithHref,
    EntitySearchDetails,
    ExpandButton,
  },

  props: {
    searchTerm: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentExpandedRows: [],
    };
  },

  computed: {
    ...mapState({
      searchResults: (state) => state.systemsSearch.searchResults,
      isFetchingSearchResults: (state) =>
        state.systemsSearch.isFetchingSearchResults,
      filteredSearchCount: (state) => state.systemsSearch.filteredCount,
      paginationState: (state) => state.systemsSearch.pagination,
      sortState: (state) => state.systemsSearch.sort,
    }),
    columns() {
      return [
        {
          title: "Name",
          key: "name",
          defaultSortOrder: "ascend",
          sortOrder: this.sortOrder("name"),
          sorter: true,
          dataType: "String",
        },
        {
          title: "Owner",
          key: "owner",
          defaultSortOrder: "ascend",
          sortOrder: this.sortOrder("owner"),
          sorter: true,
          dataType: "TeamType",
          scopedSlots: { customRender: "owner" },
        },
        {
          title: "Domain",
          key: "parent",
          defaultSortOrder: "ascend",
          sortOrder: this.sortOrder("parent"),
          sorter: true,
          dataType: "String",
          scopedSlots: { customRender: "domains" },
        },
        {
          title: "Services",
          key: "child_services_count",
          defaultSortOrder: "ascend",
          sortOrder: this.sortOrder("child_services_count"),
          sorter: true,
          dataType: "Number",
          scopedSlots: { customRender: "children" },
          align: "center",
        },
        {
          title: "Tags",
          key: "tags",
          dataIndex: "tags.nodes",
          dataType: "Types::TagConnectionType",
        },
      ];
    },
    areAllRowsExpanded() {
      return this.currentExpandedRows.length === this.searchResults.length;
    },
    recordsWithSummaryHighlights() {
      return this.searchResults.filter((record) => {
        if (!record.highlights) {
          return false;
        }

        const summaryProperties = [
          "aliases.value",
          "friendly_id_slugs.slug",
          "description",
          "note.content",
        ];

        return Object.keys(record.highlights).some((highlight) =>
          summaryProperties.includes(highlight),
        );
      });
    },
  },

  watch: {
    searchResults() {
      this.expandRowsWithSummaryHighlights();
    },
  },

  created() {
    this.expandRowsWithSummaryHighlights();
  },

  methods: {
    ...mapActions({
      updateSystemData: "systemsSearch/updateSystemData",
    }),
    handleTableChange({ queryParams }) {
      let { sorter } = queryParams;

      if (!sorter?.order) {
        sorter = DEFAULT_SORTED_INFO;
      }

      saveTableStateToUrl(
        {
          pagination: queryParams.pagination,
          sorter,
          searchTerm: this.searchTerm,
        },
        { defaultSort: DEFAULT_SORTED_INFO },
      );
      this.updateSystemData({
        queryParams: { ...queryParams, sorter, searchTerm: this.searchTerm },
      });
    },
    childrenTotalCount(system) {
      return system.childServices?.filteredCount || 0;
    },
    highlights(object, property) {
      return object.highlights?.[property];
    },
    handleExpandedRowsChange(expandedRowKeys) {
      this.currentExpandedRows = expandedRowKeys;
    },
    toggleTableExpanded() {
      if (this.areAllRowsExpanded) {
        this.currentExpandedRows = [];
      } else {
        this.currentExpandedRows = this.searchResults.map((r) => r.id);
      }
    },
    expandRowsWithSummaryHighlights() {
      if (this.recordsWithSummaryHighlights.length) {
        this.currentExpandedRows = this.recordsWithSummaryHighlights.map(
          (r) => r.id,
        );
      }
    },
    sortOrder(key) {
      return this.sortState.key === key ? this.sortState.direction : undefined;
    },
  },
};
</script>
