import * as types from "./mutation_types.js";
import { get } from "lodash";

export default {
  //
  // Fetch All Alerts
  //
  [types.REQUEST_ALERT_DATA](state) {
    state.isFetchingAlertData = true;
  },
  [types.RECEIVE_ALERT_DATA_SUCCESS](
    state,
    { alertSources, totalCount, filteredCount },
  ) {
    state.isFetchingAlertData = false;
    state.alertData = alertSources;
    state.totalAlertSourceCount = totalCount;
    state.filteredAlertSourceCount = filteredCount;
    state.errorsFetchingAlertData = [];
  },
  [types.RECEIVE_ALERT_DATA_ERROR](state, apiErrors) {
    state.isFetchingAlertData = false;
    state.errorsFetchingAlertData = apiErrors;
  },
  //
  // Fetch Service Alert Sources
  //
  [types.REQUEST_SERVICE_ALERTS](state) {
    state.isFetchingServiceAlerts = true;
  },
  [types.RECEIVE_SERVICE_ALERTS_SUCCESS](state, { account }) {
    state.isFetchingServiceAlerts = false;
    state.hasMonitoringIntegrations =
      get(account, "monitoringIntegrations.totalCount") > 0;
    state.hasIncidentNotifyingIntegrations =
      get(account, "incidentNotifyingIntegrations.totalCount") > 0;
    state.hasSlackIntegration =
      get(account, "slackIntegrations.totalCount") > 0;
    state.alertSourceEdges = get(account, "service.alertSources.edges");
    state.alertSourceTotalCount = get(
      account,
      "service.alertSources.totalCount",
    );
    state.errorsFetchingServiceAlert = [];
  },
  [types.RECEIVE_SERVICE_ALERTS_ERROR](state, apiErrors) {
    state.isFetchingServiceAlerts = false;
    state.errorsFetchingServiceAlert = apiErrors;
  },
  //
  // Fetch Account Alert Data
  //
  [types.REQUEST_ACCOUNT_ALERTS](state) {
    state.isFetchingAlertData = true;
  },
  [types.RECEIVE_ACCOUNT_ALERTS_SUCCESS](state, account) {
    state.isFetchingAlertData = false;
    state.hasMonitoringIntegrations = get(account, "hasMonitoringIntegrations");
    state.errorsFetchingAlertData = [];
  },
  [types.RECEIVE_ACCOUNT_ALERTS_ERROR](state, apiErrors) {
    state.isFetchingAlertData = false;
    state.errorsFetchingAlertData = apiErrors;
  },
  //
  // Create
  //
  [types.REQUEST_CREATE_ALERT](state) {
    state.isCreatingAlert = true;
    state.allCreatingFailed = false;
  },
  [types.RECEIVE_CREATE_ALERT_ERROR](state, errors) {
    state.isCreatingAlert = false;
    state.allCreatingFailed = true;
    state.errorsCreatingAlert = errors;
  },
  [types.RECEIVE_CREATE_ALERT_RESPONSE](state, mutationResults) {
    state.isCreatingAlert = false;
    state.allCreatingFailed = mutationResults.every(
      (result) => result.errors.length !== 0,
    );

    state.errorsCreatingAlert = [];

    for (const result of mutationResults) {
      if (result.errors.length !== 0) {
        state.errorsCreatingAlert.push(...result.errors);
      } else {
        state.alertSourceEdges.unshift({
          id: result.alertSourceServices[0].id,
          node: result.alertSourceServices[0].alertSource,
          status: result.alertSourceServices[0].status,
        });
      }
    }
  },
  //
  // Delete
  //
  [types.REQUEST_DELETE_ALERT](state) {
    state.isDeletingAlert = true;
  },
  [types.RECEIVE_DELETE_ALERT_SUCCESS](state, deletedAlertSourceId) {
    state.isDeletingAlert = false;
    state.errorsDeletingAlert = [];
    state.alertSourceEdges = state.alertSourceEdges.filter(
      (alertSourceEdge) => alertSourceEdge.id != deletedAlertSourceId,
    );
  },
  [types.RECEIVE_DELETE_ALERT_ERROR](state, errors) {
    state.isDeletingAlert = false;
    state.errorsDeletingAlert = errors;
  },

  [types.UPDATE_TABLE_DATA](state, data) {
    Object.assign(state, data);
  },
};
