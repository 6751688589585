// truncateWithSeparator will take a string, and will truncate if it exceeds a certain length
// it takes a separator character that will be used to split the string. Only the first and
// last segments will be displayed. The motivating case for this is / in path truncation.
// Note: The ... isn't considered as part of the returned length, meaning the returned string
// maybe slightly above the given length.
export const truncateWithSeparator = (input, separator, displayLength) => {
  // if input is shorter than displayLength, nothing needs to be done
  if (input.length <= displayLength) {
    return input;
  }

  const segments = input.split(separator);
  const oddOffset = displayLength & 1; // i.e. last bit is 1 means number is odd
  const segmentLength = displayLength / 2; // target length for a segment

  if (segments.length === 1) {
    return `${segments[0].slice(
      0,
      segmentLength + oddOffset,
    )}...${segments[0].slice(-segmentLength)}`;
  }

  // if there are multiple parts we will take the first and last part.
  let firstPart = segments[0];
  let lastPart = segments[segments.length - 1];

  // only truncate the parts that are too long. If the part is short enough we will add separator to indicate this.
  firstPart =
    firstPart.length > segmentLength + oddOffset
      ? `${firstPart.slice(0, segmentLength + oddOffset)}`
      : `${firstPart}${separator}`;
  lastPart =
    lastPart.length > segmentLength
      ? `${lastPart.slice(-segmentLength)}`
      : `${separator}${lastPart}`;

  return `${firstPart}...${lastPart}`;
};

// convert string from camel case to human readable
// example: octopusDeploy -> Octopus Deploy
export const toCapitalizedWords = (text) => {
  var words = text.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(" ");
};

// capitalize the first letter of a word
// example: testing -> Testing
export const capitalize = (word) => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};
