import { gqlClient } from "@/shared/apolloClient.js";
import { TRACK_USER_EVENT } from "./queries.js";

export const trackUserClick = (_, eventName) => {
  // As a background task with no user feedback, just returns the promise
  return gqlClient
    .mutate({
      mutation: TRACK_USER_EVENT,
      variables: { name: `click#${eventName}` },
    })
    .catch(() => {
      // console.warn(errors);
    });
};
