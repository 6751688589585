import * as types from "./mutation_types.js";
import extractApolloErrors from "@/lib/extract_apollo_errors.js";
import errorMatchesPath from "@/lib/graphql_error_path_matcher.js";
import { get, some } from "lodash";
import { gqlClient } from "@/shared/apolloClient.js";

export const mutate = (
  { commit, dispatch },
  {
    resourceName,
    mutationName,
    mutation,
    params,
    successMessage,
    errorPathsHandled,
    updateResourceList,
    merge = true,
  },
  { operation, operationSuccess, operationError },
) => {
  commit(operation); // Set in-progress state, like `isLoadingResource = true`

  mutationName = mutationName || mutation.definitions[0].name.value;
  gqlClient
    .mutate({
      mutation,
      variables: params,
    })
    .then((response) => {
      const errors = get(response, `data.${mutationName}.errors`) || [];

      if (errors.length) {
        errors.forEach((error) => {
          if (
            !some(errorPathsHandled, (path) => errorMatchesPath(error, path))
          ) {
            dispatch("sendInternalMessage", { message: error.message });
          }
        });

        commit(operationError, errors);
      } else {
        const resource = get(response, `data.${mutationName}.${resourceName}`);

        commit(operationSuccess, { resource, merge });

        if (updateResourceList) {
          dispatch("updateResourceList", resource);
        }

        if (successMessage) {
          dispatch("sendInternalMessage", {
            message: successMessage,
            type: "success",
          });
        }
      }
    })
    .catch((error) => {
      const errors = extractApolloErrors(error);

      dispatch("sendInternalMessage", errors[0]);
      commit(operationError, errors);
    });
};

export const createResource = (state, mutationOptions) => {
  const operationOptions = {
    operation: types.REQUEST_CREATE_RESOURCE,
    operationSuccess: types.RECEIVE_CREATE_RESOURCE_SUCCESS,
    operationError: types.RECEIVE_CREATE_RESOURCE_ERROR,
  };

  mutate(state, mutationOptions, operationOptions);
};

export const clearCreateErrors = ({ commit }) => {
  commit(types.CLEAR_CREATE_ERRORS);
};

export const updateResource = (state, mutationOptions) => {
  const operationOptions = {
    operation: types.REQUEST_UPDATE_RESOURCE,
    operationSuccess: types.RECEIVE_UPDATE_RESOURCE_SUCCESS,
    operationError: types.RECEIVE_UPDATE_RESOURCE_ERROR,
  };

  mutate(state, mutationOptions, operationOptions);
};

export const clearUpdateErrors = ({ commit }) => {
  commit(types.CLEAR_UPDATE_ERRORS);
};

export const deleteResource = (state, mutationOptions) => {
  const operationOptions = {
    operation: types.REQUEST_DELETE_RESOURCE,
    operationSuccess: types.RECEIVE_DELETE_RESOURCE_SUCCESS,
    operationError: types.RECEIVE_DELETE_RESOURCE_ERROR,
  };

  mutate(state, mutationOptions, operationOptions);
};

export const clearDeleteErrors = ({ commit }) => {
  commit(types.CLEAR_DELETE_ERRORS);
};

export const showResource = (
  { commit },
  { query, params, resourcePath, totalCountPath, merge },
) => {
  commit(types.REQUEST_SHOW_RESOURCE, {});

  gqlClient
    .query({
      query: query,
      variables: params,
    })
    .then((response) => {
      const resource = resourcePath
        ? get(response, `data.${resourcePath}`)
        : get(response, "data");
      let resourcesTotalCount = 0;

      if (totalCountPath) {
        resourcesTotalCount = get(response, `data.${totalCountPath}`, 0);
      }

      commit(types.RECEIVE_SHOW_RESOURCE_SUCCESS, {
        resource,
        resourcesTotalCount,
        merge: merge === undefined ? true : merge,
      });
    })
    .catch((error) => {
      commit(types.RECEIVE_SHOW_RESOURCE_ERROR, extractApolloErrors(error));
    });
};

export const clearShowErrors = ({ commit }) => {
  commit(types.CLEAR_SHOW_ERRORS);
};

export const fetchResources = (
  { commit },
  {
    query,
    params,
    resourcePath,
    totalCountPath = null,
    filteredCountPath = null,
    mutationType = types.RECEIVE_FETCH_RESOURCES_SUCCESS,
  },
) => {
  commit(types.REQUEST_FETCH_RESOURCES, {});

  return gqlClient
    .query({
      query: query,
      variables: params,
    })
    .then((response) => {
      const errors = get(response, `data.${resourcePath}.errors`);

      if (errors) {
        commit(types.RECEIVE_FETCH_RESOURCES_ERROR, errors);
      } else {
        const resources = get(response, `data.${resourcePath}`);
        const resourcesTotalCount = get(response, `data.${totalCountPath}`, 0);
        const resourcesFilteredCount = get(
          response,
          `data.${filteredCountPath}`,
          0,
        );

        commit(mutationType, {
          resources,
          resourcesTotalCount,
          resourcesFilteredCount,
        });
      }
    })
    .catch((error) => {
      commit(types.RECEIVE_FETCH_RESOURCES_ERROR, extractApolloErrors(error));
    });
};

export const clearFetchErrors = ({ commit }) => {
  commit(types.CLEAR_FETCH_ERRORS);
};

export const sendInternalMessage = (
  { dispatch },
  { message, type, duration },
) => {
  dispatch(
    "internalNotifications/setMessage",
    { message, type, duration },
    { root: true },
  );
};

export const updateResourceList = ({ commit }, resourceToUpdate) => {
  commit(types.UPDATE_RESOURCE_LIST, resourceToUpdate);
};
