<template>
  <div>
    <a-row style="margin-bottom: 16px">
      <a-col :span="16">
        <b>{{ aliasLead }}:</b>
        <span v-for="(aliasHtml, index) in highlightedAliases" :key="index">
          <span v-if="index > 0">,</span>
          <HighlightContent :contentHtml="aliasHtml" />
        </span>
        <span v-if="highlightedAliases.length && nonHighlightedAliases.length">
          ,
        </span>
        <span>{{ nonHighlightedAliases.join(", ") }} </span>
        <span v-if="notDisplayedAliasesCount > 0">
          and {{ notDisplayedAliasesCount }} more
        </span>
      </a-col>
    </a-row>
    <a-row style="margin-bottom: 16px">
      <b>Description:</b>
      <HighlightContent
        v-if="isHighlighted('fields.description')"
        :contentHtml="highlights['fields.description'].join('[...]')"
      />
      <span v-else>
        {{ truncatedData(entity.description, 400) }}
      </span>
    </a-row>
    <a-row>
      <b>Note:</b>
      <HighlightContent
        v-if="isHighlighted('note.content')"
        :contentHtml="highlights['note.content'][0]"
      />

      <span v-else>
        {{ truncatedData(entity.note) }}
      </span>
    </a-row>
  </div>
</template>

<script>
import pluralize from "pluralize";
import { truncate } from "lodash";
import HighlightContent from "@/components/atoms/HighlightContent.vue";

const ALIASES_LIMIT = 5;

export default {
  components: {
    HighlightContent,
  },

  props: {
    entity: {
      type: Object,
      required: true,
    },
  },

  computed: {
    aliasLead() {
      return pluralize("Alias", this.entity.aliases.length);
    },
    highlights() {
      return this.entity.highlights || {};
    },
    highlightedProperties() {
      return Object.keys(this.highlights);
    },
    highlightedAliases() {
      let aliases = [];

      if (this.isHighlighted("friendly_id_slugs.slug")) {
        const slugsToHighlight = this.highlights[
          "friendly_id_slugs.slug"
        ].slice(0, ALIASES_LIMIT);

        aliases = aliases.concat(slugsToHighlight);
      }

      if (this.isHighlighted("aliases.value")) {
        const aliasesToHighlight = this.highlights["aliases.value"].slice(
          0,
          ALIASES_LIMIT - aliases.length,
        );

        aliases = aliases.concat(aliasesToHighlight);
      }

      return aliases;
    },
    highlightedAliasesInPlainText() {
      return this.highlightedAliases.map((aliasHtml) =>
        this.extractContent(aliasHtml),
      );
    },
    nonHighlightedAliases() {
      const aliasesNotHighlighted = this.entity.aliases.filter(
        (alias) => !this.highlightedAliasesInPlainText.includes(alias),
      );

      return aliasesNotHighlighted.slice(
        0,
        ALIASES_LIMIT - this.highlightedAliases.length,
      );
    },
    notDisplayedAliasesCount() {
      return (
        this.entity.aliases.length -
        this.highlightedAliases.length -
        this.nonHighlightedAliases.length
      );
    },
  },

  methods: {
    isHighlighted(property) {
      return this.highlightedProperties.includes(property);
    },
    extractContent(html) {
      return new DOMParser().parseFromString(html, "text/html").documentElement
        .textContent;
    },
    truncatedData(data, length = 200) {
      return data ? truncate(data, { length: length }) : "None";
    },
  },
};
</script>
