<template>
  <a-badge
    :show-zero="showZero"
    :offset="offset"
    :number-style="badgeStyle"
    :overflow-count="overflowCount"
    :count="count"
  />
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
    },
    type: {
      required: false,
      type: String,
      default: "primary",
    },
    offset: {
      required: false,
      type: Array,
      default: () => [3, -4],
    },
    overflowCount: {
      required: false,
      type: Number,
      default: 99999,
    },
  },

  computed: {
    badgeStyle() {
      switch (this.type) {
        case "primary":
          return {
            backgroundColor: "#fff",
            color: "#999",
            boxShadow: "0 0 0 1px #d9d9d9 inset",
          };
        case "error":
          // This is the default `a-badge` styling which returns a red badge
          return {
            boxShadow: "none",
          };
        case "warning":
          return { backgroundColor: "rgb(250, 173, 20)" };
        case "success":
          return { backgroundColor: "#52c41a" };
        default:
          return {
            backgroundColor: "#fff",
            color: "#999",
            boxShadow: "0 0 0 1px #d9d9d9 inset",
          };
      }
    },
    // Don't show zeros for alert types, we don't want to encourage navigation to non actionable sections.
    showZero() {
      return this.type === "primary";
    },
  },
};
</script>
