const DefaultMessageDuration = 6;
const DefaultMessageType = "error";

export default (store) => {
  store.registerModuleOnce("internalNotifications", {
    namespaced: true,
    state: {
      messages: [],
    },
    getters: {
      messages: (state) => {
        return state.messages;
      },
    },
    mutations: {
      setMessage(state, message) {
        state.messages.push(message);
      },
      clearMessages(state) {
        state.messages = [];
      },
    },
    actions: {
      setMessage(
        { commit },
        {
          message,
          type = DefaultMessageType,
          duration = DefaultMessageDuration,
        },
      ) {
        const params = {
          message,
          type,
          duration,
        };

        commit("setMessage", params);
      },
      clearMessages({ commit }) {
        commit("clearMessages");
      },
    },
  });
};
