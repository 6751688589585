<template>
  <a-tooltip
    :placement="tooltipPlacement"
    :overlayStyle="{ maxWidth: '500px' }"
  >
    <span v-if="needsTooltip" slot="title" v-text="tooltipText" />
    <span v-text="displayText" />
    <a v-if="url" target="_blank" :href="url" style="padding: 5px">
      <a-icon type="export" />
    </a>
  </a-tooltip>
</template>

<script>
import { truncateWithSeparator } from "@/shared/string_helper.js";

const DISPLAY_LENGTH_DEFAULT = 22;

export default {
  props: {
    path: {
      type: Object,
      required: true,
    },
    displayLength: {
      type: Number,
      default: DISPLAY_LENGTH_DEFAULT,
    },
    tooltipPlacement: {
      type: String,
      default: "top",
    },
  },

  computed: {
    displayText() {
      const path = truncateWithSeparator(
        this.fullPath,
        "/",
        this.displayLength,
      );

      return `/${path}`;
    },
    fullPath() {
      return this.path.path;
    },
    url() {
      return this.path.href;
    },
    tooltipText() {
      return `/${this.path.path}`;
    },
    needsTooltip() {
      return this.fullPath.length > this.displayLength;
    },
  },
};
</script>
