export const canUpdateAttribute = (permissions, attribute) => {
  // This handles the case where we can't query permissions so we allow the attribute to be updated.
  // We should get rid of this if statement once we `include HasAccessControl` on all of our GraphQL types.
  if (permissions === undefined) {
    return true;
  }

  if (!permissions?.canUpdate) {
    return false;
  }

  return (
    permissions.permittedAttributesForUpdate === null ||
    permissions.permittedAttributesForUpdate.includes(attribute)
  );
};

export const disabledMessage = (
  action = "take this action",
  suggestion = "Please contact your account administrator for help.",
) => {
  return `You do not have permission to ${action}. ${suggestion}`;
};
