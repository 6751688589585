import * as types from "./mutation_types.js";

function mergeIncomingResource(state, resource) {
  if (!state.resource) {
    return resource;
  } else if (Array.isArray(state.resource)) {
    return [...state.resource, ...Array(resource)].flat();
  } else {
    return {
      ...state.resource,
      ...resource,
    };
  }
}

export default {
  [types.REQUEST_CREATE_RESOURCE](state) {
    state.isCreatingResource = true;
    state.errorsCreatingResource = [];
  },
  [types.RECEIVE_CREATE_RESOURCE_SUCCESS](state, { resource }) {
    state.isCreatingResource = false;
    state.createdResource = resource;
    state.resource = resource;
  },
  [types.RECEIVE_CREATE_RESOURCE_ERROR](state, errors) {
    state.isCreatingResource = false;
    state.errorsCreatingResource = errors;
  },
  [types.CLEAR_CREATE_ERRORS](state) {
    state.errorsCreatingResource = [];
  },
  [types.REQUEST_UPDATE_RESOURCE](state) {
    state.isUpdatingResource = true;
    state.errorsUpdatingResource = [];
  },
  [types.RECEIVE_UPDATE_RESOURCE_SUCCESS](state, { resource, merge }) {
    state.isUpdatingResource = false;
    state.updatedResource = resource;
    state.resource = merge ? mergeIncomingResource(state, resource) : resource;
  },
  [types.RECEIVE_UPDATE_RESOURCE_ERROR](state, errors) {
    state.isUpdatingResource = false;
    state.errorsUpdatingResource = errors;
  },
  [types.CLEAR_UPDATE_ERRORS](state) {
    state.errorsUpdatingResource = [];
  },
  [types.REQUEST_DELETE_RESOURCE](state) {
    state.isDeletingResource = true;
    state.errorsDeletingResource = [];
  },
  [types.RECEIVE_DELETE_RESOURCE_SUCCESS](state) {
    state.isDeletingResource = false;
    state.resource = null;
  },
  [types.RECEIVE_DELETE_RESOURCE_ERROR](state, errors) {
    state.isDeletingResource = false;
    state.errorsDeletingResource = errors;
  },
  [types.CLEAR_DELETE_ERRORS](state) {
    state.errorsDeletingResource = [];
  },
  [types.REQUEST_SHOW_RESOURCE](state) {
    state.isLoadingResource = true;
  },
  [types.RECEIVE_SHOW_RESOURCE_SUCCESS](
    state,
    { resource, resourcesTotalCount, merge },
  ) {
    state.isLoadingResource = false;
    state.resource = merge ? mergeIncomingResource(state, resource) : resource;
    state.resourcesTotalCount = resourcesTotalCount;
  },
  [types.RECEIVE_SHOW_RESOURCE_ERROR](state, errors) {
    state.isLoadingResource = false;
    state.errorsLoadingResource = errors;
  },
  [types.CLEAR_SHOW_ERRORS](state) {
    state.errorsLoadingResource = [];
  },
  [types.REQUEST_FETCH_RESOURCES](state) {
    state.isLoadingResources = true;
    state.errorsLoadingResources = [];
  },
  [types.RECEIVE_FETCH_RESOURCES_SUCCESS](
    state,
    { resources, resourcesTotalCount, resourcesFilteredCount },
  ) {
    state.isLoadingResources = false;
    state.resources = resources;
    state.resourcesTotalCount = resourcesTotalCount;
    state.resourcesFilteredCount = resourcesFilteredCount;
  },
  [types.RECEIVE_FETCH_RESOURCES_ERROR](state, errors) {
    state.isLoadingResources = false;
    state.errorsLoadingResources = errors;
  },
  [types.CLEAR_FETCH_ERRORS](state) {
    state.errorsLoadingResources = [];
  },
  [types.UPDATE_RESOURCE_LIST](state, updatedResource) {
    const oldResource = state.resources.find(
      (resource) => resource.id === updatedResource.id,
    );

    Object.assign(oldResource, updatedResource);
  },
};
