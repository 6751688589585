import * as types from "./mutation_types.js";
import { findIndex, unionBy, get } from "lodash";

export default {
  [types.REQUEST_FETCH_SUGGESTIONS](state) {
    state.isFetchingSuggestions = true;
  },
  [types.RECEIVE_FETCH_SUGGESTIONS](
    state,
    {
      suggestions,
      suggestionCount,
      filteredCount,
      hasDeploys,
      hasDeployIntegration,
    },
  ) {
    state.isFetchingSuggestions = false;
    state.suggestions = suggestions.map((s) => ({ ...s, validInput: true }));
    state.totalSuggestions = unionBy(
      suggestions.map((s) => ({ ...s, validInput: true })),
      state.totalSuggestions,
      "id",
    );
    state.totalSuggestionsCount = suggestionCount;
    state.filteredSuggestionsCount = filteredCount;
    state.pagination.updatedCount = filteredCount;
    state.errorsFetchingSuggestions = [];
    state.hasDeploys = hasDeploys;
    state.hasDeployIntegration = hasDeployIntegration;
  },
  [types.MERGE_SUGGESTIONS](
    state,
    {
      suggestions,
      suggestionCount,
      filteredCount,
      hasDeploys,
      hasDeployIntegration,
    },
  ) {
    state.isFetchingSuggestions = false;
    state.suggestions = unionBy(
      state.suggestions,
      suggestions.map((s) => ({ ...s, validInput: true })),
      "id",
    );
    state.totalSuggestions = unionBy(
      suggestions.map((s) => ({ ...s, validInput: true })),
      state.totalSuggestions,
      "id",
    );
    state.totalSuggestionsCount = suggestionCount;
    state.filteredSuggestionsCount = filteredCount;
    state.pagination.updatedCount = filteredCount;
    state.errorsFetchingSuggestions = [];
    state.hasDeploys = hasDeploys;
    state.hasDeployIntegration = hasDeployIntegration;
  },
  [types.RECEIVE_FETCH_SUGGESTIONS_ERROR](state, errors) {
    state.isFetchingSuggestions = false;
    state.errorsFetchingSuggestions = errors;
  },
  [types.REQUEST_FETCH_SUGGESTIONS_COUNT](state) {
    state.isFetchingSuggestions = true;
  },
  [types.RECEIVE_FETCH_SUGGESTIONS_COUNT](state, suggestionCount) {
    state.isFetchingSuggestionsCount = false;
    state.totalSuggestionsCount = suggestionCount;
    state.errorsFetchingSuggestionsCount = [];
  },
  [types.RECEIVE_FETCH_SUGGESTIONS_COUNT_ERROR](state, errors) {
    state.isFetchingSuggestions = false;
    state.errorsFetchingSuggestions = errors;
  },
  [types.SET_SUGGESTION_COUNT](state, count) {
    state.totalSuggestionsCount = count;
  },
  [types.UPDATE_TABLE_DATA](state, data) {
    Object.assign(state, data);
  },
  [types.UPDATE_SUGGESTIONS](state, suggestions) {
    suggestions.forEach((suggestion) => {
      const index = findIndex(state.suggestions, { id: suggestion.id });

      state.suggestions.splice(index, 1);
    });
    state.isUpdatingSuggestion = false;
  },
  //
  //  Suggestion Update Ignored Value
  //
  [types.REQUEST_SET_SUGGESTION_IGNORED_VALUE](state, ignored) {
    state.updatedIgnoreValue = ignored;
    state.isUpdatingSuggestion = true;
    state.updateIgnoreValueSuccess = false;
  },
  [types.RECEIVE_UPDATE_SUGGESTION_IGNORED_VALUE_SUCCESS](
    state,
    { suggestions, notIgnored },
  ) {
    state.notIgnoredSuggestions = notIgnored;
    state.updateIgnoreValueSuccess = true;
    state.errorsSettingIgnoredStatus = [];
    state.pagination.updatedCount -= suggestions.length;
  },
  [types.RECEIVE_UPDATE_SUGGESTION_IGNORED_VALUE_ERROR](state, errors) {
    state.errorsSettingIgnoredStatus = errors;
    state.updateIgnoreValueSuccess = false;
    state.suggestion = null;
    state.isUpdatingSuggestion = false;
  },
  [types.ACTION_SUGGESTIONS](state) {
    state.isActioningSuggestions = true;
    state.actionSuggestionsErrors = [];
  },
  [types.RECEIVE_ACTION_SUGGESTIONS_RESULTS](
    state,
    { actioned, notActioned, errors },
  ) {
    state.isActioningSuggestions = false;
    state.actionedResults = { actioned, notActioned };
    state.actionSuggestionErrors = errors;
    state.pagination.updatedCount -= actioned.length;
  },
  [types.RECEIVE_ACTION_SUGGESTIONS_ERROR](state, errors) {
    state.isActioningSuggestions = false;
    state.actionSuggestionErrors = errors;
  },
  [types.UPDATE_SUGGESTION](state, { sug, params }) {
    const actions = state.suggestionActions;
    let action = get(actions, sug.id, {});

    action = {
      action: { ...get(action, "action", {}), ...params },
    };
    action["validInput"] = sug.validInput;
    actions[sug.id] = action;
    state.suggestionActions = Object.assign(
      {},
      state.suggestionActions,
      actions,
    );
  },
  //
  //  For SERVICE_DETECTION_INTEGRATION_TYPES query
  //
  [types.REQUEST_FETCH_SUGGESTION_INTEGRATION_TYPES](state) {
    state.isFetchingSuggestionSourceIntegrationTypes = true;
  },
  [types.RECEIVE_FETCH_SUGGESTION_INTEGRATION_TYPES](
    state,
    { suggestionSourceIntegrationTypes },
  ) {
    state.isFetchingSuggestionSourceIntegrationTypes = false;
    state.suggestionSourceIntegrationTypes = suggestionSourceIntegrationTypes;
    state.errorsFetchingSuggestionSourceIntegrationTypes = [];
  },
  [types.RECEIVE_FETCH_SUGGESTION_INTEGRATION_TYPES_ERROR](state, errors) {
    state.isFetchingSuggestionSourceIntegrationTypes = false;
    state.errorsFetchingSuggestionSourceIntegrationTypes = errors;
  },
  // for SUGGESTIONS_INTEGRATIONS_QUERY
  [types.REQUEST_FETCH_SUGGESTION_INTEGRATIONS](state) {
    state.isFetchingSuggestionSourceIntegrations = true;
  },
  [types.RECEIVE_FETCH_SUGGESTION_INTEGRATIONS](
    state,
    { suggestionSourceIntegrations },
  ) {
    state.isFetchingSuggestionSourceIntegrations = false;
    state.suggestionSourceIntegrations = suggestionSourceIntegrations;
    state.errorsFetchingSuggestionSourceIntegrations = [];
  },
  [types.RECEIVE_FETCH_SUGGESTION_INTEGRATIONS_ERROR](state, errors) {
    state.isFetchingSuggestionSourceIntegrations = false;
    state.errorsFetchingSuggestionSourceIntegrations = errors;
  },
  [types.DETACH_SUGGESTION_ALIAS](state) {
    state.isDetachingSuggestionAlias = true;
  },
  [types.DETACH_SUGGESTION_ALIAS_SUCCESS](state) {
    state.isDetachingSuggestionAlias = false;
    state.errorsDetachingSuggestionAlias = [];
  },
  [types.DETACH_SUGGESTION_ALIAS_ERROR](state, errors) {
    state.isDetachingSuggestionAlias = false;
    state.errorsDetachingSuggestionAlias = errors;
  },
  [types.REQUEST_UPDATE_SUGGESTION](state) {
    state.isUpdatingSuggestion = true;
  },
  [types.RECEIVE_UPDATE_SUGGESTION_SUCCESS](state) {
    state.isUpdatingSuggestion = false;
  },
  [types.RECEIVE_UPDATE_SUGGESTION_ERROR](state, errors) {
    state.isUpdatingSuggestion = false;
    state.errorsUpdatingSuggestion = errors;
  },
};
