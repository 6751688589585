const STATUSES = {
  no_data: {
    style: {
      color: "white",
      "border-color": "#F5F5F5",
      "background-color": "#BFBFBF",
    },
    text: "No Data",
  },
  fetching_data: {
    style: {
      color: "white",
      "border-color": "#F5F5F5",
      "background-color": "#BFBFBF",
    },
    text: "Loading…",
  },
  ok: {
    style: {
      color: "white",
      "border-color": "#B7EB8F",
      "background-color": "#52C41A",
    },
    text: "OK",
  },
  warn: {
    style: {
      color: "white",
      "border-color": "#FFD591",
      "background-color": "#FA8C16",
    },
    text: "Warning",
  },
  alert: {
    style: {
      color: "white",
      "border-color": "#FFA39E",
      "background-color": "#F5222D",
    },
    text: "Alert",
  },
};

export const statusStyle = (status) => {
  if (status in STATUSES) {
    return STATUSES[status].style;
  }

  return STATUSES["no_data"].style;
};

export const statusText = (status) => {
  if (status in STATUSES) {
    return STATUSES[status].text;
  }

  return STATUSES["no_data"].text;
};
