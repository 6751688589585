import * as types from "./mutation_types.js";

export default {
  [types.REQUEST_SEARCH_RESULTS](state) {
    state.isFetchingSearchResults = true;
    state.errorsFetchingSearchResults = [];
  },
  [types.RECEIVE_SEARCH_RESULTS_SUCCESS](
    state,
    { searchResults, filteredCount, totalLevelCount = null },
  ) {
    state.isFetchingSearchResults = false;
    state.totalLevelCount = totalLevelCount;
    state.searchResults = searchResults;
    state.filteredCount = filteredCount;
  },
  [types.RECEIVE_SEARCH_RESULTS_ERROR](state, error) {
    state.errorsFetchingSearchResults = error;
    state.isFetchingSearchResults = false;
  },
  [types.REQUEST_TECH_DOC_ATTACHED_RESOURCES](state, { documentId }) {
    const stateDocIndex = state.searchResults.findIndex(
      (document) => document.id === documentId,
    );
    const documentFromState = { ...state.searchResults[stateDocIndex] };

    documentFromState.isLoadingResources = true;

    state.searchResults.splice(stateDocIndex, 1, documentFromState);
  },
  [types.RECEIVE_TECH_DOC_ATTACHED_RESOURCES_SUCCESS](
    state,
    { documentWithResources },
  ) {
    const edges = documentWithResources.attachedResources.edges;
    const newResults = edges.map((edge) => edge.node);

    const stateDocIndex = state.searchResults.findIndex(
      (result) => result.id === documentWithResources.id,
    );
    const documentFromState = { ...state.searchResults[stateDocIndex] };

    let existingResults = [];

    if (documentFromState.attachedResources) {
      existingResults = [...documentFromState.attachedResources.nodes];
    } else {
      documentFromState.attachedResources = {};
    }

    const lastResult = edges[edges.length - 1];

    documentFromState.attachedResources.nodes =
      existingResults.concat(newResults);
    documentFromState.attachedResources.cursor = lastResult.cursor;
    documentFromState.isLoadingResources = false;

    state.searchResults.splice(stateDocIndex, 1, documentFromState);
  },
  [types.RECEIVE_TECH_DOC_ATTACHED_RESOURCES_ERROR](
    state,
    { error, documentId },
  ) {
    state.errorsFetchingSearchResults = error;

    const stateDocIndex = state.searchResults.findIndex(
      (document) => document.id === documentId,
    );
    const documentFromState = { ...state.searchResults[stateDocIndex] };

    documentFromState.isLoadingResources = false;

    state.searchResults.splice(stateDocIndex, 1, documentFromState);
  },
  [types.RESET_SEARCH_RESULTS](state) {
    state.searchResults = [];
  },
  [types.SET_CURRENT_SEARCH_TAB](state, searchTab) {
    state.currentSearchTab = searchTab;
  },
  [types.UPDATE_TABLE_DATA](state, data) {
    Object.assign(state, data);
  },
};
