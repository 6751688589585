<template>
  <span v-if="!icon" :class="{ [`color-${color}`]: true }"></span>
  <mark v-else-if="highlightIcon" :class="{ [`color-${color}`]: true }">
    <component :is="icon" />
  </mark>
  <component :is="icon" v-else :class="{ [`color-${color}`]: true }" />
</template>

<script>
import * as icons from "@/shared/icons.js";
import { getIconViaFuzzyMatching } from "@/shared/iconHelper.js";

export default {
  props: {
    type: {
      type: String,
      required: true,
      default: "",
    },
    domain: {
      type: String,
      required: false,
      default: "",
    },
    highlightIcon: {
      type: Boolean,
      default: false,
    },
    showDefault: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "default",
      validator: (color) => {
        return ["default", "white", "blue", "grey", "black"].includes(color);
      },
    },
  },

  computed: {
    icon: function () {
      // NOTE: we may need to improve how we find icons based on url
      // There are urls that have the identification for the icon in the domain
      // while others are in the subdomain
      let icon = getIconViaFuzzyMatching(this.type);

      if (!icon && this.hostname) {
        icon = this.getOverrideByHost();
      }

      const cleanHostname = this.nameCleanup(this.hostname);

      if (!icon && cleanHostname != null) {
        const matchingKey = Object.keys(icons).find((iconKey) => {
          return cleanHostname.includes(
            iconKey.toLowerCase().replace("icon", ""),
          );
        });

        icon = icons[matchingKey];
      }

      if (icon) {
        return icon;
      }

      if (this.showDefault) {
        return icons["DesktopIcon"];
      }

      return null;
    },
    hostname: function () {
      try {
        return new URL(this.domain).hostname;
      } catch {
        return null;
      }
    },
  },

  methods: {
    nameCleanup(name) {
      if (name === null) {
        return null;
      }

      return name.replace(/[\s.\-_]/g, "");
    },
    getOverrideByHost() {
      const { IconOverrides } = icons;
      const cleanHostname = this.nameCleanup(this.hostname);
      const overrideKey = Object.keys(IconOverrides).find((override) => {
        if (cleanHostname.includes(override)) {
          return IconOverrides[override];
        }
      });

      return icons[IconOverrides[overrideKey]];
    },
  },
};
</script>

<style scoped>
mark {
  background-color: #ffe58f;
}

.color-default {
  color: inherit;
}

.color-white {
  color: white;
  filter: grayscale(100%) brightness(100);
}

.color-grey {
  filter: grayscale(100%);
}

.color-blue {
  color: #1890ff;
  filter: grayscale(100%) invert(56%) sepia(55%) saturate(5906%)
    hue-rotate(190deg) brightness(100%) contrast(102%);
}

.color-black {
  color: black;
  filter: grayscale(100%) brightness(0.5);
}
</style>
