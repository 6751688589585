<template>
  <span v-if="date">
    {{ formatDate(date) }}
  </span>
</template>

<script>
import {
  dateTimeWithoutWeekday,
  dateWithoutWeekday,
} from "@/shared/helpers.js";

export default {
  props: {
    date: {
      type: String,
      required: false,
      default: null,
    },
  },

  methods: {
    formatDate(date) {
      // best effort attempt to hide the inferred `12:00 AM` from strings that don't include a time portion
      // this should work for ISO8601 dates, but may not work for other date formats we support
      if (date.includes("T") || date.includes(" ")) {
        return dateTimeWithoutWeekday(date);
      } else {
        return dateWithoutWeekday(date);
      }
    },
  },
};
</script>
