export const UPDATE_TABLE_DATA = "UPDATE_TABLE_DATA";

export const REQUEST_TEAM = "REQUEST_TEAM";
export const RECEIVE_TEAM_SUCCESS = "RECEIVE_TEAM_SUCCESS";
export const RECEIVE_TEAM_ERROR = "RECEIVE_TEAM_ERROR";

export const REQUEST_TEAM_CAMPAIGN_PERFORMANCE =
  "REQUEST_TEAM_CAMPAIGN_PERFORMANCE";
export const RECEIVE_TEAM_CAMPAIGN_PERFORMANCE_SUCCESS =
  "RECEIVE_TEAM_CAMPAIGN_PERFORMANCE_SUCCESS";
export const RECEIVE_TEAM_CAMPAIGN_PERFORMANCE_ERROR =
  "RECEIVE_TEAM_CAMPAIGN_PERFORMANCE_ERROR";

export const REQUEST_TEAMS = "REQUEST_TEAMS";
export const RECEIVE_TEAMS_SUCCESS = "RECEIVE_TEAMS_SUCCESS";
export const RECEIVE_TEAMS_ERROR = "RECEIVE_TEAMS_ERROR";

export const REQUEST_UPDATE_TEAM = "REQUEST_UPDATE_TEAM";
export const RECEIVE_UPDATE_TEAM_SUCCESS = "RECEIVE_UPDATE_TEAM_SUCCESS";
export const RECEIVE_UPDATE_TEAM_ERROR = "RECEIVE_UPDATE_TEAM_ERROR";

export const REQUEST_CREATE_TEAM = "REQUEST_CREATE_TEAM";
export const RECEIVE_CREATE_TEAM_SUCCESS = "RECEIVE_CREATE_TEAM_SUCCESS";
export const RECEIVE_CREATE_TEAM_ERROR = "RECEIVE_CREATE_TEAM_ERROR";

export const REQUEST_DELETE_TEAM = "REQUEST_DELETE_TEAM";
export const RECEIVE_DELETE_TEAM_SUCCESS = "RECEIVE_DELETE_TEAM_SUCCESS";
export const RECEIVE_DELETE_TEAM_ERROR = "RECEIVE_DELETE_TEAM_ERROR";

export const REQUEST_ADD_TEAM_CONTACT = "REQUEST_ADD_TEAM_CONTACT";
export const RECEIVE_ADD_TEAM_CONTACT_SUCCESS =
  "RECEIVE_ADD_TEAM_CONTACT_SUCCESS";
export const RECEIVE_ADD_TEAM_CONTACT_ERROR = "RECEIVE_ADD_TEAM_CONTACT_ERROR";

export const REQUEST_DELETE_TEAM_CONTACT = "REQUEST_DELETE_TEAM_CONTACT";
export const RECEIVE_DELETE_TEAM_CONTACT_SUCCESS =
  "RECEIVE_DELETE_TEAM_CONTACT_SUCCESS";
export const RECEIVE_DELETE_TEAM_CONTACT_ERROR =
  "RECEIVE_DELETE_TEAM_CONTACT_ERROR";

export const REQUEST_SET_DEFAULT_TEAM_CONTACT =
  "REQUEST_SET_DEFAULT_TEAM_CONTACT";
export const RECEIVE_SET_DEFAULT_TEAM_CONTACT_SUCCESS =
  "RECEIVE_SET_DEFAULT_TEAM_CONTACT_SUCCESS";
export const RECEIVE_SET_DEFAULT_TEAM_CONTACT_ERROR =
  "RECEIVE_SET_DEFAULT_TEAM_CONTACT_ERROR";

export const REQUEST_TEAMS_TREE = "RECEIVE_TEAMS_TREE";
export const RECEIVE_TEAMS_TREE_SUCCESS = "RECEIVE_TEAMS_TREE_SUCCESS";
export const RECEIVE_TEAMS_TREE_ERROR = "RECEIVE_TEAMS_TREE_ERROR";
