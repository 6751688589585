import { set, merge, countBy, pickBy, filter } from "lodash";

export const parseUrlParamsToObject = (url) => {
  const urlParams = new URLSearchParams(url);

  const urlParamsKeys = prepareUrlParamKeys([...urlParams.keys()]);

  const urlParamsValues = [...urlParams.values()];

  const result = urlParamsKeys.reduce((acc, key, index) => {
    const value = urlParamsValues[index];
    const aggregatedValue = set({}, key, convertValueFromString(value));

    return merge(acc, aggregatedValue);
  }, {});

  return result;
};

export const parseObjectToUrlParams = (obj) => {
  const objectArray = Object.entries(obj);

  const params = objectArray
    .map(([key, value]) => {
      return toUrl(key, value);
    })
    .filter(Boolean);

  return params.length ? `?${params.join("&")}` : "";
};

export const parsePredicateToUrlParams = ({ filters }) => {
  if (filters && filters.predicates) {
    const urlFilters = filters.predicates
      .map((currFilter, index) => {
        return filter(Object.entries(currFilter), function (keyValue) {
          return [
            "key",
            "type_string",
            "arg",
            "type",
            "caseSensitive",
          ].includes(keyValue[0]);
        })
          .map((keyValue) => {
            const displayKey =
              keyValue[0] == "type_string" ? "type" : keyValue[0];
            let value = keyValue[1];

            if (
              (currFilter["key"] == "tags" ||
                currFilter["key"] == "properties") &&
              keyValue[0] == "key"
            ) {
              value = `${keyValue[1]}.${currFilter["secondary_key"]}`;
            }

            return `filter[${index}][${displayKey}]=${encodeURIComponent(
              value,
            )}`;
          })
          .join("&");
      })
      .join("&");

    if (filters.connective) {
      return `${urlFilters}&connective=${filters.connective}`;
    } else {
      return `${urlFilters}`;
    }
  } else {
    return null;
  }
};

const toUrl = (key, value) => {
  if (value instanceof Array) {
    return Array.from(value, (v, index) => toUrl(`${key}[${index}]`, v)).join(
      "&",
    );
  } else if (value instanceof Object) {
    const withoutEmptyValues = Object.keys(value).filter(
      (valueKey) => !isEmpty(value[valueKey]),
    );

    return Array.from(withoutEmptyValues, (k) =>
      toUrl(`${key}[${k}]`, value[k]),
    ).join("&");
  } else {
    return `${key}=${convertValueToString(value)}`;
  }
};

const prepareUrlParamKeys = (urlParamsKeys) => {
  // count repeated keys
  const countByRepeatedKeys = pickBy(countBy(urlParamsKeys), (k) => k > 1);

  // enumerate duplicated keys, e.g. having two keys named "a" it will return "a[0]" and "a[1]"
  return urlParamsKeys.map((key) => {
    const repeatedKeys = Object.keys(countByRepeatedKeys);

    return repeatedKeys.includes(key)
      ? `${key}[${--countByRepeatedKeys[key]}]`
      : key;
  });
};

const isEmpty = (value) => {
  if (value instanceof Array || value instanceof String) {
    return !value.length;
  } else if (value instanceof Object) {
    return !Object.keys(value).length;
  }

  return false;
};

const convertValueFromString = (value) => {
  switch (value) {
    case "":
      return null;
    case "is:null":
      return null;
    case "true":
      return true;
    case "false":
      return false;
    default:
      return value;
  }
};

const convertValueToString = (value) => {
  return value !== null ? value : "is:null";
};
