import { gql } from "@apollo/client";

export const LEVEL_INDEX_QUERY = gql`
  query levels {
    account {
      rubric {
        levels {
          nodes {
            id
            name
            description
            index
          }
        }
      }
    }
  }
`;

export const UPDATE_LEVEL_MUTATION = gql`
  mutation levelUpdate($id: ID!, $name: String, $description: String) {
    levelUpdate(input: { id: $id, name: $name, description: $description }) {
      level {
        id
        name
        description
        index
      }
      errors {
        message
        path
      }
    }
  }
`;

export const CREATE_LEVEL_MUTATION = gql`
  mutation levelCreate($name: String!, $description: String) {
    levelCreate(input: { name: $name, description: $description }) {
      level {
        id
        name
        description
        index
      }
      errors {
        message
        path
      }
    }
  }
`;

export const DELETE_LEVEL_MUTATION = gql`
  mutation levelDelete($id: ID!) {
    levelDelete(input: { id: $id }) {
      deletedLevelId
      errors {
        message
        path
      }
    }
  }
`;
