<template>
  <a-tooltip>
    <template slot="title">
      {{ text }}
    </template>
    <a-icon
      theme="filled"
      class="icon-background-circle"
      :style="style"
      :type="icon"
    />
  </a-tooltip>
</template>

<script>
import { statusStyle, statusText } from "@/shared/health_style_helper.js";

const icons = {
  alert: "exclamation-circle",
  warn: "warning",
  ok: "check-circle",
  no_data: "minus-circle",
};

const iconBackground = {
  alert: "rgba(245, 34, 45, 0.1)",
  warn: "rgba(250, 140, 22, 0.1)",
  ok: "rgba(82, 196, 26, 0.1)",
  no_data: "rgba(191, 191, 191, 0.2)",
};

export default {
  props: {
    status: {
      required: false,
      type: String,
      default: "no_data",
    },
  },

  data() {
    return {
      icons,
      iconBackground,
    };
  },

  computed: {
    icon() {
      return icons[this.status];
    },
    text() {
      let message = "";

      if (statusText(this.status) === "OK") {
        message =
          "This service's alert sources are doing OK. Click to find out more";
      } else if (statusText(this.status) === "Warning") {
        message =
          "There is a warning for the overall status of this service's alert sources. Click to find out more.";
      }

      return message;
    },
    style() {
      const iconColor = statusStyle(this.status)["background-color"];
      const backgroundColor = iconBackground[this.status];

      return {
        color: iconColor,
        background: backgroundColor,
        fontSize: "1em",
      };
    },
  },
};
</script>

<style scoped>
.icon-background-circle {
  border-radius: 50%;
  padding: 10px;
}
</style>
