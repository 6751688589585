<template>
  <div v-hotkey="keymap">
    <DismissibleBanner
      v-if="isSpoofing"
      type="error"
      :square="true"
      :showIcon="false"
      :expiryReference="spoofBannerExpiryReference"
      :ttl="spoofBannerTTL"
      :useSessionStorage="true"
      messageOnClose="Spoofing banner on this account will be closed for 15 minutes."
      style="
        text-align: center;
        position: sticky;
        z-index: 999;
        width: 100%;
        top: 0;
      "
    >
      <template slot="description">
        <b>Warning!</b> You are spoofing a real user on account
        <b>{{ currentUser.user.account.name }}</b
        >. Please be careful!
        <a-button style="margin-left: 10px" @click="onSpoofCancelClicked">
          Stop Spoofing
        </a-button>
      </template>
    </DismissibleBanner>
    <div class="sidebar-splash-text">
      {{ sidebarSplashText }}
    </div>
    <a-layout id="main-layout" class="top-layout">
      <a-layout-sider
        v-model="collapsed"
        :trigger="null"
        collapsible
        breakpoint="lg"
        :style="{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          zIndex: 1000,
        }"
      >
        <a href="/">
          <div class="logo">
            <img :src="logoPath" alt="OpsLevel" />
          </div>
        </a>
        <div
          v-if="featureFlags.includes('task_drawer')"
          class="sidebar-task-button"
        >
          <a-button
            class="task-button"
            type="primary"
            @click="handleOpenTaskDrawer"
          >
            <span
              :class="{
                'task-button-content': true,
                'sider-collapsed': collapsed,
              }"
            >
              <span style="margin-left: 4px">
                <OpsIcon type="ai_sparkle" />
                <span v-if="!collapsed" style="padding-left: 10px"
                  >Detected Services</span
                >
              </span>
              <span style="padding-left: 5px; padding-right: 4px">{{
                taskCount > 10 ? "10+" : taskCount
              }}</span>
            </span>
          </a-button>
        </div>
        <a-menu
          id="app-menu"
          theme="dark"
          mode="vertical"
          :selectedKeys="activeMenus"
        >
          <template v-for="item in menuItems">
            <a-menu-item v-if="!item.submenus" :key="item.key">
              <MenuItemLink :item="item" />
            </a-menu-item>
            <a-sub-menu
              v-else
              :key="item.key"
              :class="subMenuClass(item)"
              :data-bento-nav-item="item.url"
              popupClassName="subMenu"
            >
              <span slot="title" class="submenu-title">
                <MenuItemLink
                  :item="item"
                  style="color: rgba(255, 255, 255, 0.65)"
                />
              </span>
              <a-menu-item
                v-for="submenu in item.submenus"
                :key="submenu.key"
                :class="submenu.itemClass"
              >
                <MenuItemLink :item="submenu" />
              </a-menu-item>
            </a-sub-menu>
          </template>
        </a-menu>
        <div class="free-trial-sidebar-cta">
          <a-button
            v-if="showSideBarCta"
            class="sidebar-cta-button"
            type="primary"
            @click="handleSideBarCtaClicked"
          >
            <a
              :href="demoLink"
              target="_blank"
              :class="{
                'sidebar-cta-button-content': true,
                'sider-collapsed': collapsed,
              }"
            >
              <img src="/free-trial-graeham-icon.png" alt="Schedule Demo" />
              <span v-if="!collapsed" style="padding-left: 5px"
                >Schedule Demo</span
              >
            </a>
          </a-button>
        </div>
      </a-layout-sider>
      <a-layout
        id="main-content"
        :class="{ 'main-layout-content': true, 'sider-collapsed': collapsed }"
      >
        <PlanExpiryBanner
          v-if="showPlanExpiryBanner"
          :daysLeft="planExpiryDetails.daysLeft"
          :planName="planExpiryDetails.paymentPlan"
          :pricingUrl="urls.account_pricing"
        />
        <a-layout-header style="" class="global-header">
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
          <div class="right">
            <LongRunningTaskDropdown v-if="showLongRunningTasks" />
            <a-tooltip :title="`Quick Help Menu`">
              <a-icon
                type="question-circle"
                :style="docsIconStyle"
                @click="showHelp()"
              />
            </a-tooltip>
            <a-icon
              v-if="beamerEnabled"
              type="notification"
              style="padding: 12px; margin-right: 12px; font-size: 1.2em"
              class="beamerTrigger"
            />
            <slot name="search-bar" />
            <a-dropdown class="action" :trigger="['click']">
              <div style="display: flex; align-items: center; float: right">
                <UserWithAvatar
                  :userName="currentUser.user.name"
                  :gravatarHref="currentUser.user.gravatar_src"
                />
                <a-icon
                  v-if="hasProfileLinks"
                  type="down"
                  style="padding-left: 3px; font-size: 14px"
                />
              </div>
              <a-menu v-if="hasProfileLinks" slot="overlay" :selectable="false">
                <template v-if="profileLinks.length > 1">
                  <a-menu-item
                    v-for="(item, index) in filteredProfileLinks"
                    :key="index + 1"
                  >
                    <MenuItemLink :item="item" />
                  </a-menu-item>
                  <a-menu-divider />
                </template>
                <a-menu-item :key="profileLinks.length">
                  <MenuItemLink :item="profileLinks[profileLinks.length - 1]" />
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </a-layout-header>

        <slot name="flash" />

        <a-layout-content class="global-content">
          <slot name="content">
            <div></div>
          </slot>
        </a-layout-content>

        <JoinTeamsModal
          v-if="displayTeamsCta"
          :current-user="currentUser"
          :newTeamsPath="urls.teams.new"
        />

        <TaskDrawer
          ref="taskDrawer"
          :teamIds="currentUsersTeamIds"
          :count="taskCount"
        />

        <div class="expando" />
        <CopyrightFooter />
      </a-layout>
    </a-layout>
    <a-modal v-model="planExpiredModalVisible" :footer="null">
      <CallToAction
        buttonText="Contact Us"
        type="primary"
        :link="mailToSupport"
      >
        <img
          src="/status_images/pilot_timer.svg"
          style="height: 200px; width: 200px; padding-bottom: 10px"
          class="call-to-action-image"
        />
        <h3>Your {{ paymentPlan }}</h3>
        <p style="margin-bottom: 0px">
          To continue using OpsLevel, please contact your account manager or
          <a :href="mailToSupport">{{ supportAddress }}</a
          >.
        </p>
      </CallToAction>
    </a-modal>
    <HelpModal ref="helpModal" />
  </div>
</template>

<script>
import MenuItemLink from "@/components/MenuItemLink.vue";
import CopyrightFooter from "./CopyrightFooter.vue";
import JoinTeamsModal from "@/components/JoinTeamsModal.vue";
import DismissibleBanner from "@/components/molecules/DismissibleBanner.vue";
import CallToAction from "@/components/CallToAction.vue";
import UserWithAvatar from "@/components/atoms/UserWithAvatar.vue";
import { internalNotifications } from "@/mixins/internalNotifications.js";
import { errors } from "@/mixins/errors.js";
import HelpModal from "@/components/molecules/HelpModal.vue";
import PlanExpiryBanner from "@/components/molecules/PlanExpiryBanner.vue";
import LongRunningTaskDropdown from "@/components/organisms/LongRunningTaskDropdown.vue";
import TaskDrawer from "@/tasks/TaskDrawer.vue";
import axios from "@/lib/axios.js";
import { isArray } from "lodash";

import store from "@/store/index.js";
import application from "@/modules/application/index.js";
import members from "@/modules/teams/members/index.js";
import teams from "@/modules/teams/index.js";
import integrations from "@/modules/integrations/index.js";
import tracking from "@/modules/tracking/index.js";
import { mapActions, mapState } from "vuex";
import { parseUrlParamsToObject } from "@/shared/url_parser.js";
import { PaymentPlans } from "@/shared/payment_plans_helper.js";
import { getSidebarBackgroundColor } from "@/shared/helpers.js";
import {
  afterBentoLoad,
  drawAttentionToBentoSidebar,
  lowerZIndex,
} from "@/shared/bento_helper.js";
import { DEMO_LINK } from "@/shared/links.js";
import OpsIcon from "@/components/atoms/OpsIcon.vue";

const NavCollapsedKey = "main-nav-collapsed";
const SpoofBannerTTL = 900; // 15 minutes in seconds
const SupportAddress = "support@opslevel.com";
const MailToSupport = `mailto:${SupportAddress}?subject=Account%20Expired`;
const PlanExpiryWarningThreshold = 21;
const onboardingErrorCodes = [401, 422];

import * as Routes from "@/routes.js";
import mermaid from "mermaid";

function initCollapsed() {
  const collapsed = localStorage.getItem(NavCollapsedKey);

  return collapsed ? collapsed === "true" : false;
}

export default {
  components: {
    MenuItemLink,
    CopyrightFooter,
    JoinTeamsModal,
    DismissibleBanner,
    CallToAction,
    UserWithAvatar,
    HelpModal,
    PlanExpiryBanner,
    LongRunningTaskDropdown,
    TaskDrawer,
    OpsIcon,
  },

  mixins: [internalNotifications, errors],

  provide() {
    return {
      currentUser: this.currentUser,
      featureFlags: this.featureFlags,
      systemToggles: this.systemToggles,
      showIntegrateGitBanner: this.showIntegrateGitBanner,
      routes: this.routes,
    };
  },

  props: {
    menuItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    profileLinks: {
      type: Array,
      required: false,
      default: () => [],
    },
    currentUser: {
      type: Object,
      required: true,
    },
    selectedMenuItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    displayTeamsCta: {
      type: Boolean,
      default: true,
    },
    urls: {
      type: Object,
      default: () => {},
    },
    featureFlags: {
      type: Array,
      default: () => [],
    },
    systemToggles: {
      type: Array,
      default: () => [],
    },
    beamerEnabled: {
      type: Boolean,
      default: false,
    },
    planExpiryDetails: {
      type: Object,
      required: false,
      default: () => {},
    },
    isSpoofing: {
      type: Boolean,
      required: false,
      default: false,
    },
    showIntegrateGitBanner: {
      type: Boolean,
      required: false,
      default: false,
    },
    taskCount: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  store,

  data() {
    return {
      collapsed: initCollapsed(),
      spoofBannerTTL: SpoofBannerTTL,
      supportAddress: SupportAddress,
      mailToSupport: MailToSupport,
      // we set this on data so that the modal can be dismissed
      planExpiredModalVisible: Boolean(this.planExpiryDetails.expired),
      activeMenus: this.selectedMenuItems,
    };
  },

  computed: {
    ...mapState({
      isCreatingSampleGitIntegration: (state) =>
        state.sampleIntegrations.isCreatingSampleGitIntegration,
      environment: (state) => state.application.environment,
    }),
    sidebarBackgroundColor: function () {
      return getSidebarBackgroundColor(this.environment);
    },
    sidebarSplashText: function () {
      const isProd =
        this.environment === "production" ||
        parseUrlParamsToObject(window.location.search).simulateProd !==
          undefined;

      return isProd ? "" : this.environment;
    },
    hasProfileLinks: function () {
      return this.profileLinks && this.profileLinks.length > 0;
    },
    logoPath() {
      return this.collapsed
        ? "/OpsLevelLogoMark-White.svg"
        : "/OpsLevelLogo-White.svg";
    },
    filteredProfileLinks() {
      return this.profileLinks.slice(0, -1);
    },
    showPlanExpiryBanner() {
      return (
        this.planExpiryDetails.canExpire &&
        this.planExpiryDetails.daysLeft >= 0 &&
        this.planExpiryDetails.daysLeft <= PlanExpiryWarningThreshold
      );
    },
    showSideBarCta() {
      return this.planExpiryDetails.paymentPlan === "Free Trial";
    },
    spoofBannerExpiryReference() {
      return this.currentUser.user.account.uuid + "_spoofBannerExpiryReference";
    },
    docsIconStyle() {
      return this.beamerEnabled
        ? "padding: 12px; font-size: 1.2em;"
        : "padding: 12px; margin-right: 6px; font-size: 1.2em;";
    },
    keymap() {
      return {
        "shift+/": this.showHelp,
        "ctrl+shift+1": () => this.openPage("dashboard"),
        "ctrl+shift+2": () => this.openPage("services"),
      };
    },
    showLongRunningTasks() {
      return (
        this.planExpiryDetails.paymentPlan === PaymentPlans.freeTrial &&
        this.hasSystemToggle("websocket_connections")
      );
    },
    routes() {
      return Routes;
    },
    paymentPlan() {
      if (this.planExpiryDetails.paymentPlan === "Expired") {
        return "Account has expired.";
      } else {
        return `${this.planExpiryDetails.paymentPlan} has ended.`;
      }
    },
    demoLink() {
      return DEMO_LINK;
    },
    currentUsersTeamIds() {
      return this.currentUser?.user?.teams?.map((t) => t.gid);
    },
  },

  watch: {
    collapsed(newValue) {
      localStorage.setItem(NavCollapsedKey, newValue);
      this.setIsSidebarCollapsed({ collapsed: newValue });
    },
  },

  mounted() {
    mermaid.initialize();

    // Bento onboarding complete event listener
    // responsible for marking onboarding as complete in database
    document.addEventListener("bento-onGuideLoad", this.onBentoEvent);
    document.addEventListener("bento-buttonClicked", (event) => {
      const message = event?.detail?.message;

      if (message === "create-sample-git-integration") {
        if (this.isCreatingSampleGitIntegration) {
          return;
        }

        this.createSampleGitIntegration();
      }
    });

    document.addEventListener("canduButtonClicked", (event) => {
      const eventName = event?.detail?.action?.eventName;

      if (eventName === "create-sample-git-integration") {
        if (this.isCreatingSampleGitIntegration) {
          return;
        }

        this.createSampleGitIntegration();
      }
    });
  },

  created() {
    this.$store.registerModuleOnce("application", application);
    this.$store.registerModuleOnce("members", members);
    this.$store.registerModuleOnce("joinTeams", teams);
    this.$store.registerModuleOnce("sampleIntegrations", integrations);
    this.$store.registerModuleOnce("tracking", tracking);
    this.fetchApplicationConfigs();
    this.setIsSidebarCollapsed({ collapsed: this.collapsed });

    window.document.addEventListener(
      "menu-sublist-changed",
      this.setSelectedItems,
    );

    this.$store.watch(
      (state) => state.sampleIntegrations.errorsCreatingSampleGitIntegration,
      (errorsCreatingSampleGitIntegration) => {
        if (errorsCreatingSampleGitIntegration) {
          this.showErrorMessages("", errorsCreatingSampleGitIntegration);
        }
      },
    );
    this.$store.watch(
      (state) => state.sampleIntegrations.sampleGitIntegration,
      (sampleGitIntegration) => {
        location.assign(sampleGitIntegration.href);
      },
    );

    afterBentoLoad(drawAttentionToBentoSidebar);
    afterBentoLoad(lowerZIndex);
  },

  methods: {
    ...mapActions({
      fetchApplicationConfigs: "application/fetchApplicationConfigs",
      setIsSidebarCollapsed: "application/setIsSidebarCollapsed",
      createSampleGitIntegration:
        "sampleIntegrations/createSampleGitIntegration",
      trackUserClick: "tracking/trackUserClick",
    }),
    setSelectedItems(event) {
      const hash = event.detail.key;
      const activeKeys = [];

      for (const item of this.menuItems) {
        if (!item.active) {
          continue;
        }

        activeKeys.push(item.key);

        if (item.submenus === undefined) {
          continue;
        }

        for (const submenu of item.submenus) {
          if (!submenu.url.includes(hash)) {
            continue;
          }

          activeKeys.push(submenu.key);
        }
      }

      this.activeMenus = activeKeys;
    },
    isModalVisibleOnScreen() {
      // converting HTMLCollection to an Array
      const foundModalsInDOM = [
        ...document.getElementsByClassName("ant-modal"),
      ];

      // offset parent can be used to determine if a DOM element is visible on the screen
      // source: https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
      const isVisible = (domElement) => Boolean(domElement.offsetParent);

      return foundModalsInDOM.some(isVisible);
    },
    showHelp() {
      if (this.isModalVisibleOnScreen()) {
        return;
      }

      this.$refs.helpModal.show();
    },
    openPage(page) {
      window.location.assign(`/${page}`);
    },
    subMenuClass(item) {
      if (this.selectedMenuItems.includes(item.key)) {
        return "ant-menu-item-selected";
      }
    },
    onSpoofCancelClicked() {
      if (this.urls["stop_spoof"]) {
        window.location.assign(this.urls["stop_spoof"]);
      }
    },
    onBentoEvent(event) {
      const isOnboardingComplete =
        event?.detail?.isComplete && event?.detail.isOnboarding;

      if (!isOnboardingComplete) {
        return;
      }

      const params = {
        account: { complete_onboarding: true },
      };

      // We intentionally do not show a success message since this event may fire more than
      // once causing the message to be shown to the user multiple times.
      axios.put("/api/account", params).catch((err) => {
        const handledError = onboardingErrorCodes.includes(
          err?.response?.status,
        );
        const errorMessagePrefix = "Failed to process completion of onboarding";
        const errorMessage = handledError
          ? `${errorMessagePrefix} due to authentication error.`
          : `${errorMessagePrefix}. Please try again.`;

        this.showMessage({
          message: errorMessage,
          type: "error",
          duration: 10,
        });

        if (!handledError) {
          // Throwing an error inside a promise will be swallowed as a rejection, so we short-circuit that by using setTimeout to escape the promise
          setTimeout(() => {
            throw err;
          }, 1);
        }
      });
    },
    hasSystemToggle(toggle) {
      if (!isArray(this.systemToggles)) {
        return false;
      } else {
        return this.systemToggles.includes(toggle);
      }
    },
    handleSideBarCtaClicked() {
      this.trackUserClick("freeTrialSideBarCtaClicked");
    },
    handleOpenTaskDrawer() {
      if (this.$refs.taskDrawer.visible) {
        this.$refs.taskDrawer.close();
      } else {
        this.$refs.taskDrawer.open();
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.ant-layout-sider-collapsed) {
  .logo {
    text-align: center;
  }
}

#app-menu,
.ant-layout-sider {
  background: v-bind(sidebarBackgroundColor);
  transition: background-color 0.5s ease;
}

.logo {
  margin: 16px 16px;
}

.logo > img {
  height: 28px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    background-color: #e6f7ff;
  }
}

.sidebar-splash-text {
  position: absolute;
  font-size: 8vw;
  color: #fff;
  opacity: 0.1;
  line-height: 8vw;
  transform: rotate(80deg);
  transform-origin: center left;
  z-index: 20;
  pointer-events: none;
  left: 4vw;
  top: 10vh;
}
.global-header {
  flex: 0 1 auto;
  background: #fff;
  padding: 0 12px 0 0;

  .right {
    float: right;

    .action {
      margin-right: -12px;
      padding: 0 24px 0 12px;
      display: inline-block;
      &:hover {
        background-color: #e6f7ff; /* TODO: Access this as a less variable from ant-design-vue */
        cursor: pointer;
      }
    }
  }
}

.docs-icon {
  color: inherit;
}

.ant-menu-item :deep(.ant-badge-status-error),
.ant-menu-submenu :deep(.ant-badge-status-error),
.ant-menu-submenu .ant-menu-item :deep(.ant-badge-status-error) {
  margin-left: 10px;
}

.submenu-title,
.submenu-title > :deep(a) {
  display: block;
}

.divider-top-border {
  box-shadow:
    0 -4px 0 #001529,
    0 -5px #595959;
}
.main-layout-content {
  margin-left: 200px;

  &.sider-collapsed {
    margin-left: 80px;
  }
}

.free-trial-sidebar-cta,
.sidebar-task-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.sidebar-cta-button {
  width: 92%;
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 1px solid #e6f7ff;
}

.sidebar-cta-button-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  &.sider-collapsed {
    justify-content: center;
  }
}

.sidebar-cta-button img {
  max-height: 24px;
  border-radius: 50%;
  border: 1px solid #e6f7ff;
}

.task-button {
  height: 100%;
  width: 94%;
  padding: 5px;
  color: #ffffff;
  background: #ffffff26;
  border: 1px solid #d9d9d926;

  &:hover {
    background: #1890ff;
  }
}

.task-button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-shadow: none;

  &.sider-collapsed {
    justify-content: center;
  }
}
</style>
