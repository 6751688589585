import { tableState } from "@/shared/table_helper.js";

export default () => ({
  errorsFetchingTeams: [],
  isFetchingTeams: false,
  neverFetchedTeams: true,
  teams: [], // Potentially filtered teams
  aggregatedTeams: [], // All teams seen so far, not just filtered
  totalCount: 0,
  ...tableState({ itemsPerPage: 100 }),
  isFetchingTeam: false,
  team: null,
  errorsFetchingTeam: [],
  // Update Team
  isUpdatingTeam: false,
  updatedTeam: null,
  errorsUpdatingTeam: [],
  // Create Team
  isCreatingTeam: false,
  createdTeam: null,
  errorsCreatingTeam: [],
  // Delete Team
  isDeletingTeam: false,
  errorsDeletingTeam: [],
  deletedTeamId: null,
  // Create/Update Team Contacts
  isAddingTeamContact: false,
  errorsAddingTeamContact: [],
  // Delete Team Contacts
  isDeletingTeamContact: false,
  errorsDeletingTeamContact: [],
  // Update Default Team Contact
  isUpdatingDefaultTeamContact: false,
  errorsUpdatingDefaultTeamContact: [],

  isFetchingTeamCampaignPerformance: false,
  errorsFetchingTeamCampaignPerformance: [],
  teamCampaignPerformance: null,

  // Team tree hierarchy
  isFetchingTreeTeams: false,
  treeTeams: {},
  treeTeamsTotalCount: 0,
  errorsFetchingTreeTeams: [],
});
