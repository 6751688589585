export const REQUEST_FETCH_SUGGESTIONS = "REQUEST_FETCH_SUGGESTIONS";
export const RECEIVE_FETCH_SUGGESTIONS = "RECEIVE_FETCH_SUGGESTIONS";
export const MERGE_SUGGESTIONS = "MERGE_SUGGESTIONS";
export const RECEIVE_FETCH_SUGGESTIONS_ERROR =
  "RECEIVE_FETCH_SUGGESTIONS_ERROR";

export const ACTION_SUGGESTIONS = "ACTION_SUGGESTIONS";
export const RECEIVE_ACTION_SUGGESTIONS_RESULTS =
  "RECEIVE_ACTION_SUGGESTIONS_RESULTS";
export const RECEIVE_ACTION_SUGGESTIONS_ERROR =
  "RECEIVE_ACTION_SUGGESTIONS_ERROR";

export const UPDATE_SUGGESTION = "UPDATE_SUGGESTION";

export const REQUEST_FETCH_SUGGESTIONS_COUNT =
  "REQUEST_FETCH_SUGGESTIONS_COUNT";
export const RECEIVE_FETCH_SUGGESTIONS_COUNT =
  "RECEIVE_FETCH_SUGGESTIONS_COUNT";
export const RECEIVE_FETCH_SUGGESTIONS_COUNT_ERROR =
  "RECEIVE_FETCH_SUGGESTIONS_COUNT_ERROR";

export const SET_SUGGESTION_COUNT = "SET_SUGGESTION_COUNT";

export const UPDATE_TABLE_DATA = "UPDATE_TABLE_DATA";
export const UPDATE_SUGGESTIONS = "UPDATE_SUGGESTIONS";

export const REQUEST_SET_SUGGESTION_IGNORED_VALUE =
  "REQUEST_SET_SUGGESTION_IGNORED_VALUE";
export const RECEIVE_UPDATE_SUGGESTION_IGNORED_VALUE_SUCCESS =
  "RECEIVE_UPDATE_SUGGESTION_IGNORED_VALUE_SUCCESS";
export const RECEIVE_UPDATE_SUGGESTION_IGNORED_VALUE_ERROR =
  "RECEIVE_UPDATE_SUGGESTION_IGNORED_VALUE_ERROR";

export const REQUEST_FETCH_SUGGESTION_INTEGRATION_TYPES =
  "REQUEST_FETCH_SUGGESTION_INTEGRATION_TYPES";
export const RECEIVE_FETCH_SUGGESTION_INTEGRATION_TYPES =
  "RECEIVE_FETCH_SUGGESTION_INTEGRATION_TYPES";
export const RECEIVE_FETCH_SUGGESTION_INTEGRATION_TYPES_ERROR =
  "RECEIVE_FETCH_SUGGESTION_INTEGRATION_TYPES_ERROR";

export const REQUEST_FETCH_SUGGESTION_INTEGRATIONS =
  "REQUEST_FETCH_SUGGESTION_INTEGRATIONS";
export const RECEIVE_FETCH_SUGGESTION_INTEGRATIONS =
  "RECEIVE_FETCH_SUGGESTION_INTEGRATIONS";
export const RECEIVE_FETCH_SUGGESTION_INTEGRATIONS_ERROR =
  "RECEIVE_FETCH_SUGGESTION_INTEGRATIONS_ERROR";

export const DETACH_SUGGESTION_ALIAS = "DETACH_SUGGESTION_ALIAS";
export const DETACH_SUGGESTION_ALIAS_SUCCESS =
  "DETACH_SUGGESTION_ALIAS_SUCCESS";
export const DETACH_SUGGESTION_ALIAS_ERROR = "DETACH_SUGGESTION_ALIAS_ERROR";

export const REQUEST_UPDATE_SUGGESTION = "REQUEST_UPDATE_SUGGESTION";
export const RECEIVE_UPDATE_SUGGESTION_SUCCESS =
  "RECEIVE_UPDATE_SUGGESTION_SUCCESS";
export const RECEIVE_UPDATE_SUGGESTION_ERROR =
  "RECEIVE_UPDATE_SUGGESTION_ERROR";
