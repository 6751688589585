<template>
  <a-alert
    :class="bannerClass"
    :type="alertType"
    banner
    square
    :show-icon="false"
  >
    <template v-if="useTrialBanner">
      <template slot="description">
        <template v-if="daysLeft > 7">
          <span class="emoji">🎉</span>
          You have <b>{{ daysLeft }} days</b> left in your OpsLevel free trial.
          Want to make it official?
          <template v-if="pricingDisabled">
            Contact us at <a :href="mailToSupport">{{ supportAddress }}</a> to
            upgrade your account.
          </template>
        </template>

        <template v-else-if="daysLeft >= 1">
          <span class="emoji">⏰</span>
          Time's running out on your free trial, you have just
          <b>{{ daysLeft }} {{ pluralize("day", daysLeft) }}</b> left.
          <template v-if="pricingDisabled">
            Contact us at <a :href="mailToSupport">{{ supportAddress }}</a> to
            keep building.
          </template>
        </template>

        <template v-else>
          <span class="emoji">🚫</span>
          Looks like your trial has expired.
          <template v-if="pricingDisabled">
            Reach out to us at
            <a :href="mailToSupport">{{ supportAddress }}</a> to extend your
            free trial.
          </template>
        </template>

        <a-button
          v-if="!pricingDisabled"
          :style="{ marginLeft: '8px' }"
          class="see-plans-button"
          @click="ctaClicked"
        >
          See Plans
        </a-button>
        <a-button
          type="ghost"
          class="book-demo-button"
          :style="{ marginLeft: '8px' }"
          @click="bookADemo"
        >
          Book a Demo
        </a-button>
      </template>
    </template>

    <template v-if="isPilot">
      <template slot="description">
        <template v-if="daysLeft > 7">
          <span class="emoji">🎉</span>
          You have <b>{{ daysLeft }} days</b> left in your OpsLevel Pilot. Let
          us know if you have any questions:
          <a :href="mailToSupport">{{ supportAddress }}</a
          >.
        </template>

        <template v-else-if="daysLeft >= 1">
          <span class="emoji">⏰</span>
          Time's running out. You have
          <b>{{ daysLeft }} {{ pluralize("day", daysLeft) }}</b> left in your
          pilot. Let us know if you have any questions:
          <a :href="mailToSupport">{{ supportAddress }}</a
          >.
        </template>

        <template v-else>
          <span class="emoji">🚫</span>
          Your pilot has expired. Reach out to
          <a :href="mailToSupport">{{ supportAddress }}</a> to keep building.
        </template>
      </template>
    </template>
  </a-alert>
</template>

<script>
import pluralize from "pluralize";
import { featureFlags } from "@/mixins/featureFlags.js";
import { systemToggles } from "@/mixins/systemToggles.js";
import { mapActions } from "vuex";
import { PaymentPlans } from "@/shared/payment_plans_helper.js";

const SUPPORT_ADDRESS = "support@opslevel.com";
const MAIL_TO_SUPPORT = `mailto:${SUPPORT_ADDRESS}?subject=Account%20Expired`;
const BOOK_A_DEMO_URL =
  "https://opslevel.na.chilipiper.com/book/custom-demo-request";

export default {
  mixins: [featureFlags, systemToggles],

  props: {
    daysLeft: {
      type: Number,
      required: true,
    },
    planName: {
      type: String,
      required: true,
    },
    pricingUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      supportAddress: SUPPORT_ADDRESS,
      mailToSupport: MAIL_TO_SUPPORT,
    };
  },

  computed: {
    alertType() {
      if (this.useTrialBanner) {
        return this.daysLeft > 5 ? "success" : "error";
      }

      if (this.daysLeft > 7) {
        return "success";
      } else if (this.daysLeft >= 1) {
        return "warning";
      } else {
        return "error";
      }
    },
    bannerClass() {
      return this.useTrialBanner ? "trial-banner" : "";
    },
    useTrialBanner() {
      return this.planName === "Free Trial";
    },
    pricingDisabled() {
      return (
        this.hasFeatureFlag("disable_pricing") ||
        this.hasSystemToggle("disable_pricing_page")
      );
    },
    isPilot() {
      return (
        this.planName === PaymentPlans.pilot ||
        this.planName === PaymentPlans.paidPilot
      );
    },
  },

  methods: {
    ...mapActions({
      trackUserClick: "tracking/trackUserClick",
    }),
    async ctaClicked() {
      await this.trackUserClick("freeTrialPricingBanner");

      window.location.assign(this.pricingUrl);
    },
    pluralize,
    bookADemo() {
      window.open(BOOK_A_DEMO_URL, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.emoji {
  margin-right: 6px;
}

.trial-banner.ant-alert-success {
  color: white;
  background: linear-gradient(90deg, #13c2c2 30%, #1890ff);
}
.trial-banner.ant-alert-success a {
  color: white;
  text-decoration: underline;
}
.trial-banner.ant-alert-error {
  background: linear-gradient(90deg, #ffa39e, #f5222d);
}

.book-demo-button {
  opacity: 0.9;

  &:hover,
  &:focus {
    color: white;
    border-color: white;
    opacity: 1;
  }
}
</style>
